export const testDirective = {
	bind(el, binding) {
		if (binding.value && binding.value.includes("_")) {
			throw new Error(`Please use dashes instead of underscores in the test directive value. Found: ${binding.value}`);
		} else {
			const testId = binding.value || "default-test-id";
			el.setAttribute("data-test-id", testId);
		}
	},
};

export default testDirective;
