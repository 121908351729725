<template>
	<div>
		<v-dialog :disabled="isDisabled" v-model="dialog" width="500" @input="onInput">
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon elevation="0" v-bind="attrs" v-on="on" @click="init" v-test="'project-share-button'">
					<v-icon :disabled="isDisabled">icon-share-contact-stroke</v-icon>
				</v-btn>
			</template>

			<v-card ref="shareBox" class="pa-2" v-test="'project-share-dialog'">
				<v-card-title class="d-flex text-h5 mb-2 pa-4">
					<div class="d-flex justify-space-between grow header">
						<h5 v-test="'project-share-dialog-title'">Share With Others</h5>
						<v-icon @click="copyLink" v-test="'project-share-dialog-copy-link'">icon-link-double</v-icon>
					</div>
				</v-card-title>

				<v-card-text class="pa-4">
					<v-autocomplete
						ref="customerSearch"
						class="mb-0 text--red"
						hide-details
						:append-icon="searchText?.length > 0 && searchedMembers.length == 0 ? `icon-plus` : ``"
						@click:append="createNewContact"
						outlined
						label="People"
						chips
						deletable-chips
						no-data-text="Start typing to find people"
						counter="60"
						multiple
						@update:search-input="onChangeInput"
						@change="onChange"
						:search-input.sync="searchText"
						v-model="shareUsers"
						item-text="formattedName"
						item-value="id"
						:hide-no-data="true"
						:menu-props="{ maxWidth: peopleMenuWidth, contentClass: 'custom-menu' }"
						:items="memberItems"
						return-object
						prepend-inner-icon="icon-user-single"
						attach
						:maxLength="60"
						v-test="'project-share-dialog-people'">
						<template v-slot:selection="{ item }">
							<v-chip
								@click:close="() => remove(item)"
								class="my-custom-chip"
								close
								closable
								close-icon="icon-close-medium">
								<span class="chip-text">
									{{ item.formattedName }}
								</span>
							</v-chip>
						</template>
						<template v-slot:item="{ item, on, attrs }">
							<v-list-item
								v-bind="attrs"
								v-on="{
									...on,
									click: ($event) => {
										if (hasEmail(item)) {
											on.click($event);
										} else {
											$event.stopPropagation();
										}
									},
								}"
								:class="{ 'disabled-item': !hasEmail(item) }">
								<template v-slot:default="{ active }">
									<v-list-item-action>
										<v-checkbox
											hide-details
											:ripple="false"
											:input-value="active"
											:disabled="!hasEmail(item)"></v-checkbox>
									</v-list-item-action>
									<v-list-item-content>
										<template v-if="!hasEmail(item)">
											<v-tooltip bottom max-width="250" :content-class="'custom-tooltip'">
												<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
													<div
														v-bind="tooltipAttrs"
														v-on="tooltipOn"
														style="display: flex; flex-direction: column; width: 100%">
														<span v-if="item.name" class="text-truncate">{{
															item.name
														}}</span>
														<span v-else>{{ item.email }}</span>
														<v-list-item-subtitle>
															<span v-if="item.phone">{{ item.phone }}</span>
														</v-list-item-subtitle>
													</div>
												</template>
												<span>
													Users must have an email address to be given "Editor" access to a
													project. Add an email address or change permission to "Viewer" to
													invite this user.
												</span>
											</v-tooltip>
										</template>
										<template v-else>
											<span v-if="item.name" class="text-truncate">{{ item.name }}</span>
											<span v-else>{{ item.email }}</span>
											<v-list-item-subtitle>
												<span v-if="item.phone">{{ item.phone }}</span>
											</v-list-item-subtitle>
										</template>
									</v-list-item-content>
								</template>
							</v-list-item>
						</template>
					</v-autocomplete>
					<div v-if="searchText && searchedMembers.length == 0" class="text-small px-4">
						To share with new people, create a contact. A contact must have an email address to be given
						“Editor” access.
					</div>

					<v-select
						outlined
						hide-details
						id="acces-type"
						class="status-input mb-0 mt-4"
						v-model="accessType"
						:disabled="isDisabled"
						:active="false"
						item-text="label"
						item-value="value"
						chips
						:items="getAccessTypeItems()"
						v-test="'project-share-dialog-access-type'">
						<template v-slot:label>
							<div class="label">Access Type</div>
						</template>
						<template v-slot:item="{ item, on, attrs }">
							<v-list-item
								v-bind="attrs"
								v-on="{
									...on,
									click: ($event) => {
										if (!isItemDisabled(item)) {
											on.click($event);
										} else {
											$event.stopPropagation();
										}
									},
								}"
								:class="{ 'disabled-item': isItemDisabled(item) }">
								<v-list-item-content>
									<v-list-item-title>
										<template v-if="isItemDisabled(item)">
											<v-tooltip bottom max-width="40" :content-class="'custom-tooltip'">
												<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
													<div v-bind="tooltipAttrs" v-on="tooltipOn">
														{{ item.label }}
													</div>
												</template>
												<span>
													One or more of the users you are attempting to share with do not
													have an email address associated with them. Users must have an email
													address to be given "Editor" access. Add an email address or change
													permission to "Viewer" to invite these users.
												</span>
											</v-tooltip>
										</template>
										<template v-else>
											{{ item.label }}
										</template>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>

						<template v-slot:append>
							<v-icon>icon-lock</v-icon>
						</template>
						<template v-slot:selection="{ item }">
							<v-chip @click="() => selectItem(item.value)">{{ item.label }}</v-chip>
						</template>
					</v-select>

					<div class="text-small mb-4 px-4 text-start">
						{{ labelText }}
					</div>
					<div v-test="'project-share-dialog-people-with-access-to-project'">
						<div class="text-h6 font-weight-bold mb-4 text-start d-flex justify-space-between pt-2">
							<span class="font-size-11">People With Access To Project</span>
							<v-icon @click="togglePeopleMenu" v-test="'project-share-dialog-show-people-with-access'">{{
								showPeopleMenu ? "icon-chevron-up" : "icon-chevron-down"
							}}</v-icon>
						</div>
						<div v-if="showPeopleMenu && !peopleLoading">
							<div class="d-flex flex-column" v-for="item in paginatedUsers" :key="item.id">
								<div class="d-flex justify-content-between">
									<div class="flex-grow-1 overflow-hidden">
										<div class="font-weight-bold mt-4 text-truncate">{{ getTitle(item) }}</div>
										<div class="text-small text-truncate">{{ getSubtitle(item) }}</div>
									</div>
									<div class="flex-shrink-0 ms-3 center mt-2 mb-2">
										<AccessTypeMenu
											v-model="item.accessType"
											:disabled="isAccessTypeDisabled(item)"
											:edit-disabled="!userHasEmail(item)"
											@change="(value) => handleOptionChange(item, value)" />
									</div>
								</div>
								<v-divider></v-divider>
							</div>

							<v-pagination
								v-model="currentPage"
								:length="pageCount"
								:total-visible="5"
								class="mt-2" />
						</div>
						<div v-else-if="peopleLoading">
							<v-skeleton-loader type="table-cell@5"></v-skeleton-loader>
						</div>
					</div>
				</v-card-text>
				<v-card-actions class="pb-4">
					<v-spacer></v-spacer>
					<v-btn
						large
						class="white-bg"
						rounded
						color="primary"
						text
						@click="dialog = false"
						v-test="'project-share-dialog-back'">
						Back
					</v-btn>
					<v-btn
						large
						rounded
						:disabled="shareUsers.length == 0"
						id="confirm-action-btn"
						color="primary"
						@click="send"
						v-test="'project-share-dialog-share'"
						v-track="'project-share-dialog-share'">
						Share
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<CreateCustomerDialog
			ref="CreateCustomerDialogRef"
			@createCustomerDialogClosed="searchText = ''"></CreateCustomerDialog>
	</div>
</template>
<script>
	import api from "../api";
	import uuid from "../helpers/uuid.js";
	import AccessTypeMenu from "./AccessTypeMenu.vue";
	import { hasFreeTrialEnded } from "../helpers/hasFreeTrialEnded.js";
	import { AccessType } from "@/enums/AccessType";
	import CreateCustomerDialog from "./CreateCustomerDialog.vue";
	import * as Sentry from "@sentry/vue";
	import DeviceUtility from "@/helpers/device";
	import { constants } from "@/api/constants";
	import { calculateTimeout } from "@/helpers/utilities";
	import { blurActiveElement } from "../helpers/utilities";
	import EventBus from "@/helpers/eventBus";

	const DELETED_USER = ".";
	export default {
		name: "ShareActionDialog",
		props: {
			projectId: String,
			projectName: String,
			isDisabled: Boolean,
			access: AccessType,
		},
		components: {
			AccessTypeMenu,
			CreateCustomerDialog,
		},
		data() {
			return {
				dialog: false,
				shareUsers: [],
				sharedUsers: [],
				teamMembers: [],
				searchedMembers: [],
				searchText: "",
				customers: [],
				me: null,
				accessType: AccessType.Viewer,
				currentPage: 1,
				itemsPerPage: 5,
				currentSearchPage: 1,
				shareUrl: "",
				project: null,
				createdByUser: null,
				createdByUserId: null,
				peopleMenuWidth: 0,
				AccessType: AccessType,
				alreadySharedUsers: [],
				showPeopleMenu: false,
				peopleLoading: false,
				loadSharedUsersPromise: null,
			};
		},
		async mounted() {},
		methods: {
			async togglePeopleMenu() {
				this.showPeopleMenu = !this.showPeopleMenu;
				if (this.showPeopleMenu) {
					this.peopleLoading = true;
					if (this.loadSharedUsersPromise) {
						await this.loadSharedUsersPromise;
					}
					this.peopleLoading = false;
				}
			},
			isItemDisabled(item) {
				return item.value !== AccessType.Viewer && this.isEditTypeDisabled;
			},
			hasEmail(item) {
				if (this.accessType === AccessType.Viewer) {
					return true;
				}
				if (!item) {
					return false;
				}
				return this.userHasEmail(item);
			},
			userHasEmail(user) {
				return user.email || user.User?.email || user.Customer?.EmailAddresses?.[0]?.emailAddress;
			},
			isEditDisabled() {
				return this.access !== AccessType.Editor;
			},
			isAccessTypeDisabled(item) {
				if (!this.createdByUser) {
					return true;
				}
				return (
					this.access !== AccessType.Editor ||
					item?.userIdentifier === this.createdByUser?.Account?.id ||
					item?.userIdentifier === this.createdByUser?.User?.email
				);
			},
			selectItem(value) {
				this.accessType = value;
			},
			getAccessTypeItems() {
				return Object.entries(AccessType).map(([key, value]) => {
					return { label: key, value };
				});
			},
			validate(v) {
				for (const item of v) {
					if (!item.email) {
						return "Email address is required.";
					}
					if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(item.email)) {
						return "Email address must be valid.";
					}
				}
				return true;
			},
			remove(item) {
				this.shareUsers = this.shareUsers.filter((u) => u.id !== item.id);
			},
			async createNewContact() {
				this.$refs.customerSearch.menuIsActive = false;
				try {
					const record = await this.$refs.CreateCustomerDialogRef.open(
						this.searchText,
						this.accessType === AccessType.Editor
							? "An email is required to share this project as an Editor. Please enter an email to continue."
							: null
					);
					if (record) {
						await this.loadCustomers();
						const customer = this.getCustomerDetails(record);
						this.addToShareUsers(customer);
						this.$refs.customerSearch.menuIsActive = true;
						this.searchText = "";
					}
				} catch (err) {
					Sentry.captureException(err, {
						tags: {
							method: "createNewCustomer",
							file: "ShareAction",
						},
					});
				}
			},
			addToShareUsers(item) {
				this.shareUsers.push(item);
			},
			async getShareUrlOfProject() {
				if (!this.project.shareId) {
					// create a uuid for sharing
					const shareId = uuid.uuidv4();
					this.project = await api.updateProject(this.projectId, {
						shareId,
						genericAccessType: AccessType.Viewer,
					});
				}
				return this.project.shareUrl;
			},
			onChange(items) {
				this.$nextTick(() => {
					this.searchText = "";
				});
				this.shareUsers = items.filter((item) => typeof item === "object");
				return;
			},
			onChangeInput(searchText) {
				this.searchedMembers = [];
				if (!searchText) {
					this.currentSearchPage = 1;
					return;
				}
				this.searchText = searchText;
				this.memberItems.filter((member) => {
					if (member.name.toLowerCase().includes(searchText.toLowerCase())) {
						if (!this.sharedUsers.find((u) => u.userIdentifier == member.email)) {
							this.searchedMembers.push(member);
						}
					}
				});
			},
			async init() {
				this.shareUsers = [];
				this.accessType = AccessType.Viewer;
				this.showPeopleMenu = false;
				this.me = await api.getMe();
				this.currentPage = 1;
				if (await hasFreeTrialEnded(this.me.organization)) {
					this.dialog = false;
					this.$root.$freeTrialEndedDialog.open(this.me.organization);
				} else {
					try {
						this.project = await api.getProject(this.projectId);
					} catch (err) {
						this.$toast.error(err.message || "Project was not found.", {
							timeout: calculateTimeout(err.message || "Project was not found."),
						});
						this.$router.push("/projects");
						return;
					}
					this.createdByUserId = this.project.User?.id;
					await Promise.all([this.loadTeamMembers(), this.loadCustomers()]);
					const promiseResponses = await Promise.all([this.getShareUrlOfProject()]);
					this.shareUrl = promiseResponses[0];
				}
				this.peopleMenuWidth = this.$refs.shareBox?.$el.getBoundingClientRect().width - 48 + "px";
				this.loadSharedUsersPromise = this.loadSharedUsers();
			},
			getTitle(item) {
				let appendText = "";
				if (
					this.createdByUser?.Account &&
					(item.userIdentifier == this.createdByUser?.Account?.id ||
						item.userIdentifier == this.createdByUser?.User?.email)
				) {
					appendText = " (Creator)";
				} else if (item.userIdentifier == this.me.account.id || item.userIdentifier == this.me.user.email) {
					appendText = " (You)";
				}
				return this.getTitleList(item)[0] + appendText;
			},
			getTitleList(item) {
				const finalTitles = [];
				if (item.userIdentifierType === "account") {
					const id = item.userIdentifier;
					const user = this.teamMembers.find((u) => u.Account?.id === id);
					if (user) {
						finalTitles.push(`${user.firstname} ${user.lastname}`);
					} else {
						finalTitles.push(DELETED_USER);
					}
				}
				if (item.User?.name) {
					finalTitles.push(item.User.name);
				}
				if (item.User?.email) {
					finalTitles.push(item.User.email);
				}
				if (item.User?.phone) {
					finalTitles.push(item.User.phone);
				}
				if (item.Customer?.name) {
					finalTitles.push(item.Customer.name);
				}
				if (item.EmailAddress) {
					finalTitles.push(item.EmailAddress.emailAddress);
				}
				if (item.Customer?.EmailAddresses && item.Customer.EmailAddresses.length > 0) {
					finalTitles.push(item.Customer.EmailAddresses[0]?.emailAddress);
				}
				if (item.Customer?.PhoneNumbers && item.Customer.PhoneNumbers.length > 0) {
					finalTitles.push(item.Customer.PhoneNumbers[0]?.phoneNumber);
				}
				if (item.userIdentifierType === "email") {
					const customer = this.customers.find(
						(c) => c.EmailAddresses[0]?.emailAddress === item.userIdentifier
					);
					if (customer) {
						finalTitles.push(customer.name);
						if (customer.EmailAddresses && customer.EmailAddresses.length > 0) {
							finalTitles.push(customer.EmailAddresses[0]?.emailAddress);
						}
						if (customer.PhoneNumbers && customer.PhoneNumbers.length > 0) {
							finalTitles.push(customer.PhoneNumbers[0]?.phoneNumber);
						}
					}
				}
				if (finalTitles.length === 0) {
					finalTitles.push(DELETED_USER);
				}
				return finalTitles;
			},
			async handleOptionChange(item, value) {
				if (value === constants.REMOVE_ACCESS.value) {
					let removalMessage = "This will remove access for " + this.getTitle(item) + ".";
					if (item.userIdentifier == this.me.user.email || item.userIdentifier == this.me.account.id) {
						removalMessage = "This will remove your access to the project.";
					}
					const result = await this.$root.$confirm.open(
						"Remove Access",
						removalMessage,
						{},
						false,
						"Remove",
						"Cancel",
						true,
						true
					);
					if (result) {
						// optimistic update
						this.sharedUsers = this.sharedUsers.filter((u) => u.id !== item.id);
						await api.deleteProjectShare(item.id);
						EventBus.$emit("UserRemovedFromProject", item, this.projectId);
					} else {
						return;
					}
				} else {
					await api.updateProjectShare(item.id, { accessType: value });
					// optimistic update
					item.accessType = value;
				}
				this.$emit("access-changed", item, value);
				this.loadSharedUsers();
			},
			getSubtitle(item) {
				let subtitleList = this.getTitleList(item);
				const subtitleListSet = new Set(subtitleList);
				subtitleList = Array.from(subtitleListSet);
				// take index 1 and 2 and join them
				return subtitleList.slice(1).join(" | ");
			},
			async loadTeamMembers() {
				this.teamMembers = [];
				const result = await api.getUsers({ pageSize: 1000, page: 1 });
				this.teamMembers = result.rows.filter((user) => !!user.Account);
			},
			async loadCustomers(customers = []) {
				this.customers = [];
				const result = await api.getCustomers({ pageSize: 1000, page: 1 });
				this.customers = [...customers, ...result.rows];
			},
			async loadSharedUsers() {
				const projectShares = await api.getProjectShares({
					where: { projectId: this.projectId },
					pageSize: 1000,
				});
				this.sharedUsers = await this.sortUsers(projectShares.rows.filter((user) => !!user));
				this.sharedUsers = this.sharedUsers.filter((user) => {
					return !this.getTitle(user).startsWith(DELETED_USER);
				});
			},

			mapUsersWithAccounts(users, alreadySharedUsers) {
				return users
					.map((user) => {
						const alreadySharedUser = alreadySharedUsers.find(
							(u) => u.email == user.userIdentifier || u.Accounts.find((a) => a.id == user.userIdentifier)
						);
						if (!alreadySharedUser) {
							return null;
						}
						return {
							...user,
							User: alreadySharedUser,
							Account: alreadySharedUser?.Account,
							name: alreadySharedUser?.firstname + " " + alreadySharedUser?.lastname,
						};
					})
					.filter((u) => !!u);
			},
			mapCustomersWithAccounts(customers, alreadySharedUsers, emailCustomers) {
				return [...customers, ...emailCustomers]
					.map((customer) => {
						const alreadySharedUser = alreadySharedUsers.find(
							(u) =>
								u.id === customer.userIdentifier ||
								(u.EmailAddresses?.length > 0 &&
									u.EmailAddresses[0]?.emailAddress == customer.userIdentifier)
						);
						if (!alreadySharedUser) {
							return null;
						}
						return {
							...customer,
							Account: alreadySharedUser?.Account,
						};
					})
					.filter((c) => !!c);
			},
			async sortUsers(users) {
				this.alreadySharedUsers = await api.getSharingUsersForProject(this.projectId);
				const alreadySharedUsers = this.alreadySharedUsers.filter((u) => !!u.Account);
				const emailCustomers = this.alreadySharedUsers.filter((u) => !u.Account);
				let customers = users.filter((u) => u.userIdentifierType === "customer");
				const customersFromEmail = users.filter((u) => {
					return emailCustomers.some(
						(c) => c.EmailAddresses?.length > 0 && c.EmailAddresses[0].emailAddress == u.userIdentifier
					);
				});
				users = this.mapUsersWithAccounts(users, alreadySharedUsers);

				customers = this.mapCustomersWithAccounts(customers, this.alreadySharedUsers, customersFromEmail);
				this.createdByUser = users.find((user) => user.User?.id == this.createdByUserId);
				const projectCreator = users.find(
					(u) =>
						u.userIdentifier == this.createdByUser.Account.id ||
						u.userIdentifier == this.createdByUser.User.email
				);
				const myUser = users.find(
					(u) => u.userIdentifier == this.me.account.id || u.userIdentifier == this.me.user.email
				);
				const usersList = projectCreator ? [projectCreator] : [];
				if (myUser != null && this.me.user.id !== this.createdByUser.User.id) {
					usersList.push(myUser);
				}
				users = [...users, ...customers];
				return [
					...usersList,
					...users
						.filter(
							(u) =>
								u.userIdentifier != this.createdByUser.Account.id &&
								u.userIdentifier != this.createdByUser.User.email &&
								u.userIdentifier != this.me.account.id &&
								u.userIdentifier != this.me.user.email
						)
						.sort((a, b) => {
							const nameA = this.getTitle(a);
							const nameB = this.getTitle(b);
							return nameA.localeCompare(nameB);
						}),
				];
			},
			getCustomerDetails(customer) {
				if (!customer.EmailAddresses || customer.EmailAddresses.length == 0) {
					if (customer.PhoneNumbers && customer.PhoneNumbers.length > 0) {
						return {
							id: customer.id,
							name: customer.name,
							formattedName:
								customer.name.length > 0 ? customer.name : customer.PhoneNumbers[0]?.phoneNumber,
							phone: customer.PhoneNumbers[0]?.phoneNumber,
						};
					}
					return;
				}
				return {
					id: customer.id,
					name: customer.name,
					formattedName: customer.name.length > 0 ? customer.name : customer.EmailAddresses[0]?.emailAddress,
					email: customer.EmailAddresses[0]?.emailAddress,
					phone: customer.PhoneNumbers[0]?.phoneNumber,
				};
			},
			async copyLink() {
				if (DeviceUtility.isTablet || DeviceUtility.isMobile) {
					this.$emit("copy-link");
					return;
				}
				if (navigator.clipboard) {
					try {
						this.shareUrl = await this.getShareUrlOfProject();
						await navigator.clipboard.writeText(this.shareUrl);
						const message = "The project link has been copied to the clipboard.";
						this.$toast.success(message, { timeout: calculateTimeout(message) });
					} catch (err) {
						console.error("error copying link", err);
						const message = "Failed to copy the project link to the clipboard.";
						this.$toast.error(message, { timeout: calculateTimeout(message) });
					}
				}
			},
			async send() {
				try {
					const promises = [];
					const usersWithoutPhone = this.shareUsers.filter((user) => !user.phone);
					if (usersWithoutPhone.length > 0) {
						const userStringWithoutPhone = usersWithoutPhone.map((user) => user.name).join("<br>");
						if (
							!(await this.$root.$confirm.open(
								"Heads Up! The following people do not have phone numbers:",
								`${userStringWithoutPhone}<br><br>If they have a LiveSwitch Contact
		                               account, they will be able to see the project in their Projects table but will
		                                not receive a text message informing them they have been added to the project.<br>
		                                If they do not have a LiveSwitch Contact account, add a phone number to the
		                                 contact record, and then share the project. Alternatively, you can copy the project link below and send it to them. <br><br> <a href=${this.shareUrl} target="_blank">${this.shareUrl}</a>`,
								{},
								false,
								"Share",
								"Back",
								true
							))
						) {
							return;
						}
					}
					for (let user of this.shareUsers) {
						if (!user.email) {
							if (this.accessType !== AccessType.Viewer) {
								continue;
							}
							const request = {
								projectId: this.projectId,
								userIdentifier: user.id,
								userIdentifierType: "customer",
								accessType: this.accessType,
							};
							promises.push(api.createProjectShare(request));
							continue;
						}
						if (this.sharedUsers.find((u) => u.userIdentifier == user.email)) {
							continue;
						}
						const request = {
							projectId: this.projectId,
							userIdentifier: user.email,
							userIdentifierType: "email",
							accessType: this.accessType,
						};
						promises.push(api.createProjectShare(request));
					}
					this.shareUsers = [];
					await Promise.all(promises);
					this.dialog = false;
					this.$emit("shared");
				} catch (err) {
					this.$toast.error(err.message || "Failed to share project.", {
						timeout: calculateTimeout(err.message || "Failed to share project."),
					});
					console.error("error sharing", err);
				}
			},
			onInput() {
				blurActiveElement();
			},
		},
		computed: {
			isEditTypeDisabled() {
				if (this.shareUsers.length === 0) {
					return false;
				}
				const disabled = this.shareUsers.some((user) => {
					return !this.userHasEmail(user);
				});
				return disabled;
			},
			labelText() {
				if (this.accessType === AccessType.Editor) {
					return "A person with “Editor” access will be able to do all actions, including uploading, editing, and deleting.";
				}
				return "People with “Viewer” access will be able to view files, but will not be able to make any changes, or upload content.";
			},
			paginatedUsers() {
				const start = (this.currentPage - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.sharedUsers.slice(start, end);
			},
			pageCount() {
				return Math.ceil(this.sharedUsers.length / this.itemsPerPage);
			},
			paginatedSearchUsers() {
				const start = (this.currentSearchPage - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.searchedMembers.slice(start, end);
			},
			pageSearchCount() {
				return Math.ceil(this.searchedMembers.length / this.itemsPerPage);
			},
			memberItems() {
				let team = [];
				let teamMembers = this.teamMembers;
				team.push(
					...teamMembers.map((user) => {
						let name = `${user.firstname} ${user.lastname}`.trim();
						const email = user.email;
						return {
							id: user.Account.id,
							name,
							formattedName: name.length > 0 ? name : email,
							email,
							phone: user.phone,
						};
					})
				);
				this.customers.forEach((customer) => {
					if (!customer.EmailAddresses || customer.EmailAddresses.length == 0) {
						if (customer.PhoneNumbers && customer.PhoneNumbers.length > 0) {
							team.push({
								id: customer.id,
								name: customer.name,
								formattedName:
									customer.name.length > 0 ? customer.name : customer.PhoneNumbers[0]?.phoneNumber,
								phone: customer.PhoneNumbers[0]?.phoneNumber,
							});
						}
						return;
					}
					team.push({
						id: customer.id,
						name: customer.name,
						formattedName:
							customer.name.length > 0 ? customer.name : customer.EmailAddresses[0]?.emailAddress,
						email: customer.EmailAddresses[0]?.emailAddress,
						phone: customer.PhoneNumbers[0]?.phoneNumber,
					});
				});
				if (this.sharedUsers) {
					team = team.filter((member) => {
						return !this.sharedUsers.find(
							(u) => u.userIdentifier == member.email || u.userIdentifier == member.id
						);
					});
				}
				return team;
			},
		},
	};
</script>
<style scoped>
	.custom-tooltip {
		background-color: rgb(3, 3, 32) !important;
		color: white !important;
		max-width: 400px !important;
		font-size: 14px;
		line-height: 1.4;
		white-space: normal !important;
		padding: 8px 12px;
		border-radius: 12px;
	}

	.custom-tooltip .v-tooltip__content {
		max-width: inherit !important;
	}

	.custom-tooltip .v-tooltip__content::before {
		background-color: blue !important;
	}

	.v-list-item.disabled-item .v-tooltip .v-tooltip__content {
		pointer-events: none;
	}
	.v-list-item.disabled-item {
		color: rgba(0, 0, 0, 0.38) !important;
		cursor: not-allowed !important;
	}

	.v-list-item.disabled-item .v-list-item__title,
	.v-list-item.disabled-item .v-list-item__content {
		font-weight: normal !important;
		color: inherit !important;
	}

	.v-list-item.disabled-item:hover {
		background-color: transparent !important;
	}
	.disabled-item {
		color: rgba(0, 0, 0, 0.38);
		pointer-events: auto;
		cursor: not-allowed;
	}
	.menu .v-btn {
		height: 44px;
		width: 44px;
		border: 2px solid var(--primary-project-icon);
	}

	.menu .v-btn .v-icon {
		font-size: 1.75rem;
		color: var(--primary-project-icon);
	}

	.menu > .menu-action {
		width: 82px;
		align-items: center;
		padding: 8px;
	}

	.menu > .menu-action div:nth-child(2),
	.menu > .menu-action .text {
		margin-top: 8px;
		width: 40px;
		font-size: 0.625rem;
		font-weight: 500;
		text-align: center;
	}

	.flex-grow-1 {
		flex-grow: 1;
	}

	.min-width-0 {
		min-width: 0;
	}

	.text-ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.v-card__title .v-icon.icon-share-contact-stroke {
		width: 40px;
		height: 40px;
		margin-right: 12px;
		border-radius: 50%;
		background-color: #dff0ff;
	}

	.v-card__actions .v-btn {
		flex: 1;
	}

	.text-small {
		font-size: 10px;
		max-width: 100%;
		inline-size: inherit;
		overflow-wrap: break-word;
		line-height: 1.5;
	}

	.min-wid {
		max-width: 300px !important;
	}

	.text-medium {
		font-size: 10px;
		font-weight: 900;
	}

	.v-card {
		border-radius: 20px;
	}

	.header {
		align-items: center;
		max-width: 100%;
	}

	.v-snack__wrapper.theme--dark {
		background-color: #fff !important;
	}

	.theme--dark.v-sheet {
		background-color: #fff !important;
	}

	.v-select__selection,
	.v-select__selection--comma,
	.v-select.v-text-field input {
		color: blue !important;
	}

	.v-autocomplete__selection,
	.v-autocomplete__selection--comma,
	.v-autocomplete.v-text-field input {
		color: blue !important;
	}

	.font-size-11 {
		font-size: 1.1rem;
	}

	.label {
		pointer-events: none;
		color: black;
		cursor: not-allowed;
		background-image: none;
		background-color: #fff;
		border-color: #193e63;
	}
</style>
