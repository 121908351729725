<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		:width="'80%'"
		:style="{ zIndex: options.zIndex }"
		@keydown.esc="cancel"
		@click:outside="cancel">
		<v-card class="pa-2">
			<v-card-title class="text-h5 pa-4" :style="{ 'word-break': 'normal' }">
				<h5>{{ title }}</h5>
			</v-card-title>
			<v-card-text class="pa-4" v-show="!!message" v-html="message"></v-card-text>
			<v-card-text class="pa-4" v-show="requireDeleteConfirmation">
				<v-text-field v-model="deleteConfirmationText" label="Type DELETE to confirm"></v-text-field>
			</v-card-text>

			<v-card-actions class="pb-4 d-flex justify-end align-center flex-wrap ga-2">
				<template v-if="!switchButtonStyles">
					<v-btn
						v-if="!removeCancelButton"
						id="confirm-action-btn"
						color="grey"
						text
						class="white-bg flex-grow-0 flex-shrink-0"
						rounded
						elevation="0"
						@click.native="agree"
						:disabled="disableConfirmationButton"
					>
						{{ agreeText }}
					</v-btn>
					<v-btn
						id="cancel-action-btn"
						:class="isAngry ? 'delete-btn' : ''"
						:color="options.color"
						class="flex-grow-0 flex-shrink-0"
						rounded
						elevation="0"
						@click.native="cancel"
					>
						{{ cancelText }}
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						v-if="!removeCancelButton"
						id="cancel-action-btn"
						class="white-bg flex-grow-0 flex-shrink-0"
						rounded
						elevation="0"
						color="grey"
						text
						@click.native="cancel"
					>
						{{ cancelText }}
					</v-btn>
					<v-btn
						rounded
						elevation="0"
						id="confirm-action-btn"
						:class="isAngry ? 'delete-btn' : ''"
						color="primary"
						class="flex-grow-0 flex-shrink-0"
						@click.native="agree"
						:disabled="disableConfirmationButton"
					>
						{{ agreeText }}
					</v-btn>
				</template>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	/**
	 * Vuetify Confirm Dialog component
	 *
	 * Insert component where you want to use it:
	 * <confirm ref="confirm"></confirm>
	 *
	 * Call it:
	 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
	 * Or use await:
	 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
	 *   // yes
	 * }
	 * else {
	 *   // cancel
	 * }
	 *
	 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
	 * <template>
	 *   <v-app>
	 *     ...
	 *     <confirm ref="confirm"></confirm>
	 *   </v-app>
	 * </template>
	 *
	 * mounted() {
	 *   this.$root.$confirm = this.$refs.confirm.open
	 * }
	 */
	export default {
		data: () => ({
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			requireDeleteConfirmation: false,
			deleteConfirmationText: null,
			title: null,
			switchButtonStyles: false,
			agreeText: "Yes",
			cancelText: "No",
			options: {
				color: "red",
				width: 500,
				zIndex: 10020,
			},
			isAngry: false,
			removeCancelButton: false,
		}),
		methods: {
			open(
				title,
				message,
				options,
				requireDeleteConfirmation,
				agreeText,
				cancelText,
				switchButtonStyles,
				isAngry,
				removeCancelButton
			) {
				this.dialog = true;
				this.title = title || "Are you sure?";
				this.agreeText = agreeText || "Yes";
				this.cancelText = cancelText || "No";
				this.switchButtonStyles = switchButtonStyles || false;
				this.message = message || "You can't undo this. Are you sure you want to continue?";
				this.options = Object.assign(this.options, options);
				this.isAngry = isAngry || false;
				this.requireDeleteConfirmation = requireDeleteConfirmation;
				this.removeCancelButton = removeCancelButton || false;
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			deleteOpen(title, message) {
				return this.open(title, message, {}, false, "Delete", "Cancel", true, true);
			},
			agree() {
				this.resolve(true);
				this.deleteConfirmationText = null;
				this.dialog = false;
			},
			cancel() {
				this.resolve(false);
				this.deleteConfirmationText = null;
				this.dialog = false;
				this.$nextTick(() => {
					document.activeElement.blur();
				});
			},
		},
		computed: {
			disableConfirmationButton() {
				return this.requireDeleteConfirmation && this.deleteConfirmationText != "DELETE";
			},
		},
	};
</script>

<style scoped>
	.v-btn.primary.delete-btn {
		background-color: var(--v-error-base) !important;
		color: #fff !important;
	}
</style>
