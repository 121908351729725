import { beautifyName } from "./utilities";
import moment from "moment";
import api from "@/api";
import StorageWrapper from "@/helpers/storage";
import * as Sentry from "@sentry/vue";

export function getNotificationTitle(notification) {
	try {
		if (!notification || !notification.type) {
			return "Unknown notification";
		}
		switch (notification.type) {
			case "comment": {
				const comment = notification.metadata.commentCreated;
				if (!comment) {
					return "New comment";
				}
				if (comment.parentType === "comment") {
					if (!comment.User) {
						return "A user replied to your comment.";
					}
					return `${beautifyName(comment.User.name)} replied to your comment.`;
				}
				if (!comment.User) {
					return `A user commented on ${getBeautifiedParentName(notification)}.`;
				}
				return `${beautifyName(comment.User.name)} commented on ${getBeautifiedParentName(notification)}.`;
			}
			case "mention": {
				const comment = notification.metadata.commentCreated;
				if (!comment) {
					return "You were mentioned";
				}
				if (comment.parentType === "comment") {
					return `${beautifyName(comment.User.name)} mentioned you in a reply.`;
				}
				return `${beautifyName(comment.User.name)} mentioned you in a comment.`;
			}
			case "update": {
				const user = notification.metadata?.updatedBy;
				let difference = notification.metadata?.difference;
				difference = cleanDifference(difference);
				if (!user) {
					return `${getBeautifiedParentName(notification)} updated.`;
				}
				if (!difference || difference.length === 0) {
					return `${beautifyName(user.name)} updated ${getBeautifiedParentName(notification)}.`;
				}
				return `${beautifyName(user.name)} ${getOperation(difference)} ${getBeautifiedParentName(
					notification
				)}'s ${getBeautifiedFieldNames(difference, notification.parentType)}.`;
			}
			case "share": {
				const user = notification.metadata?.updatedBy;
				if (!user) {
					return `${getBeautifiedParentName(notification)} shared with you.`;
				}
				return `${beautifyName(user.firstname)} shared a ${getBeautifiedParentName(
					notification
				)} with you as ${beautifyName(getAccessType(notification))}.`;
			}
			default:
				return `${getBeautifiedParentName(notification)} ${notification.type}.`;
		}
	} catch (error) {
		console.error("Failed to get notification title: ", error);
		return "Unknown notification";
	}
}

function cleanDifference(difference) {
	if (!difference) {
		return {};
	}
	if (Object.keys(difference).length == 1) {
		for (const key of Object.keys(difference)) {
			if (
				difference[key].source &&
				difference[key].target &&
				Array.isArray(difference[key].source) &&
				(difference[key].source.length - difference[key].target.length === 1 ||
					difference[key].source.length - difference[key].target.length === -1)
			) {
				if (key.charAt(key.length - 1) === "s") {
					difference[key.slice(0, -1)] = difference[key];
					delete difference[key];
				}
			}
		}
	}
	return difference;
}

function getOperation(difference) {
	return "updated ";
}

function getBeautifiedParentName(notification) {
	if (notification.parentType === "conversation") {
		return "Session";
	}
	return beautifyName(notification.parentType);
}

function getBeautifiedFieldName(key) {
	switch (key) {
		case "workflowCustomerId":
		case "customerId":
			return " Contact";
		case "projectId":
			return " Project";
		case "conversationId":
			return " Session";
		case "forcedUserId":
			return " User";
		case "addressId":
			return " Address";
		default: {
			const beautifiedKey = key.charAt(0).toUpperCase() + key.slice(1);
			return beautifiedKey
				.replace("Customer", "Contact")
				.replace("Conversation", "Session")
				.replace("ForcedUser", "User");
		}
	}
}

function getBeautifiedFieldNames(difference, parentType) {
	let allNames = Object.keys(difference)
		.filter((key) => (parentType === "project" ? true : key.charAt(0) === key.charAt(0).toLowerCase()))
		.map((key) => `${getBeautifiedFieldName(key)}`);
	// unique
	allNames = [...new Set(allNames)];
	// use & for the last two items
	if (allNames.length > 1) {
		const lastTwo = allNames.slice(-2);
		allNames = allNames.slice(0, -2).concat(`${lastTwo[0]} and ${lastTwo[1]}`);
	}
	return allNames.join(", ");
}

function getAccessType(notification) {
	if (notification.type === "share") {
		return beautifyName(notification.metadata.projectShare.accessType);
	}
	return null;
}

export function getNotificationIcon(notification) {
	switch (notification.parentType) {
		case "project":
			return "icon-form";
		case "artifact": {
			const comment = notification.metadata.commentCreated;
			if (!comment) {
				return "icon-table-stroke";
			}
			if (comment.parentType === "photo") {
				return "icon-image";
			}
			return "icon-ls-video-logo";
		}
		case "comment":
			return "icon-comment-stroke";
		case "conversation":
			return "icon-conversation-solid";
		default:
			return "icon-table-stroke";
	}
}

export function getNotificationTime(notification) {
	// relative time
	return moment(notification.createdAt).fromNow();
}

export const clickNotificationMixin = {
	methods: {
		async clickNotification(notification) {
			// redirect based on the notification type
			const currentOrgId = api.getOrganizationId();
			try {
				await api.updateNotification(notification.id, { isRead: true });
				if (notification.type === "mention" || notification.type === "comment") {
					const comment = notification.metadata.commentCreated;
					const currComment = await api.getComment(comment.id);
					if (currComment.deletedAt) {
						await this.openNotFoundDialog("Comment");
						return;
					}
				}
				let url = "";
				//TODO: Sindhuja, R66: add unit tests to to test all of the different permutations
				switch (notification.parentType) {
					case "project": {
						url = await this.getUrlForProject(
							notification.parentId,
							`/projects/${notification.parentId}`,
							notification.id
						);
						break;
					}
					case "conversation": {
						try {
							const conversation = await api.getConversationInsecure(notification.parentId);
							if (conversation.organizationId !== currentOrgId) {
								return await this.openSwitchOrganizationDialog(
									conversation.organizationId,
									`/conversations/${conversation.id}`
								);
							}
						} catch (error) {
							//TODO: Sindhuja, R66: handle other errors
							await this.openNotFoundDialog("Session", error);
							return;
						}
						url = `/conversations/${notification.parentId}`;
						break;
					}
					case "mention":
					case "comment": {
						const comment = notification.metadata.commentCreated;
						try {
							const commentOriginal = await api.getComment(comment.id);
							if (commentOriginal.deletedAt) {
								throw new Error("Comment deleted");
							}
						} catch (error) {
							//TODO: Sindhuja, R66: handle other errors
							await this.openNotFoundDialog("Comment", error);
							return;
						}
						const conversationId = comment.conversationId;
						let projectId = null;
						let orgId = null;
						let conversation = null;
						if (conversationId) {
							try {
								conversation = await api.getConversationInsecure(conversationId);
								projectId = conversation.projectId;
								orgId = conversation.organizationId;
							} catch (error) {
								orgId = null;
							}
						}
						if (!orgId) {
							orgId = comment.organizationId;
						}
						switch (comment.parentType) {
							case "conversation": {
								if (conversationId && !conversation) {
									await this.openNotFoundDialog("Session");
									return;
								} else if (!conversationId) {
									try {
										conversation = await api.getConversationInsecure(comment.parentId);
									} catch (error) {
										//TODO: Sindhuja, R66: handle other errors
										await this.openNotFoundDialog("Session", error);
										return;
									}
								}
								if (orgId !== currentOrgId) {
									return await this.openSwitchOrganizationDialog(
										orgId,
										`/conversations/${comment.parentId}`
									);
								}
								url = `/conversations/${comment.parentId}`;
								break;
							}
							case "project":
								url = await this.getUrlForProject(
									comment.parentId,
									`/projects/${comment.parentId}`,
									notification.id
								);
								break;
							case "video":
							case "photo": {
								if (projectId) {
									url = await this.getUrlForProject(
										projectId,
										`/projects/${projectId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`,
										notification.id
									);
								} else if (conversationId) {
									if (orgId !== currentOrgId) {
										return await this.openSwitchOrganizationDialog(
											orgId,
											`/conversations/${conversationId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`
										);
									}
									url = `/conversations/${conversationId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`;
								}
								break;
							}
							case "comment": {
								const originalComment = await api.getComment(comment.parentId);
								const originalConversationId = originalComment.conversationId;
								let originalProjectId = null;
								let originalOrgId = null;
								if (originalConversationId) {
									try {
										const originalConversation = await api.getConversationInsecure(
											originalConversationId
										);
										originalProjectId = originalConversation.projectId;
										originalOrgId = originalConversation.organizationId;
									} catch (error) {
										//TODO: Sindhuja, R66: handle other errors
										await this.openNotFoundDialog("Session", error);
										return;
									}
								}
								if (!originalOrgId) {
									originalOrgId = originalComment.organizationId;
								}
								switch (originalComment.parentType) {
									case "conversation":
										if (originalOrgId !== currentOrgId) {
											return await this.openSwitchOrganizationDialog(
												originalOrgId,
												`/conversations/${originalComment.parentId}`
											);
										}
										url = `/conversations/${originalComment.parentId}`;
										break;
									case "project":
										url = `/projects/${originalComment.parentId}`;
										break;
									case "video":
									case "photo":
										{
											if (originalProjectId) {
												url = await this.getUrlForProject(
													originalProjectId,
													`/projects/${originalProjectId}?artifactId=${originalComment.parentId}&artifactType=${originalComment.parentType}`,
													notification.id
												);
											} else if (originalConversationId) {
												if (originalOrgId !== currentOrgId) {
													return await this.openSwitchOrganizationDialog(
														originalOrgId,
														`/conversations/${originalConversationId}?artifactId=${originalComment.parentId}&artifactType=${originalComment.parentType}`
													);
												}
												url = `/conversations/${originalConversationId}?artifactId=${originalComment.parentId}&artifactType=${originalComment.parentType}`;
											}
										}
										break;
									default:
										url = await this.getUrlForProject(
											originalComment.parentId,
											`/projects/${originalComment.parentId}`,
											notification.id
										);
										break;
								}
								break;
							}
							default:
								if (conversationId) {
									url = `/conversations/${conversationId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`;
								}
								break;
						}
						break;
					}
					default:
						break;
				}
				return url;
			} catch (error) {
				console.error("Failed to redirect to notification: ", error);
			}
		},
		async getUrlForProject(projectId, baseUrl, notificationId) {
			try {
				const shareIdResponse = await api.getShareIdForProject(projectId);
				if (!shareIdResponse) {
					await this.openNotFoundDialog("Project");
					return null;
				}
				const shareId = shareIdResponse.shareId;
				if (baseUrl.includes("?")) {
					return `${baseUrl}&shareId=${shareId}`;
				}
				return `${baseUrl}?shareId=${shareId}`;
			} catch (error) {
				Sentry.captureException(error, {
					tags: {
						method: "getUrlForProject",
						file: "notifications",
					},
					extra: {
						projectId,
						baseUrl,
						notificationId,
					},
				});
				await this.openNotFoundDialog("Project", error);
				return null;
			}
		},
		async openSwitchOrganizationDialog(organizationId, path) {
			const output = await this.$root.$confirm.open(
				"Switch Organization to View",
				"The notification you are trying to view requires you to switch organizations to view.",
				null,
				null,
				"Switch Organization",
				"Back",
				true
			);
			if (!output) {
				return;
			}
			api.setOrganizationId(organizationId);
			StorageWrapper.setItem("login-path", path);
			this.$auth.login({
				organizationId: organizationId,
				path: window.location.path,
			});
		},
		async openNotFoundDialog(entity, error = null) {
			await this.$root.$confirm.open(
				`${entity} has been deleted`,
				`The ${entity.toLowerCase()} you are trying to view has been deleted and is no longer available.`,
				null,
				null,
				"OK",
				null,
				true,
				false,
				true
			);
			if (error) {
				Sentry.captureException(error, {
					tags: {
						method: "clickNotification",
						file: "notifications",
					},
					extra: {
						entity: entity,
					},
				});
			}
		},
	},
};

export async function readNotification(notification) {
	await api.updateNotification(notification.id, { isRead: true });
}
