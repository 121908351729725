<template>
	<div class="overlay-container" v-if="$vuetify.breakpoint.smAndDown">
		<v-overlay v-if="dialog" :z-index="10010" class="upgrade-overlay-mobile">
			<v-row class="align-center justify-center">
				<v-col cols="12" md="8" class="text-center pa-4">
					<h5 class="text-black">
						<a @click.prevent="$emit('upgrade')">Upgrade</a>
						your subscription to access all features.
					</h5>
				</v-col>
			</v-row>
		</v-overlay>
		<v-btn v-show="dialog" icon @click="close" class="close-button" color="black">
			<v-icon>icon-close-large</v-icon>
		</v-btn>
	</div>
	<div v-else>
		<v-overlay z-index="10010" v-if="dialog" class="upgrade-overlay">
			<v-snackbar
				v-model="dialog"
				color="upgrade"
				:multi-line="true"
				timeout="-1"
				:centered="true"
				class="upgrade-zone">
				<div>
					<h3 v-if="isTrialCCNow">
						Start Your Free Trial.
						<v-btn icon @click="close">
							<v-icon>icon-close-medium</v-icon>
						</v-btn>
					</h3>
					<h3 v-else>
						Upgrade to Access.
						<v-btn icon @click="close">
							<v-icon>icon-close-medium</v-icon>
						</v-btn>
					</h3>
					<div v-if="isTrialCCNow">
						<p class="upgrade-message" v-if="canUpdateOrg">
							This is a premium feature. <a @click.prevent="$emit('upgrade')">Select your plan</a> to
							start your free trial and get access to this feature.
						</p>
						<p class="upgrade-message" v-else>
							This is a premium feature. Contact an administrator to select your plan to start your free
							trial and get access to this feature.
						</p>
					</div>
					<div v-else>
						<p class="upgrade-message" v-if="!canUpdateOrg">
							This is a premium feature. Contact an administrator to upgrade your subscription to access
							all features.
						</p>
						<p class="upgrade-message" v-else-if="trialExpired && !subscription.hasPaymentMethod">
							Your free trial has ended. <a @click.prevent="$emit('upgrade')">Add a payment method</a>
							to continue using premium features.
						</p>
						<p class="upgrade-message" v-else-if="trialExpired">
							Your free trial has ended. Your account needs attention. Please access your subscription
							info in the <a @click.prevent="$emit('upgrade')">customer billing portal.</a>
						</p>
						<p class="upgrade-message" v-else-if="trialDaysRemaining > 0 && !subscription.hasPaymentMethod">
							{{ trialDays }} remaining on your free trial.
							<a @click.prevent="$emit('upgrade')">Add a payment method</a>
							to continue using once your trial is finished.
						</p>
						<p class="upgrade-message" v-else-if="trialDaysRemaining > 0 && subscription.hasPaymentMethod">
							{{ trialDays }} remaining on your free trial. At the end of your trial, we will
							automatically charge the card on file.
						</p>
					</div>
				</div>
			</v-snackbar>
		</v-overlay>
	</div>
</template>

<script>
	import Account from "../api/account";
	import api from "@/api";
	import StorageWrapper from "@/helpers/storage";
	import constants from "@/api/constants";

	export default {
		data() {
			return {
				dialog: false,
				organizationId: null,
				canUpdateOrg: false,
				isTrialCCNow: false,
				subscription: null,
				userEmail: null,
				trialDaysRemaining: null,
			};
		},
		methods: {
			async open(organization) {
				this.dialog = true;
				let cachedAccount;
				try {
					cachedAccount = JSON.parse(StorageWrapper.getItem("account"));
				} catch (e) {}
				if (!cachedAccount) {
					const me = await api.getMe();
					cachedAccount = me.account;
				}
				const account = new Account(cachedAccount);
				this.canUpdateOrg = account.hasPermissionTo("UPDATE_ORGANIZATION");
				this.organizationId = organization.id;
				this.isTrialCCNow = organization.isTrialCCNow;
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			setSubscriptionInfo(subscription, userEmail, trialDaysRemaining) {
				this.subscription = subscription;
				this.userEmail = userEmail;
				this.trialDaysRemaining = trialDaysRemaining;
			},
			close() {
				if (this.resolve) {
					this.resolve(false);
				}
				this.dialog = false;
			},
			upgrade() {
				if (this.subscription && this.subscription.accountStatus !== "active") {
					window.location = `${window.env.VITE_STRIPE_URL}?prefilled_email=${this.userEmail}`;
				} else {
					const url = this.subscriptionUrl;
					if (url) {
						this.$router.push(url);
						this.close();
					}
				}
			},
		},
		computed: {
			subscriptionUrl() {
				if (this.organizationId) {
					return `/subscriptions/${this.organizationId}`;
				} else {
					return "";
				}
			},
			trialDays() {
				if (this.trialDaysRemaining != 1) {
					let days = this.trialDaysRemaining;

					if (days < 0) {
						days = 0;
					}

					return `${days} days`;
				} else {
					return "1 day";
				}
			},
			trialExpired() {
				return this.subscription?.accountStatus === constants.SUBSCRIPTION_STATUS.TRIAL_EXPIRED;
			},
		},
	};
</script>
<style scoped>
	.overlay-container {
		position: relative;
	}

	.upgrade-overlay {
		background-color: rgba(189, 191, 195, 0.8);
	}

	.upgrade-overlay a {
		cursor: pointer;
	}

	.upgrade-overlay::v-deep > .v-overlay__scrim {
		background-color: rgba(189, 191, 195, 0.8) !important;
	}

	.upgrade-overlay-mobile {
		background-color: white;
	}

	.upgrade-overlay-mobile::v-deep > .v-overlay__scrim {
		background-color: white !important;
	}

	.close-button {
		position: fixed !important;
		top: 16px;
		right: 16px;
		z-index: 10011;
	}

	.text-black {
		color: black;
	}

	a {
		text-decoration: underline;
	}

	.upgrade-zone::v-deep .v-snack__wrapper {
		border-left-width: 8px;
		border-left-style: solid;
	}

	.upgrade-zone::v-deep .v-snack__wrapper h3 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 5px;
		font-size: 1.5rem;
	}

	.upgrade-zone::v-deep .v-snack__wrapper.upgrade h3 > button {
		margin-top: -10px;
		margin-right: -10px;
		font-size: 12px;
		color: #e65100 !important;
	}

	.upgrade-zone::v-deep .v-snack__wrapper.upgrade {
		border-left-color: #e65100 !important;
		background-color: #faeef0 !important;
		color: #e65100 !important;
	}

	.upgrade-message {
		word-break: break-word;
	}
</style>
