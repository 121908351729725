import DeviceUtility from "@/helpers/device";

export const closeActionDialog = {
	methods: {
		close() {
			this.reset();
			if (DeviceUtility.isMobile) {
				if (this.$route.query.from == "homepage") {
					this.$router.push("/");
				}
			}
			this.$emit("close-menu", true);
		},
	},
};
