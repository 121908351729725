<template>
	<div
		v-if="this.$vuetify.breakpoint.smAndDown || lightBoxView"
		class="d-flex flex-column justify-start align-center ma-12 mt-0">
		<v-icon size="80" color="black">icon-chat-stroke</v-icon>
		<div v-if="!isViewOnly && !isUnauthenticated" class="d-flex justify-center">Leave the first comment.</div>
		<div v-else-if="isUnauthenticated" class="text-h6 text-center">
			<div class="d-flex justify-center pb-2 text-h6 font-weight-bold">Looks like you aren't signed in...</div>
			<div class="d-flex justify-center text-subtitle-1">Sign in to view others' comments or leave your own.</div>
		</div>
		<div v-else-if="isViewOnly && !hasFreeTrialEnded" class="text-h6 text-center">
			<div class="d-flex justify-center pb-4 text-h6 font-weight-bold">Looks like you don't have permission to comment...</div>
			<div class="d-flex justify-center text-subtitle-1">If you would like to comment, please ask an admin to grant you 'Editor' permissions for the project.</div>
		</div>
		<div v-else class="text-h6 text-center">
			<div class="d-flex justify-center pb-4">No comments yet.</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			isViewOnly: {
				required: true,
				default: false,
			},
			isUnauthenticated: {
				required: false,
				default: false,
			},
			lightBoxView: {
				required: true,
				default: false,
			},
			hasFreeTrialEnded: {
				required: true,
				default: false,
			},
		},
	};
</script>

