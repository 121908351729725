import { User } from "@/types/User";

export function formatCommentMentions(content: string | null | undefined, usersMap: Map<string, string>) {
	if (!content) return "";
	if (!content.includes("@")) return content;

	// UUID pattern: 8-4-4-4-12 hexadecimal characters
	const mentionPattern = /@[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;

	return content.replace(mentionPattern, (match) => {
		const userId = match.slice(1); // Remove @ symbol
		const userName = usersMap.get(userId);
		return userName ? `<split>@${userName}<split>` : match;
	});
}
