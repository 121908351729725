<template>
	<div
		v-if="!isSignUpScreen"
		class="d-flex flex-column signup-bar"
		@click.stop="conditionallyToggleExpand"
		:class="{ 'justify-center': !isDesktop, expanded: isExpanded }">
		<div :class="{ expanded: isExpanded }">
			<div class="d-flex flex-column justify-center align-center text-center py-0 px-5" style="gap: 16px;" :class="{ expanded: isExpanded }">
				<div class="signup-text ">
						Create a <span class="highlight">free</span> account <span v-if="isExpanded"> or log in to add photos, videos, or documents. </span>
				</div>
				<div v-if="isExpanded" class="subtitle-text">
						*Accounts must have Editor access to contribute.
				</div>
				<v-btn
					v-if="isExpanded"
					rounded
					elevation="0"
					color="primary"
					class="create-account-btn"
					@click="goToSignUp">
					Create Account
				</v-btn>
			</div>
			<div v-if="!isDesktop" class="caret-button">
				<v-icon class="max-height-limit" @click.stop="toggleExpand">{{
					isExpanded ? "icon-chevron-down" : "icon-chevron-up"
				}}</v-icon>
			</div>
			
		</div>
	</div>
	<div v-else>
		<div class="signup-page">
			<SignUpScreen @close="goToSignUp" @signup-success="goToSignUp" />
		</div>
	</div>
</template>

<script>
	import SignUpScreen from "./SignUpScreen.vue";
	import DeviceUtility from "@/helpers/device";
	export default {
		components: {
			SignUpScreen,
		},
		name: "SignUpPrompt",
		data() {
			return {
				isExpanded: true,
				isSignUpScreen: false,
			};
		},
		computed: {
			isDesktop() {
				return DeviceUtility.isDesktop;
			},
		},
		mounted() {
			if (this.isDesktop) {
				this.isExpanded = true;
			}
			if (this.$router.currentRoute.query.isSigningUp === "true") {
				this.isSignUpScreen = true;
				this.$emit("signup-clicked", this.isSignUpScreen);
			}
		},
		methods: {
			conditionallyToggleExpand() {
				if (!this.isDesktop && !this.isExpanded) {
					this.isExpanded = true;
				}
			},
			toggleExpand() {
				this.isExpanded = this.isDesktop ? true : !this.isExpanded;
			},
			goToSignUp() {
				this.isSignUpScreen = !this.isSignUpScreen;
				// add isSigningUp=true to the current url
				this.$router.push({
					path: this.$router.currentRoute.path,
					query: {
						shareId: this.$router.currentRoute.query.shareId,
						isSigningUp: this.isSignUpScreen,
					},
				});
				this.$emit("signup-clicked", this.isSignUpScreen);
			},
			handleSignupSuccess() {
				this.isSignUpScreen = false;
				this.$emit("signup-success");
			},
		},
	};
</script>

<style scoped>
	.subtitle-text {
		font-size: 0.75rem;
		font-weight: 300;
	}
	.signup-page {
		position: fixed;
		display: flex;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: white;
	}

	.signup-bar {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: white;
		transition: all 0.3s ease;
		display: flex;
		align-items: center;
		padding: 20px;
		border-top: 1px solid #ddd;
		overflow: hidden;
	}

	.signup-bar.expanded {
		display: block;
		padding: 20px;
	}

	.content-wrapper {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
	}

	.content-wrapper.expanded {
		flex-direction: column;
		justify-content: center;
	}

	.caret-button {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background: none;
		border: none;
		cursor: pointer;
		padding: 8px;
	}

	.signup-bar.expanded .caret-button {
		top: 14px;
		transform: none;
	}

	.caret-button.rotate {
		transform: rotate(180deg);
	}

	.signup-text {
		font-size: 18px;
		font-weight: 700;
		color: #333;
		margin: 0;
		justify-content: center;
		text-align: center;
	}

	.highlight {
		color: #007bff;
		font-style: italic;
	}

	/* Ensure the bar stays above other content */
	.signup-bar {
		z-index: 201;
	}

	.full-width {
		width: 100%;
	}
	.sixty-width {
		width: 60%;
	}

	.eighty-width {
		width: 80%;
	}

	.max-height-limit {
		max-height: 32px;
	}

	@media (min-width: 960px) {
		.signup-bar {
			top: 40% !important;
			right: 0 !important;
			left: unset !important;
			bottom: unset !important;
			background-color: transparent !important;
			border: none !important;
			max-width: 33%;
		}
		.signup-text {
			padding-right: 0;
		}
	}
</style>
