<template>
	<v-autocomplete
		class="minimize-height"
		ref="customerSearch"
		hide-details
		outlined
		:label="label"
		auto-select-first
		no-data-text="No contacts found"
		counter="60"
		:disabled="disabled"
		:value="value"
		@input="$emit('input', $event)"
		:item-text="getCustomerDisplayName"
		:search-input.sync="searchCustomer"
		item-value="id"
		return-object
		multiple
		chips
		deletable-chips
		@change="$emit('change', $event)"
		:items="customers"
		@update:search-input="$emit('update:search-input', $event)"
		:maxLength="60"
		:menu-props="{ maxWidth: customerMenuWidth }">
		<template v-slot:selection="{ item }">
			<v-chip
				@click:close="$emit('remove', item)"
				class="my-custom-chip"
				close
				closable
				close-icon="icon-close-small"
				:title="item.name">
				<span class="chip-text">
					<span v-if="item.firstname && item.lastname">{{ item.firstname }} {{ item.lastname }}</span>
					<span v-else-if="item.firstname">{{ item.firstname }}</span>
					<span v-else-if="item.phone">{{ item.phone }}</span>
					<span v-else-if="item.email">{{ item.email }}</span>
				</span>
			</v-chip>
		</template>

		<template v-slot:append>
			<v-icon color="info" :disabled="disabled" @click="$emit('create-customer', searchCustomer)" v-test="'create-contact-icon'">icon-plus</v-icon>
		</template>
	</v-autocomplete>
</template>

<script>
	import { getCustomerDisplayName } from "../helpers/utilities";
	import { getSearchLabelForCustomers } from "../formatters/customers";
	import { debounce } from "../helpers/utilities";
	import api from "../api";
	export default {
		name: "CustomerAutocomplete",

		props: {
			value: {
				type: Array,
				required: true,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			label: {
				type: String,
				default: "Contact (Optional)",
			},
		},

		data() {
			return {
				searchCustomer: "",
				items: [],
				customerMenuWidth: 0,
				debouncedSearch: debounce(async (searchTerm) => {
					const results = await api.searchCustomers(searchTerm);
					this.items = [...this.value, ...results.rows];
				}, 500),
			};
		},
		async mounted() {
			this.debouncedSearch(this.searchCustomer);
			this.customerMenuWidth = this.$refs.customerSearch?.$el.getBoundingClientRect().width + "px";
		},
		computed: {
			customers() {
				return getSearchLabelForCustomers(this.items);
			},
		},
		watch: {
			searchCustomer: function (newVal) {
				this.debouncedSearch(newVal);
			},
			value: function (newVal) {
				this.debouncedSearch(this.searchCustomer);
			},
		},
		methods: {
			getCustomerDisplayName,
			closeMenu() {
				this.$refs.customerSearch.isMenuActive = false;
			},
		},
	};
</script>
