<template>
	<v-snackbar v-model="dialog" color="primary" :multi-line="true" timeout="-1" top class="toast">
		<div v-if="title">
			<div class="d-flex">
				<h3>
					{{ title }}
				</h3>
				<v-spacer></v-spacer>
				<v-btn id="close-toast" icon @click="close">
					<v-icon>icon-close-medium</v-icon>
				</v-btn>
			</div>
			<div>
				<span class="message" v-show="!!message">{{ message }}</span>
			</div>
		</div>
		<div class="d-flex align-center" v-else>
			<div>
				<span class="message" v-show="!!message">{{ message }}</span>
			</div>
			<v-spacer></v-spacer>
			<v-btn icon @click="close">
				<v-icon>icon-close-medium</v-icon>
			</v-btn>
		</div>
	</v-snackbar>
</template>

<script>
	import { EventBus } from "../helpers/eventBus";

	export default {
		data: () => ({
			dialog: false,
			message: null,
			title: null,
		}),
		methods: {
			open(title, message, timeout) {
				if (!title && !message) {
					return;
				}

				this.dialog = true;
				this.title = title || this.title;
				this.message = message || this.message;
				setTimeout(() => {
					this.dialog = false;
					//If auto closes
					EventBus.$emit("EnableCloseOnClick");
				}, timeout ?? 5000);
			},
			close() {
				this.dialog = false;
				EventBus.$emit("EnableCloseOnClick");
			},
		},
	};
</script>

<style scoped>
	.toast::v-deep .v-snack__wrapper {
		border-left-width: 8px;
		border-left-style: solid;
	}

	.toast::v-deep .v-snack__wrapper h3 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 5px;
		font-size: 1.5rem;
	}

	.toast::v-deep .v-snack__wrapper h3 > button {
		margin-top: -10px;
		margin-right: -10px;
		font-size: 12px;
	}

	.toast::v-deep .v-snack__wrapper.error h3 > button {
		color: #e84867 !important;
	}

	.toast::v-deep .v-snack__wrapper.error button.error {
		background-color: #e84867 !important;
		float: right;
	}

	.toast::v-deep .v-snack__wrapper.error {
		border-left-color: #e84867 !important;
		background-color: #faeef0 !important;
		color: #e84867 !important;
	}

	.message {
		word-break: break-word;
	}
</style>
