<template>
	<div class="comment-box" :class="getBoxClass" style="flex: 1">
		<div
			class="d-flex flex-column"
			style="flex: 1"
			:class="isReplyBoxOpen && lightBoxView ? 'height-100' : ''"
			:style="isMobile ? { maxHeight: isEditing || isReplyBoxOpen ? '90vh' : '65vh' } : {}">
			<div class="d-flex justify-space-between align-center" v-if="this.$vuetify.breakpoint.smAndDown">
				<div class="d-flex justify-center text-subtitle-1 font-weight-bold item center">
					Comments ({{ showCount }})
				</div>
				<v-btn class="item right" icon @click="closeCommentBox">
					<v-icon>icon-close-large</v-icon>
				</v-btn>
			</div>
			<v-card-title v-else :class="parentType == 'conversation' ? '' : 'pl-0'">
				<div class="d-flex media-header mb-4" v-test="'comments'">
					<v-icon>icon-chat-stroke</v-icon>
					<span class="heading font-size-16">Comments ({{ showCount }})</span>
				</div>
			</v-card-title>
			<AddComment
				v-if="canAddComment && $vuetify.breakpoint.mdAndUp && !lightBoxView"
				ref="addCommentRef"
				:project-id="projectId"
				:project-share-users="projectShareUsers"
				:class="getAddCommentClass"
				:lightBoxView="lightBoxView"
				:users="users"
				@update:addComment="addComment" />
			<div
				style="flex: 1; overflow-y: auto"
				:style="isMobile ? { maxHeight: isEditing || isReplyBoxOpen ? '90vh' : '65vh' } : {}">
				<div
					class="comments-container"
					ref="commentsContainer"
					style="flex: 1 1 0px"
					:style="$vuetify.breakpoint.smAndDown ? pullStyle : desktopStyle"
					:class="getContainerClass">
					<NoCommentView
						:is-view-only="isViewOnly"
						:is-unauthenticated="!me?.user"
						:has-free-trial-ended="hasFreeTrialEnded"
						:light-box-view="lightBoxView"
						v-if="
							(comments.length === 0 && !loadingComments) || (isViewOnly && !hasFreeTrialEnded) || !me
						" />
					<CommentsList
						v-else-if="comments.length > 0"
						:comments="comments"
						:is-view-only="isViewOnly"
						:project-share-users="projectShareUsers"
						:allow-more="allowMore"
						:light-box-view="lightBoxView"
						:users="users"
						:me="me"
						@update-comment="updateComment"
						@reduce-comment-count="reduceCommentCount"
						@edit-comment="editComment"
						@load-more-comments="loadMoreComments"
						@delete-comment="deleteComment"
						@toggle-reply-box="toggleReplyBox" />
					<div class="d-flex justify-center" :class="{ 'loading-indicator-container': lightBoxView }" v-else>
						<!-- <v-icon>icon-loading-dots-circle</v-icon> -->
						<v-progress-circular
							indeterminate
							color="primary"
							class="loading-indicator"></v-progress-circular>
					</div>
				</div>
			</div>
		</div>
		<AddComment
			v-if="canAddComment && ($vuetify.breakpoint.smAndDown || lightBoxView)"
			ref="addCommentRef"
			:project-id="projectId"
			:project-share-users="projectShareUsers"
			:class="getAddCommentClass"
			:lightBoxView="lightBoxView"
			:users="users"
			@update:addComment="addComment" />
	</div>
</template>

<script>
	import AddComment from "./AddComment.vue";
	import CommentsList from "./CommentsList.vue";
	import NoCommentView from "./NoCommentView.vue";
	import DeviceUtility from "@/helpers/device";
	import api from "@/api";
	import EventBus from "@/helpers/eventBus";
	import { AccessType } from "@/enums/AccessType";

	export default {
		components: {
			AddComment,
			CommentsList,
			NoCommentView,
		},
		props: {
			parentId: {
				type: String,
				required: true,
			},
			parentType: {
				type: String,
				required: true,
			},
			lightBoxView: {
				type: Boolean,
				default: false,
			},
			isViewOnly: {
				type: Boolean,
				default: false,
			},
			conversationId: {
				type: String,
				default: null,
			},
			projectId: {
				type: String,
				default: null,
			},
			users: {
				type: Array,
				default: () => [],
				required: true,
			},
			me: {
				type: Object,
				required: false,
			},
			hasFreeTrialEnded: {
				type: Boolean,
				required: false,
			},
		},
		data() {
			return {
				comments: [],
				commentCount: 0,
				loadingComments: true,
				isReplyBoxOpen: false,
				isEditing: false,
				atBottom: false,
				touchStartY: 0,
				currentPull: 0,
				isPulling: false,
				pullThreshold: 50,
				isSticky: false,
				currentPages: 1,
				currentCount: 0,
				showCount: 0,
				hysteresisOffset: 20,
				scrollThreshold: 1050,
				stickyCommentStyle: {},
				commentBoxTop: {},
				projectShares: [],
			};
		},
		beforeDestroy() {
			if (DeviceUtility.isDesktop) {
				window.removeEventListener("scroll", this.checkStickyPosition);
			}
		},
		async mounted() {
			if (DeviceUtility.isDesktop) {
				window.addEventListener("scroll", this.checkStickyPosition);
			}
			try {
				this.projectShares = await api.getProjectShares({ where: { projectId: this.projectId } });
			} catch (error) {
				console.error("Error getting project shares", error);
			}
			try {
				await this.loadComments(); // Ensure comments are loaded after setting up the scroll
			} catch (error) {
				console.error("Error loading comments", error);
			}
		},
		methods: {
			closeCommentBox() {
				this.$emit("close-comment-box");
				if (this.isReplyBoxOpen) {
					EventBus.$emit("CloseReplies");
				}
			},
			reduceCommentCount() {
				this.showCount--;
			},
			async loadMoreComments() {
				this.currentPages++;
				await this.loadComments();
			},
			checkStickyPosition() {
				this.commentBoxTop = this.$refs.commentsContainer?.getBoundingClientRect().top;
				const headerLeft = document.querySelector(".project-date")?.getBoundingClientRect().left || 0;
				if (this.commentBoxTop > this.scrollThreshold && !this.isSticky) {
					this.scrollThreshold -= this.hysteresisOffset;
					this.isSticky = true;
					this.stickyCommentStyle = {
						left: `${headerLeft - 8}px`,
					};
				} else if (this.commentBoxTop < this.scrollThreshold + this.hysteresisOffset && this.isSticky) {
					this.isSticky = false;
					this.stickyCommentStyle = {};
					this.scrollThreshold += this.hysteresisOffset;
				}
			},
			handleTouchStartForCommentHeader(event) {
				const container = this.$refs.commentHeader;
				this.handleTouchStart(event, container);
			},
			handleTouchStart(event, container = this.$refs.commentsContainer) {
				if (!this.lightBoxView) {
					return;
				}
				if (container.scrollTop === 0) {
					this.touchStartY = event.touches[0].clientY;
					this.isPulling = true;
				}
			},
			handleTouchMoveForCommentHeader(event) {
				const container = this.$refs.commentHeader;
				this.handleTouchMove(event, container);
			},
			handleTouchMoveForCommentBox(event) {
				const container = this.$refs.commentBoxRef;
				this.handleTouchMove(event, container);
			},
			handleTouchStartForCommentBox(event) {
				const container = this.$refs.commentBoxRef;
				this.handleTouchStart(event, container);
			},
			handleTouchMove(event, container = this.$refs.commentsContainer) {
				if (container.scrollTop === 0 && container.scrollHeight > container.clientHeight) {
					if (event.touches[0].screenY > event.touches[0].screenY) {
						event.preventDefault();
					}
				} else if (container.scrollTop + container.clientHeight === container.scrollHeight) {
					if (event.touches[0].screenY < event.touches[0].screenY) {
						event.preventDefault();
					}
				}
				if (this.isPulling && container.scrollTop === 0) {
					const touchY = event.touches[0].clientY;
					const pull = touchY - this.touchStartY;

					// Only allow pulling down, not up
					if (pull > 0) {
						// Add resistance to the pull
						this.currentPull = pull / 2;
						event.preventDefault();
					}
				}
				event.stopPropagation();
			},
			handleTouchEnd() {
				if (!this.lightBoxView) {
					return;
				}
				if (this.isPulling) {
					if (this.currentPull > this.pullThreshold) {
						this.$emit("close-comment-box");
						if (this.isReplyBoxOpen) {
							EventBus.$emit("CloseReplies");
						}
					}

					// Reset pull state
					this.currentPull = 0;
					this.isPulling = false;
				}
			},
			async loadComments() {
				if ((this.isViewOnly && !this.hasFreeTrialEnded) || !this.me) {
					this.loadingComments = false;
					return;
				}

				this.comments = [];
				this.commentCount = 0;
				this.showCount = 0;

				this.loadingComments = true;
				let comments = [],
					commentsToShow = [];
				try {
					[comments, commentsToShow] = await Promise.all([
						api.getComments({
							where: { parentId: this.parentId },
							pageSize: 50 * this.currentPages,
						}),
						api.getComments({
							where: {
								parentId: this.parentId,
								$or: [{ deletedAt: { $eq: null } }, { replyCount: { $gt: 0 } }],
							},
							pageSize: 1,
						}),
					]);
				} catch (error) {
					try {
						[comments, commentsToShow] = await Promise.all([
							api.getCommentsInsecure({
								where: { parentId: this.parentId },
								pageSize: 50 * this.currentPages,
							}),
							api.getCommentsInsecure({
								where: {
									parentId: this.parentId,
									$or: [{ deletedAt: { $eq: null } }, { replyCount: { $gt: 0 } }],
								},
								pageSize: 1,
							}),
						]);
					} catch (error) {
						console.error("Error loading comments insecure: ", error);
					}
				}
				this.currentCount = comments.rows.length;
				this.comments = comments.rows.filter((c) => c.deletedAt === null || c.replyCount > 0);
				this.commentCount = comments.count;
				this.showCount = commentsToShow.count;
				this.loadingComments = false;
			},
			async updateComment(comment) {
				const updatedCommentIndex = this.comments.findIndex((c) => c.id === comment.id);
				this.comments[updatedCommentIndex] = comment;
			},
			async editComment(comment, isEditing) {
				this.isEditing = isEditing;
			},
			toggleReplyBox(isOpen, commentId) {
				this.isReplyBoxOpen = isOpen;
				this.$emit("toggle-reply-box", isOpen);
			},
			async deleteComment(id) {
				await this.loadComments();
			},
			async addComment(comment) {
				const body = {
					parentId: this.parentId,
					parentType: this.parentType,
					content: comment,
				};
				if (this.conversationId) {
					body.conversationId = this.conversationId;
				}
				await api.createComment(body);
				await this.loadComments();
			},
		},
		watch: {
			parentId: async function (newVal) {
				await this.loadComments();
				this.$refs.addCommentRef?.clearComment();
				this.isReplyBoxOpen = false;
				this.isEditing = false;
			},
		},
		computed: {
			isMobile() {
				return this.$vuetify.breakpoint.smAndDown;
			},
			projectShareUsers() {
				return this.projectShares?.rows
					?.filter((share) => share.accessType !== AccessType.Viewer && share.User)
					.map((share) => share.User);
			},
			canAddComment() {
				return !this.isReplyBoxOpen && !this.isEditing && !this.isViewOnly;
			},
			allowMore() {
				return this.commentCount > this.currentCount;
			},
			getAddCommentClass() {
				return "";
			},
			getBoxClass() {
				return {
					"light-box": this.lightBoxView,
				};
			},
			getContainerClass() {
				return {
					"light-container": this.lightBoxView,
					"new-comments-container": this.lightBoxView,
					"height-full shadow-unset": this.isReplyBoxOpen && this.lightBoxView,
					"edit-container": this.isEditing,
					pulling: this.isPulling,
					sticky: this.isSticky,
				};
			},
			pullStyle() {
				return {
					transform: this.currentPull ? `translateY(${this.currentPull}px)` : "none",
					transition: this.isPulling ? "none" : "transform 0.2s",
				};
			},
			desktopStyle() {
				return {};
			},
			scrollable() {
				return !this.isReplyBoxOpen;
			},
		},
	};
</script>

<style scoped>
	.item.center {
		margin-left: auto;
	}
	.item.right {
		margin-left: auto;
	}
	.media-header {
		align-items: center;
		margin-bottom: 16px;
		color: #03011d;
	}

	.media-header .v-icon {
		width: 28px;
		height: 28px;
		color: #0070ff;
		/* margin-left: 12px; */
		margin-right: 8px;
		padding: 6px;
	}

	.media-header .heading {
		font-family: "Inter";
		font-size: 1rem;
		font-style: normal;
		font-weight: 600;
	}

	.loading-indicator {
		width: 32px;
		height: 32px;
	}

	.loading-indicator-container {
		position: absolute;
		top: calc(50% - 32px);
		left: calc(50% - 32px);
	}

	.h-100 {
		height: 100%;
	}
	.line {
		height: 2px;
		display: flex;
		align-items: center;
		align-self: center;
		margin-bottom: 8px;
		background-color: #9b9ea5;
		border-radius: 12px;
		opacity: 1;
		width: 24px;
	}
	.comments-container {
		transition: transform 0.2s ease-out;
	}

	.scrollable {
		overflow-y: scroll;
	}

	.sticky-add-comment {
		position: fixed;
		bottom: 0;
		left: 16.9vw;
		width: 55% !important;
		background-color: white;
		z-index: 10;
	}
	.sticky {
		padding-top: 20px;
	}
	.comment-box {
		display: flex;
		flex-direction: column;
		justify-content: start !important;
	}

	.light-box {
		justify-content: space-between;
		height: 100%;
		overflow: hidden;
	}

	.height-full {
		height: 100%;
		max-height: 100% !important;
		box-shadow: 0px 1px 2px 0px rgba(45, 45, 45, 0.1) !important;
	}

	.height-full .comment-list {
		height: 100%;
	}

	@media (min-width: 960px) {
		.font-size-16 {
			font-size: 1.25rem !important;
		}
		.new-comments-container {
			height: 100%;
			max-height: 0;
			scrollbar-width: thin;
		}
	}

	@media (max-width: 959px) {
		.comment-box {
			padding: 16px 8px !important;
		}
		@media (orientation: landscape) {
			.comments-container.new-comments-container {
				max-height: 45vh !important;
				min-height: 25vh !important;
			}
		}
		.new-comments-container {
			/* max-height: 58vh;
			min-height: 58vh !important; */
			box-shadow: 0px 1px 2px 0px rgba(45, 45, 45, 0.1);
		}
		.edit-container {
			max-height: 75vh;
		}
		.shadow-unset {
			box-shadow: unset !important;
		}
		.comments-container {
			flex: 1 1 auto;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			min-height: 0;
			overscroll-behavior-y: contain;
			touch-action: pan-y;
			margin-bottom: 5rem;
		}

		.light-container {
			margin-bottom: 0rem !important;
		}

		.light-box {
			margin-bottom: unset !important;
			max-height: unset !important;
			height: 100%;
		}
	}

	.v-card-hide {
		background-color: #f5f5f5 !important;
	}
</style>
