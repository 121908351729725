<template>
	<v-autocomplete
		:autocomplete="autocomplete"
		ref="customers"
		dense
		class="autocomplete"
		hide-details
		outlined
		label="Contact (Optional)"
		no-data-text="No contacts found"
		counter="60"
		v-model="selectedCustomer"
		clearable
		item-text="searchLabel"
		item-value="id"
		return-object
		cache-items
		@input="onCustomerSelected"
		@click:clear="onClearCustomer"
		:items="customerItems"
		:maxLength="60"
		:search-input.sync="searchTerm"
		:menu-props="{ maxWidth: customerMenuWidth }">
		<template v-slot:selection="{ item }">
			<span v-if="item.name">{{ item.name }}</span>
			<span v-else-if="item.phone">{{ item.phone }}</span>
			<span v-else-if="item.email">{{ item.email }}</span>
		</template>
		<template v-slot:item="{ item }">
			<v-list-item-content>
				<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
				<v-list-item-subtitle v-if="item.phone">
					<span>{{ item.phone }}</span>
				</v-list-item-subtitle>
				<v-list-item-subtitle v-if="item.email">
					<span>{{ item.email }}</span>
				</v-list-item-subtitle>
			</v-list-item-content>
		</template>
		<template v-slot:no-data>
			<div class="d-flex align-center justify-center no-data-slot" v-if="searchTerm">
				<v-btn
					@click="createNewContact"
					class="elevation-0 pa-2 text-none create-contact-btn"
					color="transparent">
					Create contact '<span class="text-truncate">{{ searchTerm }}</span
					>'
				</v-btn>
			</div>
		</template>
	</v-autocomplete>
</template>

<script>
	import Dialogs from "../helpers/dialogs";
	import api from "../api";
	import { EventBus } from "../helpers/eventBus";

	export default {
		name: "ContactAutocomplete",
		props: {
			autocomplete: {
				type: [String, Number],
				required: true,
			},
			customerId: {
				type: String,
				required: false,
			},
			customer: {
				type: Object,
				required: false,
			},
		},
		data() {
			return {
				searchTerm: null,
				selectedCustomer: {},
				customers: [],
				customerMenuWidth: 0,
			};
		},
		async mounted() {
			this.customers = await Dialogs.getCustomers();
			if (this.customerId) {
				const result = await api.getCustomers({
					where: {
						id: this.customerId,
					},
				});

				if (result.count > 0) {
					this.customers = result.rows;
					let customer = result.rows[0];
					const name = customer.firstname
						? `${customer.firstname} ${customer.lastname}`.trim()
						: customer.PhoneNumbers && customer.PhoneNumbers.length > 0
						? customer.PhoneNumbers[0].phoneNumber
						: "";
					customer = Object.assign({}, customer, { name });
					this.setCustomer(customer);
				}
			}
			this.calculateMenuWidth();
			window.addEventListener("resize", this.calculateMenuWidth);
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.calculateMenuWidth);
		},
		watch: {
			customer: {
				immediate: true,
				handler(newVal) {
					// Explicitly handle null/undefined cases
					if (newVal === null || newVal === undefined) {
						this.selectedCustomer = null;
						this.searchTerm = "";
					} else if (newVal) {
						this.selectedCustomer = { ...newVal };
					}
				},
			},
			searchTerm(search) {
				clearTimeout(this._searchTimerId);
				this._searchTimerId = setTimeout(async () => {
					const customers = await Dialogs.getCustomers(search);
					this.customers = customers;
				}, 500);
			},
		},
		methods: {
			calculateMenuWidth() {
				this.customerMenuWidth = this.$refs.customers?.$el?.getBoundingClientRect().width + "px" || "0px";
			},
			onCustomerSelected(customer) {
				this.setCustomer(customer);
			},
			onClearCustomer() {
				this.clearCustomer();
			},
			getCustomerDetails(customer) {
				if (!customer.EmailAddresses || customer.EmailAddresses.length == 0) {
					if (customer.PhoneNumbers && customer.PhoneNumbers.length > 0) {
						return {
							id: customer.id,
							name: customer.name,
							formattedName:
								customer.name.length > 0 ? customer.name : customer.PhoneNumbers[0]?.phoneNumber,
							phone: customer.PhoneNumbers[0]?.phoneNumber,
						};
					}
					return;
				}
				return {
					id: customer.id,
					name: customer.name,
					formattedName: customer.name.length > 0 ? customer.name : customer.EmailAddresses[0]?.emailAddress,
					email: customer.EmailAddresses[0]?.emailAddress,
					phone: customer.PhoneNumbers[0]?.phoneNumber,
				};
			},
			async createNewContact() {
				try {
					EventBus.$emit("DisableCloseOnClick");
					const record = await this.$root.$createCustomerDialog.open(this.searchTerm);
					if (record) {
						this.customers.push(record);
						this.setCustomer(record);
						this.searchCustomers = "";
					}
				} catch (err) {
					console.log(err);
				} finally {
					EventBus.$emit("EnableCloseOnClick");
				}
			},
			async clearCustomer() {
				this.setCustomer(null);
				this.phone = "";
				this.customers = await Dialogs.getCustomers();
			},
			setCustomer(customer) {
				this.selectedCustomer = customer;
				this.$emit("update-customer", customer);
			},
		},
		computed: {
			customerItems() {
				return this.customers
					.map((customer) => {
						let name = `${customer.firstname} ${customer.lastname}`.trim();
						let phone = null;
						let email = null;
						let searchLabel = name;

						if (name === "") {
							name = null;
						}

						if (customer.PhoneNumbers?.length > 0) {
							phone = customer.PhoneNumbers[0].phoneNumber;
							searchLabel = `${searchLabel} ${phone.replaceAll(" ", "")}`;
						}

						if (customer.EmailAddresses?.length > 0) {
							email = customer.EmailAddresses[0].emailAddress;
							searchLabel = `${searchLabel} ${email}`;
						}

						return Object.assign({}, customer, { name, phone, email, searchLabel });
					})
					.filter((x) => x != null && x.searchLabel.trim() !== "");
			},
		},
	};
</script>

<style scoped>
	.create-new-contact {
		color: #2d74ff;
	}
	.create-contact-btn:hover::before {
		opacity: 0 !important;
	}
	.text-truncate {
		max-width: 50% !important;
	}
	.create-contact-btn {
		width: 100%;
		white-space: normal;
		overflow-wrap: break-word;
	}
	.create-contact-btn,
	.create-contact-btn::v-deep .v-btn__content {
		width: 100% !important;
	}
</style>
