<template>
	<v-card :class="cardClass()">
		<v-card-text>
			<div class="header">
				<div>
					<h2><span class="action-header-photo">Take</span> Photos Now.</h2>
				</div>
			</div>
			<v-form v-model="form" :autocomplete="autocomplete">
				<v-text-field
					:autocomplete="autocomplete"
					v-model="title"
					ref="title"
					hide-details
					:placeholder="placeholderTitle"
					label="Session Name"
					outlined
					dense
					clearable
					:maxLength="56"
					@change="titleChanged"
					class="field-border-radius" />

				<FABCustomerAutoComplete
					:autocomplete="autocomplete"
					@update-customer="(customer) => setCustomer(customer)"
					:customerId="customerId" />

				<!-- <v-text-field
					:autocomplete="autocomplete"
					v-model="contactName"
					ref="name"
					hide-details
					label="Contact"
					outlined
					dense
					clearable
					:maxLength="56"
					class="field-border-radius"
					:class="allowReset ? 'reset' : ''" /> -->

				<ProjectAutoComplete :selected-project="newSelectedProject" @change="updateProject" />
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-spacer />

			<!--Close-->
			<v-btn text elevation="0" @click="close()"> Back </v-btn>

			<!--Start-->
			<v-btn color="primary" elevation="0" :disabled="startButtonDisabled()" :loading="starting" @click="start()">
				{{ dynamicActioningText }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import api from "../api";
	import "../helpers/emoji";
	import constants from "../api/constants";
	import Dialogs from "../helpers/dialogs";
	import ProjectAutoComplete from "./ProjectAutoComplete.vue";
	import timezones from "@/helpers/timezones";
	import DeviceUtility from "@/helpers/device";
	import EventBus from "@/helpers/eventBus";
	import { PhotoPreScreenBody, PhotoPreScreenHead } from "../helpers/string";
	import { closeActionDialog } from "@/helpers/closeActionDialogs";
	import CreateCustomerDialog from "./CreateCustomerDialog.vue";
	import FABCustomerAutoComplete from "./FABCustomerAutoComplete.vue";

	export default {
		components: {
			ProjectAutoComplete,
			CreateCustomerDialog,
			FABCustomerAutoComplete,
		},
		props: {
			customerId: String,
			actionText: String,
			actioningText: String,
			buttonIcon: String,
			showHeader: Boolean,
			projectId: String,
			closeMenu: Function,
			existingConversationId: String,
			cameraUrl: String,
		},
		mixins: [closeActionDialog],
		data() {
			return {
				showPhoneFieldErrorState: false,
				autocomplete: Math.random(),
				form: false,
				currentTab: null,
				vueTelProps: window.VueTelProps,
				starting: false,
				validPhone: false,
				autoSetName: false,
				autoSetNameValue: "",
				emailDisabled: true,
				email: "",
				phone: "",
				phoneSearch: "",
				name: "",
				contactName: "",
				includeEmailInvitation: false,
				meetingUrl: "",
				actioningTextOverride: "",
				organization: {},
				autoStartRecordings: true,
				notificationTemplate: "",
				defaultNotificationTemplate: "",
				shareUser: null,
				me: null,
				selectedCustomer: {},
				validCustomer: true,
				searchCustomers: null,
				phoneSearchResultsOpen: false,
				customerJustSelected: false,
				newSelectedProject: null,
				projectSelectItems: [],
				isCameraOpen: false,
				capturedImage: null,
				cameraWindow: null,
				stream: null,
				conversationId: null,
				creatingConversation: false,
				title: "",
				cachedUser: {},
				placeholderTitle: "",
			};
		},
		computed: {
			dynamicActioningText() {
				if (this.actioningTextOverride) {
					return this.actioningTextOverride;
				}
				return "Take Photos";
			},
			computedTitle() {
				if (this.me?.user) {
					return `Photos from ${this.getTeamMemberName()} on ${timezones.formatDate(
						new Date().toISOString(),
						"M/D/YYYY"
					)}`;
				} else {
					return `Photos taken on ${timezones.formatDate(new Date().toISOString(), "M/D/YYYY")}`;
				}
			},
		},
		async mounted() {
			this.me = await api.getMe();
			this.title = this.computedTitle;
			this.placeholderTitle = this.title;
			if (this.projectId) {
				const result = await api.getProject(this.projectId);
				this.newSelectedProject = result;
			}
			this.reset();
			this.organization = this.me.organization;
			if (this.existingConversationId) {
				this.conversationId = this.existingConversationId;
				await this.start();
			}
		},
		methods: {
			// TODO: Consolidate this into a helper or single Action dialog component
			getTeamMemberName() {
				let name = null;

				if (this.me?.user.firstname) {
					name = this.me?.user.firstname;
				}

				if (this.me?.user.lastname) {
					name = `${name} ${this.me?.user.lastname}`;
				}

				if (!name) {
					name = this.me?.user.email;
				}

				return name ?? "-";
			},
			async updateProject(project) {
				this.newSelectedProject = project;
			},
			titleChanged() {
				if (!this.title || this.title == "") {
					this.title = this.computedTitle;
				}
			},
			cardClass() {
				return `conversation-card ${DeviceUtility.isMobile ? "conversation-bottom" : ""}`;
			},
			startButtonDisabled() {
				return (
					!this.form ||
					this.starting ||
					!!this.actioningTextOverride ||
					!this.title ||
					this.title.trim() === ""
				);
			},
			reset() {
				this.phone = "";
				this.email = "";
				this.includeEmailInvitation = false;
				this.name = "";
				this.autoSetName = "";
				this.autoSetNameValue = "";
				this.shareUser = null;
			},
			customerSelected(customer) {
				if (customer) {
					this.customerJustSelected = true;
				}

				this.setCustomer(customer);
			},
			async start() {
				let win = null;
				let popupOpened = false;
				const androidPwa = DeviceUtility.isAndroid && DeviceUtility.isPwaInstalled;
				this.starting = true;
				if (!androidPwa) {
					win = window.open("", "_blank");
				}
				if (win && win.document && win.document.body) {
					win.document.head.innerHTML = PhotoPreScreenHead;
					win.document.body.innerHTML = PhotoPreScreenBody(window.location.origin);
					popupOpened = true;
				}
				// open camera
				try {
					let cameraUrl = this.cameraUrl;
					if (!this.conversationId) {
						this.creatingConversation = true;
						const me = await api.getMe();
						if (this.newSelectedProject && this.selectedCustomer?.id) {
							const selectedContact = this.selectedCustomer;
							let contactExistance = false;
							if (!this.newSelectedProject.Customers) {
								this.newSelectedProject.Customers = [];
							}
							for (const contact of this.newSelectedProject.Customers) {
								if (contact.id === selectedContact.id) {
									contactExistance = true;
									break;
								}
							}
							if (!contactExistance) {
								await api.updateProject(this.newSelectedProject.id, {
									Customers: [...this.newSelectedProject.Customers, selectedContact],
								});
							}
						}
						const request = {
							forcedUserId: me.user.id,
							name: this.title ?? "Photos Session",
							type: constants.CONVERSATION_TYPE.PHOTOS,
							initiationType: constants.INITIATION_TYPE.ADMIN,
							projectId: this.newSelectedProject?.id,
						};

						if (this.selectedCustomer.id) {
							request.visitors = [
								{
									customerId: this.selectedCustomer.id,
									phone: this.phone.trim(),
									email: this.email.trim(),
									firstname: this.selectedCustomer.firstname,
									lastname: this.selectedCustomer.lastname,
									notificationSMS: false,
									notificationEmail: false,
								},
							];
						}

						const resp = await api.createConversation(request);
						const conversation = resp.conversation;
						this.conversationId = conversation.id;
						this.creatingConversation = false;
						cameraUrl = resp.url;
					}

					if (popupOpened) {
						win.location = cameraUrl;
						window.addEventListener("message", this.handleCameraMessage);

						const currentUrl = window.location.href;
						this.$emit("close-menu");
						if (!currentUrl.includes("/projects")) {
							this.$router.push({
								name: "conversation",
								params: {
									id: this.conversationId,
								},
							});
						} else {
							this.$router.push({
								name: "conversation",
								params: {
									id: this.conversationId,
								},
								query: {
									from: "homepage",
								},
							});
						}
					} else {
						window.location = cameraUrl;
					}
					this.starting = false;
				} catch (error) {
					console.error("Error accessing the camera:", error);
				}
			},
			setCustomer(customer) {
				this.selectedCustomer = customer;
				if (customer) {					
					if (customer.EmailAddresses?.length > 0) {
						this.email = customer.EmailAddresses[0].emailAddress;
						this.emailDisabled = true;
					} else {
						this.emailDisabled = false;
					}

					if (!this.phone && customer.PhoneNumbers?.length > 0) {
						this.phone = customer.PhoneNumbers[0].phoneNumber;
					}
				} else {
					this.emailDisabled = false;
					this.name = "";
					this.email = "";
				}
			},
			handleCameraMessage(event) {
				if (event.data.type === "IMAGE_CAPTURED") {
					console.debug("Image captured");
					EventBus.$emit("ReloadPhotos", this.conversationId);
					EventBus.$emit("ReloadPhotos", this.newSelectedProject?.id);
				}
			},
		},
		beforeUnmount() {},
	};
</script>

<style lang="scss" scoped>
	.start-conversation-button {
		font-family: "Inter Tight", sans-serif;
		font-size: 20px;
		color: white;
		margin-left: 10px;
	}
	.reset-span {
		position: relative;
		left: calc(100% - 50px);
		display: inline-block;
		top: -30px;
		height: 0;
	}

	.conversation-card {
		border-radius: 12px;
		min-height: 400px;
		width: 100vw;
		display: flex;
		flex-direction: column;
		justify-content: start;
	}

	.conversation-card .header {
		display: flex;
		margin-bottom: 28px;
		margin-top: 12px;
	}

	.conversation-card textarea {
		color: #212121;
		font-size: 16px;
	}

	.conversation-card h2,
	.conversation-card i {
		font-size: 24px;
		margin-right: 5px;
	}

	.conversation-card .v-card__actions {
		padding: 16px;
		display: flex;
		justify-content: space-evenly;
	}

	.conversation-card .v-card__actions > button {
		width: 50%;
		height: 50px;
		border-radius: 25px;
		line-height: 20px;
		font-size: 1em;
		font-weight: 600;
	}

	.conversation-card .header {
		color: #000;
	}

	.conversation-card .auto-message {
		color: #b3b3b3;
		font-size: 16px;
	}

	.conversation-card .record-switch {
		margin-left: 5px;
		margin-top: 0px;
	}

	.conversation-card .v-card__actions > button:nth-child(2) {
		display: block;
		border: 1px solid #e0e0e0 !important;
	}

	.conversation-card .contact-name.reset {
		padding-top: 0px;
	}

	.field-border-radius {
		border-radius: 6px;
	}

	.v-slide-group__next,
	.v-slide-group__prev {
		display: none;
	}

	/* Email Invite */

	.team-member-share {
		position: absolute;
		padding-top: 8px;
		margin-left: 2px;
		color: #0070ff;
	}

	.customer-phone-search {
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
	}
</style>
