export function getSearchLabelForCustomers(customers: any[]) {
	const customerSelectItemsUnique = [...new Set(customers)];
	return customerSelectItemsUnique
		.map((customer) => getCustomerSearchLabel(customer))
		.filter((label) => !!label);
}

export function getCustomerSearchLabel(customer: any) {
	if (!customer) {
		return {};
	}

	// Extract customer details
	const name = getCustomerName(customer);
	const phone = getCustomerPhone(customer);
	const email = getCustomerEmail(customer);

	// Build search label from available details
	const searchLabel = [name, phone?.replaceAll(" ", ""), email].filter(Boolean).join(" ").trim();

	return searchLabel ? { ...customer, name, phone, email, searchLabel } : undefined;
}

function getCustomerName(customer: any): string | null {
	const fullName = customer.firstname ? `${customer.firstname} ${customer.lastname}`.trim() : "";

	return fullName || null;
}

function getCustomerPhone(customer: any): string | null {
	return customer.PhoneNumbers?.[0]?.phoneNumber || null;
}

function getCustomerEmail(customer: any): string | null {
	return customer.EmailAddresses?.[0]?.emailAddress || null;
}
