<template>
	<div flat class="page thumbnail-tray" :class="{ closed: !showThumbs }">
		<v-btn
			icon
			v-show="!showThumbs"
			class="close-gallery ma-4 d-none"
			style="position: fixed; left: 0; background-color: #000"
			color="#fff"
			@click="toggleGallery">
			<v-icon>icon-grid-3x3</v-icon>
		</v-btn>
		<div
			:class="{ closed: !showThumbs }"
			class="snapshots fixed"
			@touchmove="
				(e) => {
					e.stopPropagation();
				}
			">
			<div class="close-gallery ma-4" v-show="this.$vuetify.breakpoint.smAndDown">
				<v-btn fab small elevation="0" @click="toggleGallery">
					<v-icon>icon-close-small</v-icon>
				</v-btn>
			</div>
			<!--Snapshot controls-->
			<v-card-text class="snapshot-controls snapshot-actions">
				<!--Primary controls-->
				<div class="d-flex selection justify-space-between">
					<!--Cancel-->
					<v-btn v-if="bulkSelect" elevation="0" @click="() => (selectedArtifactIds = [])"> Back </v-btn>

					<!--Select all-->
					<v-btn v-if="bulkSelect && artifacts.length > 1" elevation="0" @click="selectAll">
						{{ selectedArtifactIds.length == artifacts.length ? "Deselect All" : "Select All" }}
					</v-btn>

					<!--Download-->
					<v-btn
						class="bulk-action"
						v-if="bulkSelect && selectedArtifactIds.length > 0"
						fab
						small
						elevation="0"
						@click="downloadArtifacts">
						<v-icon class="mr-0">icon-download</v-icon>
					</v-btn>

					<!--Delete-->
					<v-btn
						class="bulk-action"
						v-if="
							!(isViewOnly && !hasFreeTrialEnded) &&
							bulkSelect &&
							selectedArtifactIds.length > 0
						"
						fab
						small
						elevation="0"
						@click="deleteArtifacts">
						<v-icon class="mr-0" color="red">icon-trash-full-stroke</v-icon>
					</v-btn>
				</div>
				<!--Secondary controls-->
				<div class="actions" v-show="false">
					<!--Download-->
					<v-btn
						v-if="bulkSelect && selectedArtifactIds.length > 0"
						fab
						small
						elevation="0"
						@click="downloadArtifacts">
						<v-icon class="mr-0">icon-download</v-icon>
					</v-btn>

					<!--Delete-->
					<v-btn
						v-if="
							!(isViewOnly && !hasFreeTrialEnded) &&
							bulkSelect &&
							selectedArtifactIds.length > 0
						"
						fab
						small
						elevation="0"
						@click="deleteArtifacts">
						<v-icon class="mr-0" color="red">icon-trash-full-stroke</v-icon>
					</v-btn>
				</div>
			</v-card-text>

			<!--Snapshots-->
			<v-card-text class="pt-0">
				<v-row style="margin: 0">
					<v-col
						v-for="artifact in artifacts"
						:key="artifact.id"
						class="d-flex child-flex"
						:cols="isLandscape && isMobile ? 3 : 6">
						<v-img
							v-if="artifactType == 'snapshots'"
							:src="artifact.src"
							:lazy-src="artifact.src"
							aspect-ratio="1"
							class="grey lighten-2"
							@click="selectItem(artifact.id)">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
								</v-row>
							</template>
							<div class="selection-icon" @click.stop="toggleSelection(artifact.id)">
								<div class="circle" :class="{ selected: isArtifactSelected(artifact.id) }">
									<span
										class="text-h5 white--text centre-number"
										color="green"
										v-if="isArtifactSelected(artifact.id)">
										{{ getArtifactSelectionIndex(artifact.id) }}
									</span>
								</div>
							</div>
							<v-overlay
								absolute
								:opacity="0.72"
								@click.stop="
									isArtifactSelected(artifact.id)
										? toggleSelection(artifact.id)
										: selectItem(artifact.id)
								"
								:value="isArtifactSelected(artifact.id)">
							</v-overlay>
						</v-img>

						<div
							v-else-if="artifactType == 'videos'"
							class="video-container"
							@click="selectItem(artifact.id)">
							<div class="video-wrapper">
								<v-img :src="require('@/assets/lsc-poster.svg')" height="100%" aspect-ratio="1" />
								<div class="video-selection-icon" @click.stop="toggleSelection(artifact.id)">
									<div class="circle-video" :class="{ selected: isArtifactSelected(artifact.id) }">
										<span
											class="text-h5 white--text centre-number"
											color="green"
											v-if="isArtifactSelected(artifact.id)">
											{{ getArtifactSelectionIndex(artifact.id) }}
										</span>
									</div>
								</div>
							</div>
							<v-overlay
								absolute
								:opacity="0.72"
								@click.stop="
									isArtifactSelected(artifact.id)
										? toggleSelection(artifact.id)
										: selectItem(artifact.id)
								"
								:value="isArtifactSelected(artifact.id)">
							</v-overlay>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</div>
	</div>
</template>
<script>
	import { blurActiveElement } from "@/helpers/utilities";

	export default {
		props: {
			/**
			 * Array of media artifacts to display
			 * @type {Array<{id: string, src?: string, recordingUrl?: string}>}
			 * @required
			 */
			artifacts: {
				type: Array,
				required: true,
			},

			/**
			 * Type of artifacts being displayed ('snapshots' or 'videos')
			 * @type {string}
			 * @required
			 */
			artifactType: {
				type: String,
				required: true,
			},

			/**
			 * Whether the gallery is in view-only mode
			 * @type {boolean}
			 * @default false
			 */
			isViewOnly: {
				type: Boolean,
				default: false,
			},
			/**
			 * Parent ID for video file naming
			 * @type {string}
			 * @required
			 */
			parentId: {
				type: String,
				required: true,
			},
			hasFreeTrialEnded: {
				type: Boolean,
				required: true,
			},
		},

		emits: {
			/**
			 * Emitted when gallery visibility is toggled
			 * @param {boolean} isVisible - Whether gallery is now visible
			 */
			"toggle-gallery": (isVisible) => typeof isVisible === "boolean",

			/**
			 * Emitted when artifacts are selected for download
			 * @param {string[]} artifactIds - IDs of artifacts to download
			 */
			"download-artifacts": (artifactIds) => Array.isArray(artifactIds),

			/**
			 * Emitted when artifacts are selected for deletion
			 * @param {string[]} artifactIds - IDs of artifacts to delete
			 */
			"delete-artifacts": (artifactIds) => Array.isArray(artifactIds),

			/**
			 * Emitted when lightbox index changes
			 * @param {string} artifactId - ID of artifact to show in lightbox
			 */
			"update-lightbox-index": (artifactId) => typeof artifactId === "string",
		},

		data() {
			return {
				selectedArtifactIds: [],
				bulkSelect: false,
				showGalleryCloseButton: false,
				lightBoxOpen: false,
				lightBoxClose: false,
				showThumbs: false,
				isLandscape: false,
				isMobile: false,
			};
		},
		mounted() {
			this.isLandscape = window.innerHeight < window.innerWidth;
			this.isMobile = this.$vuetify.breakpoint.smAndDown;
			window.addEventListener("resize", () => {
				this.isLandscape = window.innerHeight < window.innerWidth;
				this.isMobile = this.$vuetify.breakpoint.smAndDown;
			});
		},
		methods: {
			videoFileName(recording) {
				return `file.${this.parentId}.${recording.id}.mp4`;
			},
			videoRecordingId(recordingId) {
				return `video-${recordingId}`;
			},
			toggleGallery() {
				this.showThumbs = !this.showThumbs;
				if (!this.showThumbs) {
					this.selectedArtifactIds = [];
				}
				this.$emit("toggle-gallery", this.showThumbs);
				blurActiveElement();
			},
			isOpen() {
				return this.showThumbs;
			},
			downloadArtifacts() {
				this.$emit("download-artifacts", this.selectedArtifactIds);
			},
			deleteArtifacts() {
				this.$emit("delete-artifacts", this.selectedArtifactIds, () => {
					this.selectedArtifactIds = [];
				});
			},
			selectAll() {
				if (this.selectedArtifactIds.length == this.artifacts.length) {
					this.selectedArtifactIds = [];
				} else {
					this.selectedArtifactIds = [];
					this.selectedArtifactIds.push(...this.artifacts.map((s) => s.id));
				}
			},
			toggleSelection(artifactId) {
				this.selectedArtifactIds.includes(artifactId)
					? this.selectedArtifactIds.splice(this.selectedArtifactIds.indexOf(artifactId), 1)
					: this.selectedArtifactIds.push(artifactId);
			},
			isArtifactSelected(artifactId) {
				return this.selectedArtifactIds.includes(artifactId);
			},
			getArtifactSelectionIndex(artifactId) {
				return this.selectedArtifactIds.indexOf(artifactId) + 1;
			},
			selectItem(artifactId) {
				if (this.$vuetify.breakpoint.smAndDown && this.showThumbs) {
					this.toggleGallery();
				}
				this.$emit("select-item", artifactId);
			},
		},
		watch: {
			selectedArtifactIds: {
				handler() {
					if (this.selectedArtifactIds.length > 0) {
						this.bulkSelect = true;
					} else {
						this.bulkSelect = false;
					}
				},
			},
			artifacts: {
				handler() {
					for (let i = 0; i < this.selectedArtifactIds.length; i++) {
						const selectedId = this.selectedArtifactIds[i];
						const artifact = this.artifacts.find((x) => x.id == selectedId);

						if (!artifact) {
							this.selectedArtifactIds.splice(i, 1);
							i--;
						}
					}
				},
			},
		},
	};
</script>
<style scoped>
	.video-container {
		width: 100%;
		position: relative;
	}

	.video-wrapper {
		position: relative;
		width: 100%;
	}

	.video-selection-icon {
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: 3;
		cursor: pointer;
	}

	.circle-video {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 2px solid white;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.circle-video.selected {
		background-color: #4caf50;
		border-color: #4caf50;
	}

	.centre-number {
		line-height: 1;
	}

	.selection-icon {
		cursor: pointer;
		position: absolute;
		top: 8px;
		right: 8px;
		z-index: 3;
	}
	.selection-icon-video {
		cursor: pointer;
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: 3;
	}
	@media (min-width: 960px) {
		.image-artifact {
			height: 100px !important;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
		}
		.date {
			padding-top: 16px;
			padding-bottom: 16px;
		}
		.artifact-page {
			padding-left: 8px;
		}
		.column {
			max-width: 13.33%;
		}
	}
	.container {
		padding: 0px !important;
		height: 100%;
	}
	.heading {
		font-size: 24px;
		font-weight: 600;
		margin: 4px;
	}
	.date {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 13px;
		margin-top: 13px;
	}
	.video-player {
		margin-bottom: 10px;
		height: 100%;
		width: 100%;
	}

	.column {
		padding-left: 0.5rem;
		padding-bottom: 0rem;
		padding-top: 0rem;
	}

	.close-button {
		padding-left: 0px !important;
		padding-right: 0px !important;
		max-width: 24px !important;
		min-width: 24px !important;
	}

	.view-button {
		height: 22px !important;
		width: 75px !important;
		font-size: 12px !important;
		border-radius: 8px;
		margin-right: 4px;
		margin-top: 4px;
	}

	.action-buttons {
		margin-bottom: 7px;
		margin-top: 28px;
	}

	.overlay {
		position: relative;
		background-color: transparent;
		border-radius: 20px;
		z-index: 1;
	}

	.artifact-item {
		padding: 0;
		border: 2px solid blue;
		margin-bottom: 8px;
	}

	.v-list-item {
		padding: 0;
	}
	.row {
		padding-right: 10px;
	}

	.document {
		width: 90px;
		height: 60px;
		background-color: #f0f0f0;
		border: 2px solid #ccc;
		border-radius: 3px;
		margin-right: 2px;
	}

	.project-readonly {
		padding: 16px !important;
	}

	.document-edge {
		width: 0px;
		height: 0px;
		border-top: 20px solid #ccc;
		border-right: 20px solid transparent;
		top: -2px;
		right: -2px;
	}

	.document-content {
		width: 40px !important;
		text-align: center;
	}

	.file-type {
		font-size: 10px;
		margin-bottom: 10px;
		max-width: 120px;
		font-weight: bold;
		color: #666;
		white-space: wrap;
		line-height: 1.375;
		word-break: break-word;
	}

	.file-icon {
		margin-bottom: 200px;
	}

	.file-name {
		font-size: 12px;
	}
	.datemapping {
		height: inherit;
	}
	.row {
		margin-left: 0px;
		margin-right: 0px;
	}

	.v-card {
		padding: 10px;
		height: 100%;
		border-radius: 16px;
		width: 100%;
	}

	.artifact-page {
		width: 100%;
	}
	.v-list-item--active:hover::before,
	.v-list-item--active::before,
	.v-list-item:hover::before {
		opacity: 0 !important;
	}
</style>

<style scoped>
	.bulk-action .v-icon {
		font-size: 1.25rem;
	}

	.snapshot-controls {
		display: flex;
		justify-content: space-between;
		min-height: 68px;
	}

	.snapshot-controls i,
	.close-gallery i {
		/* font-size: 18px; */
	}

	.close-gallery {
		z-index: 20000;
	}

	.close-gallery > button {
		color: #fff !important;
		background-color: #000 !important;
	}

	.selected {
		background-color: green !important;
		opacity: 1 !important;
		border-color: green !important;
	}

	.select-overlay::v-deep {
		transition: none;
	}

	.select-overlay::v-deep > .v-overlay__content {
		position: absolute;
		bottom: 9px;
		right: 9px;
	}

	.select-overlay::v-deep i {
		font-size: 18px;
	}

	.select-overlay:hover::v-deep {
		border: #90baff solid 4px;
	}

	.select-overlay.selected::v-deep {
		color: #0070ff;
		border: #0070ff solid 4px;
	}

	.select-overlay:hover::v-deep > .v-overlay__content {
		bottom: 5px;
		right: 5px;
	}

	.select-overlay.selected::v-deep > .v-overlay__content {
		bottom: 5px;
		right: 5px;
	}

	.thumbnail-tray {
		z-index: 10000;
		display: flex;
		flex-direction: column;
		gap: 10px;
		transition: all 0.3s ease;
	}

	.thumbnail-tray.closed {
		width: 0;
	}

	.thumbnail-tray-item {
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
	.project-card {
		margin-bottom: 8px;
	}
	.media-card {
		margin-bottom: 1rem;
		min-height: unset !important;
		border-radius: 15px !important;
	}
	.media-header {
		align-items: center;
		margin-bottom: 16px;
		color: #03011d;
	}

	.media-header .v-icon {
		width: 28px;
		height: 28px;
		color: #0070ff;
		margin-right: 8px;
		padding: 6px;
	}

	.media-header .heading {
		font-family: "Inter Tight";
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.2;
	}

	.snapshots {
		z-index: 10010;
		width: 320px;
		height: 100vh;
		overflow-y: scroll;
	}

	/* .snapshots.fixed {
		position: absolute;
		top: 0;
		left: 0;
	} */

	.snapshots.fixed button {
		background-color: #1e2128 !important;
		color: #fff;
		font-size: 12px;
	}

	.snapshots.fixed > .snapshot-controls {
		flex-direction: column;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button {
		background-color: #000 !important;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button:first-child {
		color: #fff !important;
	}

	.snapshots.closed {
		width: 0 !important;
	}
	.snapshots.fixed {
		transition: all 0.3s ease;
		background-color: #000;
	}
	.snapshots.fixed > .snapshot-controls {
		flex-direction: column;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button {
		background-color: #000 !important;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button:first-child {
		color: #fff !important;
	}

	.snapshot-actions {
		justify-content: space-between;
	}

	.snapshot-actions .selection {
		/* margin-left: 12px; */
	}

	.snapshot-actions .selection .v-btn:first-child {
		margin-right: 12px;
	}

	.snapshot-actions .actions {
		margin-right: 12px;
	}

	.snapshot-actions .actions .v-btn {
		background: none;
	}

	.snapshot-actions .actions .v-btn:first-child {
		margin-right: 1rem;
	}

	.snapshots .col-6:nth-child(odd) {
		padding: 0 4px 4px 0;
	}

	.snapshots .col-6:nth-child(even) {
		padding: 0 0 4px 4px;
	}

	.snapshots .snapshot-thumbnail {
		cursor: pointer;
	}

	@media (max-width: 320px) {
		.recording-buttons > button:first-child {
			font-size: 12px;
		}

		.snapshots.fixed > .snapshot-controls {
			width: 85%;
		}

		.snapshots.fixed > .snapshot-controls > div:first-child > button {
			width: 100%;
		}

		.snapshots.fixed > .snapshot-controls > div:first-child > button:not(:first-child) {
			margin-left: 0;
			margin-top: 15px;
		}

		.snapshots.fixed > .snapshot-controls > div:first-child {
			margin-bottom: 8px;
		}
	}
	@media (max-width: 959px) {
		.thumbnail-tray {
			width: 100%;
		}
		.snapshots.fixed {
			width: 100%;
			z-index: 10009;
		}

		.v-card.page.media-card {
			padding: 0;
		}

		.v-card.page.media-card .v-card__text {
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.project-card.v-sheet.v-card:not(.v-sheet--outlined) {
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.28);
			border-radius: 12px;
		}
		.v-card.project-card.media-card {
			padding: 16px 8px;
		}

		.v-card.project-card.media-card .v-card__text.no-snapshots {
			padding: 20px;
		}
	}
</style>
