<template>
	<v-card :class="cardClass()">
		<v-card-text>
			<div v-if="showHeader" class="header">
				<h2 v-if="conversationType == 'async'">
					<span class="action-header-async-call">Request</span> a Recording.
				</h2>
				<div v-else>
					<h2>Send a <span class="action-header-sync-call">Live Video Call</span> Invitation.</h2>
				</div>
			</div>
			<v-form v-model="form" :autocomplete="autocomplete">
				<!--Contact name -->
				<FABCustomerAutoComplete
					v-if="!phone || selectedCustomer"
					:customer="selectedCustomer"
					:autocomplete="autocomplete"
					@update-customer="(customer) => customerSelected(customer)"
					:customerId="customerId" />

				<v-text-field
					v-else
					:autocomplete="autocomplete"
					v-model="name"
					ref="name"
					:disabled="disablePhoneNumberEdit"
					hide-details
					label="Contact (Optional)"
					outlined
					dense
					:maxLength="56"
					class="field-border-radius"
					:class="allowReset ? 'reset' : ''" />
				<!--Enter a phone number-->
				<div @paste.prevent="pasteEvent" class="mb-3" style="position: relative">
					<vue-tel-input
						:autocomplete="autocomplete"
						tabindex="0"
						autofocus
						ref="phoneInput"
						:border-radius="0"
						:validCharactersOnly="true"
						valid-color="#000000"
						v-bind="vueTelProps"
						:disabled="disablePhoneNumberEdit"
						@validate="validate"
						@input="matchPhone"
						@country-changed="matchPhone"
						@blur="phoneBlur"
						@focus="phoneFocus"
						class="field-border-radius"
						:class="{
							'error--text': showPhoneFieldErrorState,
						}"
						v-model="phone" />

					<!--Reset-->
					<span v-if="allowReset" class="reset-span">
						<a href="#" @click="reset()">Reset</a>
					</span>

					<v-autocomplete
						:autocomplete="autocomplete"
						class="customer-phone-search"
						ref="customerPhoneSearch"
						:menu-props="{ value: phoneSearchResultsOpen }"
						outlined
						dense
						hide-details
						no-data-text="No contacts found"
						counter="60"
						v-model="phoneSearch"
						item-text="searchLabel"
						item-value="phone"
						return-object
						@input="customerPhoneSelected"
						:items="customerItems"
						:maxLength="60">
						<template v-slot:item="{ item }">
							<v-list-item-content>
								<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
								<v-list-item-subtitle v-if="item.phone"
									><span>{{ item.phone }}</span></v-list-item-subtitle
								>
								<v-list-item-subtitle v-if="item.email"
									><span>{{ item.email }}</span></v-list-item-subtitle
								>
							</v-list-item-content>
						</template>
					</v-autocomplete>
				</div>

				<ProjectAutoComplete :selected-project="newSelectedProject" @change="updateProject" />

				<!--Add a message-->
				<v-textarea
					ref="message"
					rows="3"
					v-model="notificationTemplate"
					:disabled="disablePhoneNumberEdit"
					hide-details
					label="Message"
					outlined
					counter="500"
					:maxLength="500"
					:counter-value="countCharacters"
					:placeholder="defaultNotificationTemplate"
					class="field-border-radius"
					@change="notesChanged" />

				<div>
					<v-switch
						inset
						v-model="includeEmailInvitation"
						ref="includeEmailInvitation"
						hide-details
						label="Include Email Invitation"
						class="mt-0 mb-4">
					</v-switch>
					<v-text-field
						v-show="includeEmailInvitation"
						v-if="includeEmailInvitation || email"
						dense
						:disabled="emailEditDisabled"
						class="field-border-radius email-invite-input"
						outlined
						label="Email (Required)"
						v-model.trim="email"
						ref="email"
						:required="includeEmailInvitation"
						:rules="emailRules"
						validate-on-blur
						clearable
						hint="Link to join the meeting will be sent to this email."
						persistent-hint>
					</v-text-field>
				</div>

				<div>
					<v-autocomplete
						dense
						hide-details
						outlined
						label="Share Recording with a User."
						no-data-text="Start typing to find a user"
						class="autocomplete"
						counter="60"
						v-model="shareUser"
						ref="shareUser"
						item-text="name"
						item-value="id"
						return-object
						prepend-inner-icon="icon-user-single"
						:items="teamMemberItems"
						:maxLength="60">
						<template v-slot:selection="{ attr, on, item, selected }">
							<span v-if="item.name">{{ item.name }}</span>
							<span v-else-if="item.phone">{{ item.phone }}</span>
							<span v-else-if="item.email">{{ item.email }}</span>
						</template>
						<template v-slot:item="{ item }">
							<v-list-item-content>
								<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
								<v-list-item-subtitle v-if="item.phone"
									><span>{{ item.phone }}</span></v-list-item-subtitle
								>
								<v-list-item-subtitle v-if="item.email"
									><span>{{ item.email }}</span></v-list-item-subtitle
								>
							</v-list-item-content>
						</template>
					</v-autocomplete>
				</div>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-spacer />

			<!--Close-->
			<v-btn text elevation="0" @click="close()">
				{{ this.meetingUrl ? "Close" : "Back" }}
			</v-btn>

			<!--Start-->
			<v-btn
				color="primary"
				elevation="0"
				:disabled="startButtonDisabled()"
				:loading="starting"
				v-if="!meetingUrl || actioningTextOverride"
				@click="start()">
				{{ dynamicActioningText }}
			</v-btn>

			<!--Join-->
			<v-btn color="primary" v-if="meetingUrl && allowJoin" @click="join()">
				<v-icon left> icon-video </v-icon> Join
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import api from "../api";
	import "../helpers/emoji";
	import Projects from "../helpers/projects";
	import tokenHandler from "../helpers/tokenHandler";
	import moment from "moment-timezone";
	import constants from "../api/constants";
	import Dialogs from "../helpers/dialogs";
	import { EventBus } from "../helpers/eventBus";
	import ProjectAutoComplete from "./ProjectAutoComplete.vue";
	import DeviceUtility from "@/helpers/device";
	import timezones from "@/helpers/timezones";
	import { closeActionDialog } from "@/helpers/closeActionDialogs";
	import FABCustomerAutoComplete from "./FABCustomerAutoComplete.vue";
	import { VuetifyValidation } from "../helpers/Validation";

	export default {
		components: {
			ProjectAutoComplete,
			FABCustomerAutoComplete,
		},
		props: {
			projectId: String,
			conversationType: String, //sync or async
			customerId: String,
			actionText: String,
			actioningText: String,
			allowJoin: Boolean,
			buttonIcon: String,
			showHeader: Boolean,
		},
		mixins: [closeActionDialog],
		data() {
			return {
				showPhoneFieldErrorState: false,
				autocomplete: Math.random(),
				form: false,
				currentTab: null,
				vueTelProps: window.VueTelProps,
				starting: false,
				validPhone: false,
				autoSetName: false,
				autoSetNameValue: "",
				emailDisabled: false,
				email: "",
				phone: "",
				phoneSearch: "",
				name: "",
				includeEmailInvitation: false,
				meetingUrl: "",
				actioningTextOverride: "",
				organization: {},
				autoStartRecordings: true,
				notificationTemplate: "",
				defaultNotificationTemplate: "",
				shareUser: null,
				teamMembers: [],
				me: null,
				customers: [],
				selectedCustomer: {},
				validCustomer: true,
				searchCustomers: null,
				phoneSearchResultsOpen: false,
				customerJustSelected: false,
				newSelectedProject: null,
				projectSelectItems: [],
				searchTerm: "",
				phoneNumberFieldInFocus: false,
			};
		},
		computed: {
			dynamicActioningText() {
				if (this.actioningTextOverride) {
					return this.actioningTextOverride;
				}
				return "Send";
			},
			disablePhoneNumberEdit() {
				return !!this.meetingUrl;
			},
			allowReset() {
				return this.meetingUrl;
			},
			emailRules(v) {
				if (!this.includeEmailInvitation) {
					return [];
				}
				const errorTpl = "Please enter a valid email or un-check the Include Email Invitation option.";
				return [
					VuetifyValidation.RequiredValidationField(errorTpl).isValid(v.email),
					VuetifyValidation.EmailValidation(errorTpl).isValid(v.email),
				];
			},
			emailEditDisabled() {
				return this.emailDisabled || !this.includeEmailInvitation || this.disablePhoneNumberEdit;
			},
			customerItems() {
				// TODO: Create a helper function out of this to apply to all places where a combobox/autocomplete uses it
				return this.customers
					.map((customer) => {
						let name = `${customer.firstname} ${customer.lastname}`.trim();
						let phone = null;
						let email = null;
						let searchLabel = name;

						if (name === "") {
							name = null;
						}

						if (customer.PhoneNumbers?.length > 0) {
							phone = customer.PhoneNumbers[0].phoneNumber;
							searchLabel = `${searchLabel} ${phone.replaceAll(" ", "")}`;
						}

						if (customer.EmailAddresses?.length > 0) {
							email = customer.EmailAddresses[0].emailAddress;
							searchLabel = `${searchLabel} ${email}`;
						}

						return Object.assign({}, customer, { name, phone, email, searchLabel });
					})
					.filter((x) => x != null && x.searchLabel.trim() !== "");
			},
			teamMemberItems() {
				return this.teamMembers.map((user) => {
					let name = "";

					if (user.firstname != null) {
						name = user.firstname;
					}

					if (user.lastname != null) {
						name = `${name} ${user.lastname}`;
					}

					return Object.assign({}, user, { name });
				});
			},
		},
		activated() {
			try {
				this.$refs.phoneInput.focus();
			} catch (e) {}
		},
		async mounted() {
			this.me = await api.getMe();
			this.reset();
			this.organization = this.me.organization;
			this.autoStartRecordings = this.organization.autoStartRecordings;
			this.notificationTemplate =
				this.conversationType == "async"
					? this.organization.notificationTemplateAsync
					: this.organization.notificationTemplate;
			this.defaultNotificationTemplate = this.notificationTemplate;
			if (this.projectId) {
				const result = await api.getProject(this.projectId);
				this.newSelectedProject = result;
			}
			this.customers = await Dialogs.getCustomers();

			if (this.customerId) {
				const result = await api.getCustomers({
					where: {
						id: this.customerId,
					},
				});

				if (result.count > 0) {
					this.customers = result.rows;
					let customer = result.rows[0];
					const name = `${customer.firstname} ${customer.lastname}`.trim();
					customer = Object.assign({}, customer, { name });
					this.setCustomer(customer);
				}
			}

			await this.loadTeamMembers();
		},
		methods: {
			async updateProject(project) {
				this.newSelectedProject = project;
			},
			cardClass() {
				return `conversation-card  ${DeviceUtility.isMobile ? "conversation-bottom" : ""} ${
					this.conversationType
				}`;
			},
			startButtonDisabled() {
				return !this.validPhone || !this.form || this.starting || !!this.actioningTextOverride;
			},
			reset() {
				this.meetingUrl = "";
				this.phone = "";
				this.email = "";
				this.includeEmailInvitation = false;
				this.name = "";
				this.autoSetName = "";
				this.autoSetNameValue = "";
				this.shareUser = null;
				this.notificationTemplate =
					this.conversationType == "async"
						? this.organization.notificationTemplateAsync
						: this.organization.notificationTemplate;

				setTimeout(() => {
					try {
						this.$refs.phoneInput.focus();
					} catch (e) {}
				}, 500);
			},
			countCharacters(value) {
				return value?.unicodeLength();
			},
			pasteEvent(event) {
				this.phone = event.clipboardData?.getData("text")?.replace(/\D/g, "");
				// If a user pastes into the vue-tel-input, force it to reformat itself.
				this.$nextTick(function () {
					try {
						this.$refs.phoneInput.onInput();
					} catch (e) {}
				});
			},
			// Fires when the correctness of the vue-tel-input's phone number changes (from true to false or vice-versa) and when the component is mounted.
			validate(args) {
				this.validPhone = args.valid;
			},
			// Fires when the vue-tel-input's input changes.
			async matchPhone(number, phoneObject) {
				if (!this.validPhone || !phoneObject || !phoneObject.valid) {
					// bail on invalid numbers
					if (phoneObject && !phoneObject.formatted && this.selectedCustomer?.id) {
						this.clearCustomer();
					} else if (phoneObject && phoneObject.formatted?.length > 0 && !phoneObject.valid) {
						this.setCustomer(null, true);
					}
					return;
				}

				// Make sure that even if the user changes the formatting of their phone number, we always internally
				// update it to formatted version. This way, when the phone number gets saved to the database, it is
				// always properly formatted.
				this.$nextTick(() => {
					this.phone = phoneObject.formatted;
				});
				this.deactivateCustomerPhoneSearchMenu();

				if (this.phone == "") {
					this.clearCustomer();
					return;
				}

				if (!number && this.autoSetName) {
					this.name = "";
				}

				if (this.customerJustSelected) {
					this.customerJustSelected = false;
					return;
				}

				const result = await api.getCustomers({
					where: {
						"$PhoneNumbers.phoneNumber$": {
							$like: `%${number}%`,
						},
					},
				});

				if (result.count > 0) {
					this.customers = result.rows;
					let customer = result.rows[0];
					const name = `${customer.firstname} ${customer.lastname}`.trim();
					customer = Object.assign({}, customer, { name });
					this.setCustomer(customer);
					clearTimeout(this._searchTimerId);
				} else {
					this.setCustomer(null, true, this.searchTerm);
				}
			},
			customerSelected(customer) {
				if (customer) {
					this.customerJustSelected = true;
				}

				this.setCustomer(customer);
			},
			customerPhoneSelected(customer) {
				this.setCustomer(customer);
				this.phone = customer.phone;
				this.deactivateCustomerPhoneSearchMenu();
				this.customerJustSelected = true;
			},
			phoneBlur() {
				this.phoneNumberFieldInFocus = false;
				this.deactivateCustomerPhoneSearchMenu();
				this.showPhoneFieldErrorState = this.phone && !this.validPhone;
			},
			phoneFocus() {
				this.phoneNumberFieldInFocus = true;
				this.activateCustomerPhoneSearchMenu();
				this.showPhoneFieldErrorState = false;
			},
			activateCustomerPhoneSearchMenu() {
				if (this.phone.length > 0 && this.phoneNumberFieldInFocus) {
					this.phoneSearchResultsOpen = true;
					if (this?.$refs?.customerPhoneSearch) {
						this.$refs.customerPhoneSearch.isMenuActive = true;
					}
				}
			},
			deactivateCustomerPhoneSearchMenu() {
				this.phoneSearchResultsOpen = false;
				if (this?.$refs?.customerPhoneSearch) {
					this.$refs.customerPhoneSearch.isMenuActive = false;
				}
			},
			async clearCustomer() {
				this.setCustomer(null);
				this.phone = "";
				this.customers = await Dialogs.getCustomers();
			},
			setCustomer(customer, filtering = false, searchTerm = "") {
				this.selectedCustomer = customer;
				if (customer) {
					this.validCustomer = true;
					this.name =
						customer.firstname && customer.lastname
							? `${customer.firstname} ${customer.lastname}`
							: customer.firstname || customer.lastname;
					this.autoSetName = true;
					this.autoSetNameValue = this.name;

					if (customer.EmailAddresses?.length > 0) {
						this.includeEmailInvitation = true;
						this.email = customer.EmailAddresses[0].emailAddress;
						this.emailDisabled = true;
					}

					if (customer.PhoneNumbers?.length > 0) {
						if (!this.phone || this.customerJustSelected) {
							this.phone = customer.PhoneNumbers[0].phoneNumber;
						}
					} else {
						this.phone = "";
					}
				} else {
					this.validCustomer = false;
					this.name = searchTerm;
					if (this.emailDisabled) {
						this.email = "";
						this.emailDisabled = false;
						this.includeEmailInvitation = false;
					}
					if (!filtering) {
						this.phone = "";
					}
				}
			},
			notesChanged(notes) {
				// If the user removes the text is in the 'notes' text area, set their
				// notification template to use the default notification template.
				if (notes == "") {
					this.notificationTemplate = this.defaultNotificationTemplate;
				}
			},
			loadTeamMembers() {
				this.teamMembers = [];
				api.getSharingUsers().then((result) => {
					result.forEach((user) => {
						if (user.Accounts[0].Roles[0].name.toLowerCase() === "administrator") {
							this.teamMembers.push(user);
						}
					});
				});
			},
			join() {
				window.open(this.meetingUrl, "_blank");
			},
			async start() {
				if (!this.validPhone || !this.phone) {
					return;
				}

				this.starting = true;

				try {
					let baseUrl = window.env.VITE_RAPID_DOMAIN;
					if (this.conversationType === "async") {
						baseUrl = window.env.VITE_ASYNC_DOMAIN;
					}

					const me = await api.getMe();
					const selectedUserId = me.user.id;
					const nameParts = this.name.trim().split(" ");
					const first = nameParts.length > 0 ? nameParts[0] : "";
					const last = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";

					if (this.newSelectedProject && this.selectedCustomer?.id) {
						const selectedContact = this.selectedCustomer;
						let contactExistance = false;
						if (!this.newSelectedProject.Customers) {
							this.newSelectedProject.Customers = [];
						}
						for (const contact of this.newSelectedProject.Customers) {
							if (contact.id === selectedContact.id) {
								contactExistance = true;
								break;
							}
						}
						if (!contactExistance) {
							await api.updateProject(this.newSelectedProject.id, {
								Customers: [...this.newSelectedProject.Customers, selectedContact],
							});
						}
					}
					let conversationName;
					if (this.conversationType == constants.CONVERSATION_TYPE.ASYNC) {
						let titlePrefix = "Recording from";

						let name = first || last || this.phone.trim() || this.email.trim();
						conversationName = titlePrefix + " " + name;
						const formattedDate = timezones.formatDate(new Date().toISOString(), "M/D/YYYY");
						conversationName = conversationName + " on " + formattedDate;
					}
					// save data
					const result = await api.createConversation({
						referrer: baseUrl,
						autoStartRecordings: this.organization.autoStartRecordings,
						forcedUserId: selectedUserId,
						notificationSMS: false,
						notificationEmail: this.includeEmailInvitation,
						name: conversationName,
						notificationTemplate: this.notificationTemplate?.trim(),
						screenShareSupported: this.conversationType != constants.CONVERSATION_TYPE.ASYNC,
						visitors: [
							{
								phone: this.phone.trim(),
								email: this.email.trim(),
								firstname: first,
								lastname: last,
								notificationSMS: true,
								notificationEmail: this.includeEmailInvitation,
							},
						],
						shareUserId: this.shareUser ? this.shareUser.id : undefined,
						projectId: this.newSelectedProject ? this.newSelectedProject.id : undefined,
						type: this.conversationType,
						initiationType: "admin",
						browserId: tokenHandler.getBrowserId(),
						browserToken: await tokenHandler.getOrRefreshToken(),
						timeZone: moment.tz.guess(),
					});

					this.meetingUrl = result.url;

					if (this.allowJoin) {
						this.join();
					} else {
						this.actioningTextOverride = "Sent!";
						window.setTimeout(() => {
							this.actioningTextOverride = "";
							this.close();
						}, 1500);
						EventBus.$emit("UpdateDashboard");
					}

					this.$emit("started");
				} catch (e) {
					console.error(e);
				}
				this.starting = false;
			},
		},
		watch: {
			searchCustomers(searchTerm) {
				this.searchTerm = searchTerm;
				clearTimeout(this._searchTimerId);
				this._searchTimerId = setTimeout(async () => {
					const customers = await Dialogs.getCustomers(searchTerm);
					this.customers = customers;
				}, 500);
			},
			phone: {
				async handler(newValue) {
					if (!newValue) {
						this.deactivateCustomerPhoneSearchMenu();
						const customers = await Dialogs.getCustomers(newValue);
						this.customers = customers;
						return;
					}

					this.phoneSearch = newValue;

					if (this.customerJustSelected) {
						// The user just selected one of search results so we don't want to do a search/open the menu
						return;
					}

					clearTimeout(this._searchTimerId);
					this._searchTimerId = setTimeout(async () => {
						const customers = await Dialogs.getCustomers(newValue);
						this.customers = customers;

						if (customers.length >= 0) {
							this.activateCustomerPhoneSearchMenu();
						} else if (!newValue) {
							this.deactivateCustomerPhoneSearchMenu();
						}
					}, 500);
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.start-conversation-button {
		font-family: "Inter Tight", sans-serif;
		font-size: 20px;
		color: white;
		margin-left: 10px;
	}
	.reset-span {
		position: relative;
		left: calc(100% - 50px);
		display: inline-block;
		top: -30px;
		height: 0;
	}

	.conversation-card {
		border-radius: 12px;
		min-height: 400px;
		display: flex;
		flex-direction: column;
		justify-content: start;
	}

	.conversation-card .header {
		display: flex;
		margin-bottom: 28px;
		margin-top: 12px;
	}

	.conversation-card textarea {
		color: #212121;
		font-size: 16px;
	}

	.conversation-card h2,
	.conversation-card i {
		font-size: 24px;
		margin-right: 5px;
	}

	.conversation-card .v-card__actions {
		padding: 16px;
		display: flex;
		justify-content: space-evenly;
	}

	.conversation-card .v-card__actions > button {
		width: 50%;
		height: 50px;
		border-radius: 25px;
		line-height: 20px;
		font-size: 1em;
		font-weight: 600;
	}

	.conversation-card .header {
		color: #000;
	}

	.conversation-card .auto-message {
		color: #b3b3b3;
		font-size: 16px;
	}

	.conversation-card .record-switch {
		margin-left: 5px;
		margin-top: 0px;
	}

	.conversation-card .v-card__actions > button:nth-child(2) {
		display: block;
		border: 1px solid #e0e0e0 !important;
	}

	.conversation-card .contact-name.reset {
		padding-top: 0px;
	}

	.v-slide-group__next,
	.v-slide-group__prev {
		display: none;
	}

	/* Email Invite */

	.team-member-share {
		position: absolute;
		padding-top: 8px;
		margin-left: 2px;
		color: #0070ff;
	}

	.customer-phone-search {
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
	}
</style>
