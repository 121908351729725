<template>
	<div
		ref="videoContainer"
		class="video-container d-flex flex-column"
		style="height: 100%; max-height: 100%"
		:class="{
			fullscreen: isFullScreen,
			playing: isPlaying,
			paused: !isPlaying,
			scrubbing: isScrubbing,
			wasPlaying: wasPlaying,
			waiting: waiting,
		}"
		@click="() => openLightBox(recordingId)">
		<v-icon
			@click="play"
			v-if="controlsEnabled && canPlay"
			v-show="!isPlaying"
			size="10"
			class="pause-indicator"
			style="z-index: 100; color: #fff"
			>{{ isPlaying ? "icon-pause" : "icon-play-solid" }}</v-icon
		>
		<v-progress-circular
			class="loading-indicator"
			v-show="waiting"
			indeterminate
			size="100"
			width="7"
			color="primary">
		</v-progress-circular>
		<div
			class="d-flex video-processing"
			v-if="!thumbnail && !videoReady && !waiting"
			:style="{ 'border-radius': isMobile ? '12px' : '0' }">
			<div class="icon">
				<v-img :src="require('@/assets/liveswitch-v-sign-hand-emoji.svg')" alt="Processing" />
			</div>
			<h5 class="message">Loading...</h5>
		</div>
		<video
			v-show="thumbnail || videoReady"
			controls
			ref="video"
			:class="videoStyle"
			style="height: 50%; flex: 1"
			:style="{
				'border-top-left-radius': thumbnail ? 0 : isMobile ? '0px' : '12px',
				'object-fit': (limitHeight || isRecordingView) && isSafari ? 'fill' : isStarted ? 'contain' : 'cover',
				'background-color': isStarted ? '#000' : 'unset',
				'max-height': limitHeight ? '78px' : 'unset',
			}"
			:src="source"
			poster="@/assets/lsc-poster.svg"
			preload="metadata"
			crossOrigin="anonymous"
			webkit-playsinline
			playsinline
			@click="play"
			@play="onPlay"
			@pause="onPause"></video>
		<div
			v-if="controlsEnabled"
			v-show="videoReady"
			id="video-controls test-class"
			class="video-controls"
			:class="{
				active: areCustomControlSupported,
				fullscreen: isFullScreen,
			}">
			<div class="video-slider">
				<v-slider
					v-model="percent"
					color="white"
					track-color="#FFFFFF52"
					thumb-color="white"
					id="seek-slider"
					min="0"
					:max="maxSliderValue"
					:thumb-size="48"
					:class="{ scrubbing: isScrubbing }"
					@click="sliderClicked"
					@input="videoScrubbed">
				</v-slider>
			</div>
			<div class="video-buttons" @click="controlsClicked">
				<v-btn id="play" icon :data-state="!isPlaying ? 'play' : 'pause'" @click="play">
					<v-icon>{{ isPlaying ? "icon-pause" : "icon-play-solid" }}</v-icon>
				</v-btn>
				<div class="progress">
					<span class="current-time">
						{{ toHoursAndMinutes(video.currentTime) }}
					</span>
					/
					<span class="total-time">
						{{ toHoursAndMinutes(duration) }}
					</span>
				</div>
				<v-btn id="mute" icon :data-state="isMuted ? 'unmute' : 'mute'" @click="mute">
					<v-icon>{{ isMuted ? "icon-volume-slash-solid" : "icon-volume-solid" }}</v-icon>
				</v-btn>
				<v-btn id="download" icon :disabled="isDownloading" @click="download">
					<v-icon v-if="!isDownloading">icon-download</v-icon>
					<v-progress-circular
						v-else
						class="loading"
						color="white"
						:size="20"
						indeterminate></v-progress-circular>
				</v-btn>
				<v-btn v-if="canSnapshot" id="snapshot" icon :disabled="video.currentTime == 0" @click="takeSnapshot">
					<v-icon>icon-snapshot</v-icon>
				</v-btn>
				<v-btn id="fullscreen" icon @click="toggleFullscreen" ref="fullScreenButton">
					<v-icon>{{ isFullScreen ? "icon-fullscreen-exit" : "icon-fullscreen" }}</v-icon>
				</v-btn>
				<v-menu top left :absolute="isFullScreen" :attach="videoContainer" v-model="showMoreControls">
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" @click="showMoreControls = true">
							<v-icon>icon-dots-vertical-more</v-icon>
						</v-btn>
					</template>

					<v-list class="more-controls">
						<v-list-item v-if="canPictureInPicture">
							<v-list-item-title @click="startPictureInPicture"
								><i class="icon-picture-in-picture" /> Picture in picture</v-list-item-title
							>
						</v-list-item>
						<v-list-item>
							<v-menu
								top
								:attach="isFullScreen ? videoContainer : undefined"
								right
								:absolute="isFullScreen"
								:max-height="300"
								:nudge-left="isFullScreen ? 64 : 0"
								v-model="showSpeedControls">
								<template v-slot:activator="{ on, attrs }">
									<v-list-item-title
										v-bind="attrs"
										v-on="on"
										@click="
											showSpeedControls = true;
											showMoreControls = false;
										"
										><i class="icon-playback-speed" /> Playback speed</v-list-item-title
									>
								</template>

								<v-list class="speed-list">
									<v-list-item>
										<v-list-item-title @click="goBackToMoreControls"
											><i class="icon-chevron-left" /> Options</v-list-item-title
										>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.25 }">
										<v-list-item-title @click="changePlaybackSpeed(0.25)">0.25</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.5 }">
										<v-list-item-title @click="changePlaybackSpeed(0.5)">0.5</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.75 }">
										<v-list-item-title @click="changePlaybackSpeed(0.75)">0.75</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1 }">
										<v-list-item-title @click="changePlaybackSpeed(1)">Normal</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.25 }">
										<v-list-item-title @click="changePlaybackSpeed(1.25)">1.25</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.5 }">
										<v-list-item-title @click="changePlaybackSpeed(1.5)">1.5</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.75 }">
										<v-list-item-title @click="changePlaybackSpeed(1.75)">1.75</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 2 }">
										<v-list-item-title @click="changePlaybackSpeed(2)">2</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>
	</div>
</template>

<script>
	import DeviceUtility from "@/helpers/device";

	export default {
		name: "VideoPlayer",
		components: {},
		props: {
			contentClass: String,
			shouldPause: Boolean,
			canSnapshot: Boolean,
			filename: String,
			recordingId: String,
			source: String,
			videoId: String,
			disablePlayback: Boolean,
			openVideoLightBox: Function,
			isSelected: Boolean,
			limitHeight: Boolean,
			isRecordingView: Boolean,
			controlsEnabled: {
				type: Boolean,
				default: true,
			},
			thumbnail: {
				type: Boolean,
				default: false,
			},
		},
		emits: ["take-snapshot"],
		data: () => ({
			areCustomControlSupported: false,
			canPictureInPicture: true,
			canPlay: false,
			currentPlaybackSpeed: 1,
			duration: 0,
			figureId: null,
			isDownloading: false,
			isFullScreen: false,
			isMuted: false,
			isPlaying: false,
			isStarted: false,
			isScrubbing: false,
			maxSliderValue: 100,
			currentTime: 0,
			percent: 0,
			playPromise: undefined,
			progress: 0,
			showMoreControls: false,
			showSpeedControls: false,
			wasPlaying: false,
			waiting: false,
			video: {
				muted: false,
				paused: false,
				ended: false,
				currentTime: 0,
			},
			videoContainer: null,
			videoReady: false,
			fullScreenButton: null,
			isMobile: false,
			isLandscape: false,
			isSafari: false,
		}),
		mounted() {
			this.isMobile = this.$vuetify.breakpoint.smAndDown;
			this.isLandscape = window.matchMedia("(orientation: landscape)").matches;
			this.isSafari = DeviceUtility.isSafari;
			const supportsVideo = !!document.createElement("video").canPlayType;

			if (supportsVideo) {
				// set up custom controls
				this.areCustomControlSupported = true;
				this.videoContainer = this.$refs.videoContainer;
				this.video = this.$refs.video;
				this.fullScreenButton = this.$refs.fullScreenButton;

				//hide default controls
				this.$refs.video.controls = false;
				this.$refs.video.controls = false;
				this.$refs.video.load();

				if (!("pictureInPictureEnabled" in document)) {
					this.canPictureInPicture = false;
				}
				this.$refs.video.addEventListener("loadedmetadata", () => {
					this.canPlay = true;
					this.videoReady = true;
					this.$emit("loaded-metadata", this.video, this.recordingId);
					this.duration = this.$refs.video.duration;

					if (this.duration > 180) {
						this.maxSliderValue = 1000;
					}
				});

				this.$refs.video.addEventListener("progress", () => {
					// todo: can we use this to display the second loader bar?
					// console.log(`progress`);
				});

				this.$refs.video.addEventListener("canplay", () => {
					// console.log(`can play ${this.videoId}`);
					this.$emit("loaded-metadata", this.video, this.recordingId);
					this.canPlay = true;
					if (this.waiting) {
						this.playPromise = this.$refs.video.play();
						this.waiting = false;
					}
				});

				this.$refs.video.addEventListener("waiting", () => {
					// wait a specified time before setting wait state as the waiting event fires every time you seek in the video, and we dont want to show an indicator each time
					setTimeout(() => {
						// if video now has enough data to play, ignore the event
						// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
						if (this.$refs.video.readyState >= HTMLMediaElement.HAVE_FUTURE_DATA) return;
						this.canPlay = false;
						this.waiting = true;
					}, 1000);
				});

				this.$refs.video.addEventListener("play", () => {
					this.isPlaying = true;
					this.waiting = false;
					if (this.playPromise !== undefined) {
						this.playPromise = undefined;
					}
				});

				this.$refs.video.addEventListener("pause", () => {
					this.isPlaying = false;
				});

				this.$refs.video.addEventListener("timeupdate", () => {
					// video current time in percentage
					this.percent = Math.floor((this.$refs.video.currentTime / this.duration) * this.maxSliderValue);
				});

				document.addEventListener("fullscreenchange", () => {
					if (!document.fullscreenElement) {
						this.isFullScreen = false;
					}
				});
				this.$refs.video.addEventListener("webkitendfullscreen", () => {
					if (
						document.fullScreenElement ||
						document.webkitIsFullScreen == true ||
						document.mozFullScreen ||
						document.msFullscreenElement
					) {
						this.isFullScreen = true;
					} else {
						this.isFullScreen = false;
					}
				});
			}
		},
		computed: {
			videoContainerId() {
				return `video-${this.recordingId}-container`;
			},
			videoStyle() {
				return {
					"artifact-item": this.isSelected,
					"current-recording": this.contentClass === "current-recording",
					"conversation-recording": true,
					thumbnail: this.thumbnail,
				};
			},
		},
		watch: {
			shouldPause(newVal) {
				this.pause();
			},
		},
		methods: {
			changePlaybackSpeed(speed) {
				this.$refs.video.playbackRate = speed;
				this.currentPlaybackSpeed = speed;
				window.setTimeout(() => {
					this.showSpeedControls = false;
					this.showMoreControls = false;
					console.log("hiding speed controls, hiding more controls");
				}, 0);
			},
			controlsClicked(e) {
				e.stopPropagation();
				e.preventDefault();
			},
			openLightBox(recordingId) {
				if (this.openVideoLightBox) {
					this.openVideoLightBox(recordingId);
				}
			},
			takeSnapshot() {
				this.$emit("take-snapshot", this.video, this.recordingId);
			},
			async download(e) {
				e.preventDefault();
				const me = this;
				this.isDownloading = true;
				const filename = this.filename;
				var xhr = new XMLHttpRequest();
				xhr.open("GET", this.$refs.video.src, true);
				xhr.responseType = "blob";
				xhr.onload = function () {
					var urlCreator = window.URL || window.webkitURL;
					var videoUrl = urlCreator.createObjectURL(this.response);
					var tag = document.createElement("a");
					tag.href = videoUrl;
					tag.target = "_blank";
					tag.download = filename;
					document.body.appendChild(tag);
					tag.click();
					document.body.removeChild(tag);
					me.isDownloading = false;
				};
				xhr.onerror = (err) => {
					me.isDownloading = false;
					console.log("Error download video", err);
				};
				xhr.send();
			},
			goBackToMoreControls() {
				window.setTimeout(() => {
					this.showSpeedControls = false;
					this.showMoreControls = true;
					console.log("hiding speed controls, showing more controls");
				}, 0);
			},
			mute() {
				this.isMuted = !this.isMuted;
				this.$refs.video.muted = !this.$refs.video.muted;
			},
			async play() {
				if (this.thumbnail) {
					return;
				}

				if (!this.canPlay) {
					console.warn("Tried playing video before canPlay is set.");
					return;
				}

				this.isStarted = true;

				const isPlaying =
					this.$refs.video.currentTime > 0 &&
					!this.$refs.video.paused &&
					!this.$refs.video.ended &&
					this.$refs.video.readyState > this.$refs.video.HAVE_CURRENT_DATA;

				if (!isPlaying) {
					console.log("this play");
					//this.playPromise = this.video.play();
					this.$refs.video.play();
				} else {
					await this.pause();
				}
			},
			async pause() {
				if (this.playPromise !== undefined) {
					await this.playPromise;
					this.$refs.video.pause();
				} else {
					this.$refs.video.pause();
				}
			},
			onPlay() {
				this.isPlaying = true;
			},
			onPause() {
				this.isPlaying = true;
			},
			setFullscreenData(state) {
				this.$refs.videoContainer.setAttribute("data-fullscreen", !!state);

				if (state) {
					this.$refs.video.classList.add("fullscreen");
				} else {
					this.$refs.video.classList.remove("fullscreen");
				}
			},
			sliderClicked(e) {
				e.preventDefault();
				e.stopPropagation();
			},
			async startPictureInPicture() {
				try {
					if (this.$refs.video !== document.pictureInPictureElement) {
						await this.$refs.video.requestPictureInPicture();
					}
					window.setTimeout(() => {
						this.showSpeedControls = false;
						this.showMoreControls = false;
						console.log("hiding speed controls, hiding more controls");
					}, 0);
				} catch (error) {
					console.error(error);
				}
			},
			async toggleFullscreen() {
				if (
					document.fullscreenElement ||
					document.webkitFullscreenElement ||
					document.mozFullScreenElement ||
					document.webkitCurrentFullScreenElement
				) {
					// The document is in fullscreen mode
					// document.exitFullscreen();
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.mozCancelFullScreen) {
						/* Firefox */
						document.mozCancelFullScreen();
					} else if (document.webkitExitFullscreen) {
						/* Chrome, Safari and Opera */
						document.webkitExitFullscreen();
					} else if (document.msExitFullscreen) {
						/* IE/Edge */
						document.msExitFullscreen();
					}
					this.setFullscreenData(false);
					this.isFullScreen = false;
				} else {
					// The document is not in fullscreen mode

					let timeout = 1;
					this.isFullScreen = true;
					this.setFullscreenData(true);

					// Workaround for iOS issue. Start playing the video prior to requesting full screen.
					if (DeviceUtility.isIOS) {
						await this.play();
						timeout = 200;
					}

					setTimeout(() => {
						if (this.$refs.videoContainer.requestFullscreen) {
							this.$refs.videoContainer.requestFullscreen();
						} else if (this.$refs.videoContainer.mozRequestFullScreen) {
							/* Firefox */
							this.$refs.videoContainer.mozRequestFullScreen();
						} else if (this.$refs.videoContainer.webkitRequestFullscreen) {
							/* Chrome, Safari and Opera */
							this.$refs.videoContainer.webkitRequestFullscreen();
						} else if (this.$refs.video.webkitEnterFullScreen) {
							this.$refs.video.webkitEnterFullScreen();
						} else if (this.$refs.videoContainer.msRequestFullscreen) {
							/* IE/Edge */
							this.$refs.videoContainer.msRequestFullscreen();
						}
					}, timeout);
				}
			},
			toHoursAndMinutes(totalSeconds) {
				const totalMinutes = Math.floor(totalSeconds / 60);
				const seconds = (totalSeconds % 60).toFixed(0).toString();
				const hours = Math.floor(totalMinutes / 60).toString();
				const minutes = (totalMinutes % 60).toString();
				if (hours > 0) {
					return `${hours}:${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
				}
				return `${minutes}:${seconds.padStart(2, "0")}`;
			},
			async videoScrubbed(value) {
				//turn off transition time
				this.wasPlaying = !this.$refs.video.paused;
				this.isScrubbing = true;
				if (!this.waiting) {
					await this.pause();
				}
				this.$refs.video.currentTime = (value / this.maxSliderValue) * this.duration;
				window.setTimeout(() => {
					if (this.wasPlaying && !this.waiting) {
						this.playPromise = this.$refs.video.play();
					}
					this.wasPlaying = false;
					this.isScrubbing = false;
				}, 100);
			},
		},
	};
</script>

<style scoped>
	.artifact-item {
		padding: 0;
		border: 2px solid blue;
		margin-bottom: 8px;
	}

	.conversation-recordings > div:first-child {
		margin-bottom: 20px;
	}

	.conversation-recording {
		object-fit: contain;
		max-width: 100%;
	}

	.conversation-recording .fullscreen {
		height: 100%;
	}

	#video-container {
		height: 83px !important;
		border-radius: 16px !important;
	}

	.video-container {
		position: relative;
		color: #fff;
		width: 100%;
	}

	.pause-indicator {
		background-color: #00000066;
		border-radius: 100px;
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2rem !important;
	}

	.loading-indicator {
		position: absolute;
		top: calc(50% - 50px);
		left: calc(50% - 50px);
	}

	.video-processing {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		border: 1px solid var(--ls-contact-theme-space-cadet-8, rgba(3, 1, 29, 0.08));
		background: var(
			--Video-Processing-Gradient,
			linear-gradient(254deg, #f7f5fe 0%, #f0f8ff 13.76%, #ebfffa 43.55%, #eaf9ff 76.6%, #fef5fd 99.13%)
		);
	}

	.video-processing .message {
		color: #000;
	}

	.video-processing > .refresh > .v-btn {
		background-color: #03011d80 !important;
		color: #fff !important;
	}

	.video-container.paused.scrubbing.wasPlaying::after {
		content: "";
		background: none;
	}

	.video-container.waiting .loading {
		position: absolute;
		top: calc(50% - 25px);
		left: calc(50% - 25px);
		color: gray;
		z-index: 1;
	}

	.video-container.fullscreen {
		height: 100%;
	}

	.video-container.fullscreen video {
		height: 100%;
	}

	video.thumbnail {
		height: 100%;
		width: 100%;
		background-color: #fff;
	}

	video.started {
		background-color: #000;
	}

	.video-container i {
		font-size: 18px;
		color: #fff;
	}

	.video-container.fullscreen .video-controls {
		position: absolute;
		bottom: 0;
	}

	/* Lighbox styles */

	.video-controls {
		width: 100%;
		/* height: 100%; */
		background-color: #000 !important;
		padding: 0 8px 8px 8px;
		background: rgb(0, 0, 0);
		background: -moz-linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		background: -webkit-linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#000000",GradientType=1);
	}

	.video-controls.active {
		display: flex;
		flex-direction: column;
		/* justify-content: flex-end; */
	}

	.video-controls::v-deep > .video-slider .v-slider__thumb-container,
	.video-controls::v-deep > .video-slider .v-slider__track-background,
	.video-controls::v-deep > .video-slider .v-slider__track-fill {
		transition: 0.3s linear;
	}

	.video-controls::v-deep > .video-slider > .scrubbing .v-slider__thumb-container,
	.video-controls::v-deep > .video-slider > .scrubbing .v-slider__track-background,
	.video-controls::v-deep > .video-slider > .scrubbing .v-slider__track-fill {
		transition: 0s;
	}

	.video-controls::v-deep > .video-slider .v-slider--horizontal {
		margin-left: 18px;
		margin-right: 18px;
	}

	.video-controls::v-deep > .video-slider .v-slider--horizontal .v-slider__track-container {
		height: 6px;
		border-radius: 5px;
	}

	.video-controls::v-deep > .video-slider .v-slider--horizontal .v-slider__thumb {
		width: 18px;
		height: 18px;
		left: -9px;
	}

	.video-controls::v-deep > .video-slider .v-slider--horizontal .v-slider__thumb:before {
		width: 32px;
		height: 32px;
		transform: scale(0);
		left: -7px;
		top: -7px;
	}

	.video-controls::v-deep
		> .video-slider
		.v-slider--horizontal
		.v-slider__thumb-container:hover
		.v-slider__thumb:before {
		transform: scale(1);
	}

	.video-controls::v-deep > .video-slider .v-input__slider .v-input__slot {
		margin-bottom: 0px;
	}

	.video-controls::v-deep > .video-slider .v-input__slider .v-messages {
		display: none;
	}

	.video-controls::v-deep > .video-slider .v-slider__track-container .v-slider__track-background {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.video-controls::v-deep > .video-slider .v-slider__track-container .v-slider__track-fill {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.video-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.video-buttons > div.progress {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #ffffff99;
		font-family: "Inter";
		font-weight: 400;
	}

	.video-buttons > div.progress > .current-time {
		margin-right: 5px;
		color: #ffffff;
		font-weight: 600;
	}

	.video-buttons > div.progress > .total-time {
		margin-left: 5px;
	}

	.video-buttons > #snapshot:disabled i,
	.video-buttons > #snapshot[disabled] i {
		color: #cecece !important;
	}

	.video-buttons > .v-btn {
		background: none;
		color: #fff;
	}

	.more-controls::v-deep .v-list-item,
	.speed-list::v-deep .v-list-item {
		cursor: pointer;
	}

	.more-controls::v-deep i,
	.speed-list::v-deep i {
		color: #000;
	}

	.more-controls::v-deep .v-list-item:hover,
	.speed-list::v-deep .v-list-item:hover {
		background-color: #00000011;
	}

	.speed-list::v-deep .v-list-item.active {
		border-left-color: #0070ff;
		border-left-style: solid;
		border-left-width: 5px;
	}

	.speed-list::v-deep .v-list-item:not(:first-child) .v-list-item__title {
		text-align: center;
	}

	@media (max-width: 415px) {
		.v-btn--icon.v-size--default {
			width: 32px;
			height: 32px;
		}
	}

	@media (max-width: 959px) {
		.v-btn--icon.v-size--default {
			width: 24px;
			height: 24px;
			margin: 0 1px;
		}

		.v-btn--icon.v-size--default .v-icon {
			font-size: 1.25rem;
		}
	}
</style>
