import Logging, { TEvent } from "../../plugin/track";

export const trackDirective = {
  bind(el, binding, vnode) {
    if (binding.value) {
      const event = {
        name: binding.value,
        data: {
          action: "",
          testId: "",
          node: vnode.elm.tagName.toLowerCase(),
        },
      };

      // Check for `data-test-id` attribute
      const testId = el.getAttribute("data-test-id");
      if (testId) {
        event.data.testId = testId;
      }

      // Add event listener for buttons
      if (vnode.elm.tagName.toLowerCase() === "button") {
        el.addEventListener("click", () => {
          event.name = `${binding.value}_clicked`;
          event.data.action = `click`;
          Logging.log(event);
        });
      }
    }
  }
};

export default trackDirective;
