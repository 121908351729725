<template>
	<div class="d-flex">
		<SnapshotNotes
			:editNotesAllowed="editNotes"
			:notes="snapshotNotes"
			:onNotesChanged="onNotesChanged"
			:canSave="canSaveNotes"
			:save="saveNotes" />
		<v-chip v-if="lightBoxOpen && source === 'project'" color="#fff" class="lightbox-access">{{
			isViewOnly ? "Viewer" : "Editor"
		}}</v-chip>

		<ThumbnailTray
			ref="thumbnails"
			:artifacts="snapshotList"
			:artifact-type="'snapshots'"
			:is-view-only="isViewOnly"
			:parent-id="parentId"
			:has-free-trial-ended="hasFreeTrialEnded"
			@select-item="setCurrentSnapshotId"
			@download-artifacts="downloadArtifacts"
			@toggle-gallery="updateShowThumbs"
			@delete-artifacts="deleteArtifacts" />

		<div v-if="annotating" class="annotation-overlay"></div>
		<div v-if="annotating" class="annotation-controls" :class="{ 'thumbs-open': showThumbs }">
			<v-overlay :absolute="true" :opacity="0.5" :value="savingAnnotation">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
				Saving Image...
			</v-overlay>
			<SnapshotAnnotation
				:backgroundImage="backgroundImage"
				:backgroundImageCaption="backgroundImageCaption"
				@cancel-annotation="cancelAnnotation"
				@save-annotation="saveAnnotation">
			</SnapshotAnnotation>
		</div>
		<div v-show="cropping" class="crop-controls" :class="{ 'thumbs-open': showThumbs }">
			<v-overlay :absolute="true" :opacity="0.32" :value="savingCrop">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
				Cropping Image...
			</v-overlay>
			<div id="crop-container" ref="cropContainer"></div>
			<div>
				<v-btn elevation="0" color="#fff" @click="cancelCrop"> Back </v-btn>
				<v-btn elevation="0" color="#fff" @click="saveCrop"> Apply </v-btn>
			</div>
		</div>

		<div
			class="d-flex flex-column"
			style="background-color: #f3f5f9; height: 100vh; width: 100vw; flex: 1"
			:style="{
				display:
					this.$vuetify.breakpoint.smAndDown && this.$refs.thumbnails?.isOpen() ? 'none !important' : 'flex',
			}">
			<div
				class="d-flex align-center justify-space-between lightbox-header"
				v-if="isDesktop"
				:style="{ visibility: !showControls ? 'hidden' : 'visible' }">
				<div>
					<div>
						<v-btn
							fab
							small
							class="close-gallery ml-2"
							color="black"
							style="color: #fff"
							@click="toggleGallery">
							<v-icon>icon-grid-3x3</v-icon>
						</v-btn>
					</div>
				</div>
				<div class="d-flex flex-row justify-center align-center">
					<div>
						<v-tooltip
							v-if="
								this.$vuetify.breakpoint.smAndDown &&
								!isViewOnly &&
								!hasFreeTrialEnded
							"
							bottom
							content-class="lightbox-tooltip"
							:open-on-hover="!isTouch"
							:open-on-click="false"
							:open-on-focus="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="startComment">
									<v-icon>icon-chat-stroke</v-icon>
								</v-btn>
							</template>
							<span>Comment</span>
						</v-tooltip>

						<v-tooltip
							bottom
							v-if="!isViewOnly"
							content-class="lightbox-tooltip"
							:open-on-hover="!isTouch"
							:open-on-click="false"
							:open-on-focus="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="startAnnotation">
									<v-icon>icon-pencil-edit-stroke</v-icon>
								</v-btn>
							</template>
							<span>Annotate</span>
						</v-tooltip>
						<v-tooltip v-if="!isViewOnly" bottom content-class="lightbox-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="editSnapshot">
									<div class="icon pt-1">
										<img :src="require('@/assets/liveswitch-caption.svg')" alt="Processing" />
									</div>
								</v-btn>
							</template>
							<span>Add Caption</span>
						</v-tooltip>

						<v-tooltip v-if="!isViewOnly" bottom content-class="lightbox-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="cropSnapshot">
									<v-icon>icon-crop</v-icon>
								</v-btn>
							</template>
							<span>Crop</span>
						</v-tooltip>
					</div>
					<div>
						<v-tooltip bottom content-class="lightbox-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="downloadSnapshot">
									<v-icon>icon-download</v-icon>
								</v-btn>
							</template>
							<span>Download</span>
						</v-tooltip>

						<v-tooltip bottom content-class="lightbox-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-if="!isViewOnly"
									elevation="0"
									icon
									v-bind="attrs"
									v-on="on"
									@click="() => deleteSnapshot()">
									<v-icon color="red">icon-trash-full-stroke</v-icon>
								</v-btn>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</div>
				</div>
				<v-btn fab small class="mr-2" color="black" style="color: #fff" :ripple="false" @click="closeLightBox">
					<v-icon>icon-close-small</v-icon>
				</v-btn>
			</div>
			<div v-else-if="!annotating && !cropping" class="d-flex align-center justify-space-between pa-4">
				<div>
					<v-btn
						fab
						small
						class="close-gallery ml-2"
						color="black"
						style="color: #fff"
						@click="toggleGallery">
						<v-icon>icon-grid-3x3</v-icon>
					</v-btn>
				</div>
				<div class="d-flex flex-row align-center" v-if="getUserInitials() !== '-'">
					<v-avatar
						size="40"
						color="primary"
						class="team-avatar"
						:style="{ 'background-color': getUserAvatarBackgroundColor() }">
						{{ getUserInitials() }}
					</v-avatar>
					<h6 class="ml-2 chip-text">{{ user?.name }}</h6>
				</div>
				<v-btn icon x-large color="black" style="color: #fff" :ripple="false" @click="closeLightBox">
					<v-icon>icon-close-small</v-icon>
				</v-btn>
			</div>

			<div
				style="height: calc(100% - 72px)"
				class="d-flex flex-column"
				:class="{ 'justify-center': isDesktop }"
				v-if="!cropping && !annotating">
				<div style="height: 100%" class="d-flex flex-column align-center">
					<v-carousel
						class="recording-carousel"
						light
						:class="{ 'mobile-recording-carousel': isMobile }"
						:style="{ display: isCommentBoxOpen && isMobile ? 'none !important' : 'flex' }"
						:hide-delimiters="true"
						hide-delimiter-background
						delimiter-icon="icon-round-solid"
						:show-arrows="snapshotList.length > 1"
						:height="isDesktop ? '100%' : ''"
						v-model="currentSnapshotId"
						@change="snapshotChanged">
						<v-carousel-item
							v-for="(snapshot, i) in snapshotList"
							v-bind:key="snapshot.id"
							:eager="true"
							:value="snapshot.id">
							<div
								class="d-flex align-center flex-column"
								:class="{ 'justify-center': isDesktop }"
								style="width: 100%; height: 100%">
								<!--Processing recording, please wait.-->
								<!-- TODO: Removed from style below, causing spacing issue: overflow: !isLandscape ? 'hidden' : 'scroll', -->
								<div
									class="d-flex flex-row justify-center"
									:style="{
										width: isMobile && isLandscape ? '85%' : '90%',
										maxHeight: isMobile && isLandscape ? '100%' : '80%',
										minHeight: isMobile && isLandscape ? '100%' : '80%',
									}">
									<!--Snapshop-->
									<div
										class="d-flex snapshot-container"
										v-show="!annotating && !cropping"
										:style="{
											width: isMobile ? '100%' : '65%',
											flex: 1,
											display: showCommentBox && isMobile ? 'none !important' : 'flex',
											backgroundColor: 'black',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											overflow: 'hidden',
											position: 'relative',
										}">
										<v-img
											:src="snapshot.src ?? snapshot.uploadResult?.signedUrl"
											:lazy-src="snapshot.thumbnail"
											:aspect-ratio="16 / 9"
											contain
											:max-height="800"
											style="width: 100%; height: 100%"
											:ref="`snapshot-${snapshot.id}`">
											<template v-slot:placeholder>
												<div class="d-flex align-center justify-center fill-height jerod-lb">
													<v-progress-circular
														color="grey-lighten-4"
														indeterminate></v-progress-circular>
												</div>
											</template>
										</v-img>
									</div>
									<!-- TODO: Find a better solution for the 1px margin. The black background from the left container is showing and looks like a border -->
									<!--  -->
									<div
										v-show="showCommentBox"
										v-if="currentSnapshotId === snapshot.id"
										class="comment-controls"
										:style="{
											width: showCommentBox && isMobile ? '90%' : '25%',
											height: showCommentBox && isMobile ? '100%' : 'auto',
										}"
										style="margin-left: 1px">
										<v-card class="desktop-right">
											<CommentBox
												class="pl-2 pr-2 pt-4"
												:parent-id="snapshot.id"
												:parent-type="'photo'"
												:project-id="projectId"
												:conversation-id="snapshot?.conversationId"
												:is-view-only="isViewOnly"
												:has-free-trial-ended="hasFreeTrialEnded"
												:light-box-view="true"
												:users="localUsers"
												:me="localMe"
												@toggle-reply-box="toggleReplyBox"
												@close-comment-box="startComment" />
										</v-card>
									</div>
								</div>
								<div
									v-if="isDesktop"
									class="d-flex flex-column pa-4"
									style="
										width: 90%;
										background-color: #fff;
										border-bottom-left-radius: 12px;
										border-bottom-right-radius: 12px;
									">
									<div
										class="d-flex flex-row align-center"
										v-if="isDesktop && getUserInitials() !== '-'">
										<v-avatar
											size="40"
											color="primary"
											class="team-avatar"
											:style="{ 'background-color': getUserAvatarBackgroundColor() }">
											{{ getUserInitials() }}
										</v-avatar>
										<h6 class="ml-2 chip-text">{{ user?.name }}</h6>
									</div>
									<div class="d-flex mt-2" style="color: rgba(0, 0, 0, 0.6)">
										{{ snapshotDate }}
									</div>
									<div
										v-if="currentSnapshot?.description"
										class="d-flex mt-2"
										style="color: rgba(0, 0, 0, 0.6)">
										{{ currentSnapshot?.description }}
									</div>
								</div>
							</div>
						</v-carousel-item>
					</v-carousel>
					<!-- Mobile Controls -->
					<div
						v-if="isMobile"
						class="d-flex flex-column"
						:class="{ 'px-4': !isLandscape }"
						:style="{ width: isLandscape ? '85%' : '100%' }">
						<div
							class="d-flex flex-column justify-space-between mobile-controls"
							:class="{ 'pt-2': isMobile && isLandscape }"
							:style="{
								display: isCommentBoxOpen && isMobile ? 'none !important' : 'flex',
							}">
							<div class="d-flex flex-row justify-space-between align-space-between">
								<div class="d-flex flex-row">
									<div>
										<v-btn elevation="0" large icon @click="startComment">
											<v-icon>icon-chat-stroke</v-icon>
										</v-btn>
									</div>
									<v-tooltip
										v-if="!isViewOnly"
										bottom
										content-class="lightbox-tooltip"
										:open-on-hover="!isTouch"
										:open-on-click="false"
										:open-on-focus="false">
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												class="pt-2"
												elevation="0"
												icon
												v-bind="attrs"
												v-on="on"
												@click="startAnnotation">
												<v-icon>icon-pencil-edit-stroke</v-icon>
											</v-btn>
										</template>
										<span>Annotate</span>
									</v-tooltip>
									<v-tooltip
										v-if="!isViewOnly"
										bottom
										content-class="lightbox-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="editSnapshot">
												<div class="icon pt-2">
													<img
														:src="require('@/assets/liveswitch-caption.svg')"
														alt="Processing" />
												</div>
											</v-btn>
										</template>
										<span>Add Caption</span>
									</v-tooltip>

									<v-tooltip
										v-if="!isViewOnly"
										bottom
										content-class="lightbox-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												class="pt-2"
												elevation="0"
												icon
												v-bind="attrs"
												v-on="on"
												@click="cropSnapshot">
												<v-icon>icon-crop</v-icon>
											</v-btn>
										</template>
										<span>Crop</span>
									</v-tooltip>
								</div>
								<div class="d-flex flex-row">
									<div>
										<v-tooltip bottom content-class="lightbox-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													elevation="0"
													large
													icon
													v-bind="attrs"
													v-on="on"
													@click="downloadSnapshot">
													<v-icon>icon-download</v-icon>
												</v-btn>
											</template>
											<span>Download</span>
										</v-tooltip>
									</div>
									<div v-if="!isViewOnly">
										<v-tooltip :disabled="isTouch" bottom content-class="lightbox-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													elevation="0"
													large
													icon
													v-bind="attrs"
													v-on="on"
													@click="() => deleteSnapshot(lightBoxIndex)">
													<v-icon color="red">icon-trash-full-stroke</v-icon>
												</v-btn>
											</template>
											<span>Delete</span>
										</v-tooltip>
									</div>
								</div>
							</div>
						</div>
						<div
							class="d-flex flex-column"
							:style="{
								backgroundColor: !isMobile ? '#fff' : 'transparent',
								borderRadius: isMobile ? '12px' : '0px 0px 12px 12px',
								display: isCommentBoxOpen && isMobile ? 'none !important' : 'flex',
							}">
							<div class="d-flex" style="color: rgba(0, 0, 0, 0.6)">
								{{ snapshotDate }}
							</div>
							<div class="d-flex mt-2" style="color: rgba(0, 0, 0, 0.6)">
								{{ currentSnapshot?.description }}
							</div>
						</div>
					</div>
					<!-- TODO: Fix/restyle/restructure comments to avoid using VW for width if possible -->
					<div
						v-if="isMobile && currentSnapshotId"
						v-show="isCommentBoxOpen"
						class="comment-controls"
						style="height: 100%">
						<v-card class="desktop-right">
							<!-- <v-icon @click="startComment">icon-close-circle-solid</v-icon> -->
							<CommentBox
								class="pl-2 pr-2 pt-4"
								:parent-id="currentSnapshotId"
								:is-view-only="isViewOnly"
								:parent-type="'photo'"
								:has-free-trial-ended="hasFreeTrialEnded"
								:conversation-id="currentSnapshot?.conversationId"
								:light-box-view="true"
								:project-id="projectId"
								:users="localUsers"
								:me="localMe"
								@toggle-reply-box="toggleReplyBox"
								@close-comment-box="startComment" />
						</v-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from "../api";
	import timezones from "@/helpers/timezones";
	import DeviceUtility from "@/helpers/device";
	import Cropper from "cropperjs";
	import { getSeededAvatarColor, getAgentNameInitials } from "@/helpers/utilities";
	import Snapshot from "../helpers/snapshot";
	import CommentBox from "./CommentBox.vue";
	import { blurActiveElement } from "../helpers/utilities";

	export default {
		components: {
			CommentBox,
		},
		props: {
			snapshots: {
				type: Array,
				required: true,
			},
			bulkSelect: {
				type: Boolean,
				required: false,
			},
			lightboxId: {
				type: String,
				required: false,
			},
			showGallery: {
				type: Boolean,
				default: true,
			},
			isViewOnly: {
				type: Boolean,
				default: false,
			},
			source: {
				type: String,
				default: "conversation",
			},
			projectId: {
				type: String,
				required: false,
			},
			parentId: {
				type: String,
				required: true,
			},
			users: {
				type: Array,
				default: () => [],
				required: false,
			},
			me: {
				type: Object,
				default: null,
				required: false,
			},
			hasFreeTrialEnded: {
				type: Boolean,
				required: true,
			},
		},
		data() {
			return {
				saving: false,
				editNotes: false,
				snapshotNotes: "",
				snapshotList: [],
				canSaveNotes: false,
				lightBoxIndex: null,
				lightBoxOpen: false,
				cropping: false,
				annotating: false,
				commenting: false,
				savingAnnotation: false,
				savingCrop: false,
				isTouch: false,
				dirty: false,
				isDesktop: this.$vuetify.breakpoint.mdAndUp,
				commentBoxStyle: {}, // { display: "none" },
				isLightboxThumbsOpen: false,
				isUnauthenticated: false,
				backgroundImage: "",
				getSeededAvatarColor,
				getAgentNameInitials,
				sourceCommentWidth: -1,
				backgroundImageCaption: {
					title: "",
					description: "",
				},
				isImageChanging: false,
				isReplyBoxOpen: false,
				showButtons: false,
				currentSnapshotId: null,
				currentSnapshot: null,
				localUsers: [],
				localMe: {},
				showThumbs: false,
				isMobile: this.$vuetify.breakpoint.smAndDown,
				isLandscape: window.matchMedia("(orientation: landscape)").matches,
				previousSnapshotIds: [],
				previousSnapshotId: null,
			};
		},
		async mounted() {
			window.addEventListener("resize", this.handleResize);
			if (this.$vuetify.breakpoint.mdAndUp) {
				window.addEventListener("mousemove", this.handleMouseMove);
			}
			this.isTouch = matchMedia("(hover: none), (pointer: coarse)").matches;
			this.snapshotList = this.snapshots;
			this.currentSnapshot = this.snapshotList.length > 0 ? this.snapshotList[0] : null;

			if (this.currentSnapshot) {
				this.currentSnapshotId = this.currentSnapshot.id;
			}

			const usersPromise = async () => {
				if (this.users.length == 0) {
					const localUsersResponse = await api.getUsers({ pageSize: 1000 });
					this.localUsers = localUsersResponse.rows;
				} else {
					this.localUsers = this.users;
				}
			};
			const mePromise = async () => {
				if (!this.me) {
					this.localMe = await api.getMe();
				} else {
					this.localMe = this.me;
				}
			};
			try {
				await Promise.all([usersPromise(), mePromise()]);
			} catch (error) {
				console.error("Error getting users or me", error);
			}
		},
		computed: {
			showCommentBox() {
				const showCommentBox =
					(this.$vuetify.breakpoint.mdAndUp || this.commenting) &&
					!this.annotating &&
					!this.cropping &&
					!this.isImageChanging;
				return showCommentBox;
			},
			showControls() {
				const showControls = !this.annotating && !this.cropping;
				return showControls;
			},
			snapshotDate() {
				return timezones.formatDate(this.currentSnapshot?.createdAt);
			},
			isCommentBoxOpen() {
				return this.commenting || this.$vuetify.breakpoint.mdAndUp;
			},
			snapshotId() {
				return this.currentSnapshotId;
			},
			user() {
				return this.currentSnapshot?.User;
			},
			actionClass() {
				if (this.cropping) {
					return "cropping";
				} else if (this.annotating) {
					return "annotating";
				} else {
					return "comment";
				}
			},
		},
		methods: {
			updateShowThumbs(showThumbs) {
				this.showThumbs = showThumbs;
			},
			getUserInitials() {
				return getAgentNameInitials(this.user?.name) ?? "-";
			},
			getUserAvatarBackgroundColor(item) {
				return getSeededAvatarColor(item?.agentName) + " !important";
			},
			closeLightBox() {
				this.commenting = false;
				this.annotating = false;
				this.cropping = false;
				this.editNotes = false;
				this.snapshotNotes = "";
				this.canSaveNotes = false;
				if (this.showThumbs) {
					this.toggleGallery();
				}
				this.$emit("close-lightbox");
			},
			toggleGallery() {
				this.$refs.thumbnails.toggleGallery();
				blurActiveElement();
			},
			downloadArtifacts(artifactIds) {
				this.$emit("download-artifacts", "snapshots", artifactIds);
			},
			deleteArtifacts(artifactIds, callback) {
				this.previousSnapshotIds = this.snapshotList.map((x) => x.id);
				const remainingSnapshots = this.snapshotList.filter((x) => !artifactIds.includes(x.id));
				this.previousSnapshotId = this.currentSnapshotId;
				const updatedCallBack = (deleted) => {
					if (deleted) {
						if (artifactIds.find((x) => x === this.previousSnapshotId)) {
							for (let i = 0; i < artifactIds.length; i++) {
								if (artifactIds[i] !== this.previousSnapshotId) {
									this.previousSnapshotIds = this.previousSnapshotIds.filter(
										(x) => x !== artifactIds[i]
									);
								}
							}
							let index = this.snapshotList.findIndex((x) => x.id === this.previousSnapshotId);
							if (index > -1) {
								this.snapshotList.splice(index, 1);
							}
							index = this.previousSnapshotIds.findIndex((x) => x === this.previousSnapshotId);
							this.previousSnapshotIds.splice(index, 1);

							if (index > 0) {
								index--;
							}
							if (this.previousSnapshotIds.length > 0 && this.snapshotList.length > 0) {
								this.currentSnapshotId = this.snapshotList[index].id;
								this.currentSnapshot = this.snapshotList[index];
							}
							this.previousSnapshotIds = [];
							this.previousSnapshotId = null;
						}
						if (callback) {
							callback();
						}
						if (remainingSnapshots.length === 0) {
							this.$emit("close-lightbox");
						}
					}
				};

				this.$emit("delete-artifacts", "snapshots", artifactIds, updatedCallBack);
			},
			snapshotChanged(id) {
				if (!id) {
					return;
				}
				this.currentSnapshot = this.snapshotList.find((x) => x.id == id);
				this.annotating = false;
				this.cropping = false;
				this.editNotes = false;
				this.snapshotNotes = "";
				this.canSaveNotes = false;
			},
			handleMouseMove() {
				if (this.showButtons) {
					return;
				}
				this.showButtons = true;
				setTimeout(() => {
					this.showButtons = true;
				}, 1000);
			},
			toggleReplyBox(isOpen) {},
			handleResize() {
				if (this.$refs.lightbox) {
					this.$refs.lightbox.showThumbs = false;
				}
				this.isLandscape = window.matchMedia("(orientation: landscape)").matches;
			},
			hideThumbnailTray() {
				this.$refs.lightbox.showThumbs = false;
			},
			setCurrentSnapshotId(id) {
				this.currentSnapshot = this.snapshotList.find((x) => x.id == id);
				this.currentSnapshotId = id;
			},
			openLightBox(id) {
				if (!id || !this.snapshotList || this.snapshotList.length === 0) {
					return;
				}
				this.lightBoxIndex = this.snapshotList.findIndex((s) => s.id === id);
				if (this.lightBoxIndex < 0) {
					return;
				}
				this.lightBoxOpen = true;
				this.onLightBoxChange(this.lightBoxIndex);

				if (DeviceUtility.isMobile || DeviceUtility.getDeviceType() === "tablet") {
					this.$refs.lightbox.canZoom = false;
				}

				// if we are in mobile then we have to close the thumbnail tray after selecting a new image
				if (this.isLightboxThumbsOpen && window.innerWidth < 960) {
					this.$refs.lightbox.showThumbs = false;
				}
			},
			async saveNotes(snapshotNotes) {
				const snapshot = this.currentSnapshot;

				let request = { notes: snapshotNotes };
				await api.updateSnapshot(snapshot.id, request);

				snapshot.description = snapshotNotes;
				this.editNotes = false;
				this.snapshotNotes = "";
				this.canSaveNotes = false;
			},
			onNotesChanged(notes) {
				const snapshot = this.currentSnapshot;
				this.canSaveNotes = notes != snapshot.description;
			},
			onLightBoxChange(index) {
				this.lightBoxIndex = index;
				this.editNotes = false;
				this.snapshotNotes = "";
				this.canSaveNotes = false;
				this.commentBoxStyle = { display: "none" };
			},
			startComment() {
				this.commenting = !this.commenting;
			},
			startAnnotation() {
				const currentImage = this.$refs[`snapshot-${this.currentSnapshotId}`][0].image;
				this.backgroundImage = currentImage;
				this.backgroundImageCaption.title = this.currentSnapshot.title;
				this.backgroundImageCaption.description = this.currentSnapshot.description;
				this.annotating = true;
			},
			async saveAnnotation(blob) {
				try {
					if (blob) {
						this.savingAnnotation = true;
						const snapshot = this.currentSnapshot;
						const annotatedSnapshot = await api.uploadSnapshot(snapshot.conversationId, blob, snapshot.id);
						this.annotating = false;
						let indexToPut = this.lightBoxIndex;
						while (indexToPut > 0 && this.snapshots[indexToPut - 1]?.parentId === snapshot.id) {
							indexToPut--;
						}

						const newAnnotatedSnapshot = {
							...annotatedSnapshot,
							id: annotatedSnapshot.id,
							conversationId: snapshot.conversationId,
							thumbnail: annotatedSnapshot.uploadResult.signedUrlThumbnail,
							src: annotatedSnapshot.uploadResult.signedUrl,
							User: snapshot.User,
							description: annotatedSnapshot.notes ?? "",
							title: timezones.formatDate(annotatedSnapshot.createdAt),
							createdAt: annotatedSnapshot.createdAt,
						};

						this.snapshotList.splice(indexToPut, 0, newAnnotatedSnapshot);
						this.currentSnapshot = newAnnotatedSnapshot;
						this.currentSnapshotId = newAnnotatedSnapshot.id;
					}
				} catch (e) {
					console.error(e);
				} finally {
					this.cancelAnnotation();
				}
			},

			cancelAnnotation() {
				this.annotating = false;
				this.savingAnnotation = false;
			},
			downloadSnapshot() {
				const snapshot = this.currentSnapshot;
				this.$emit("download-artifacts", "snapshots", [snapshot.id]);
			},
			editSnapshot() {
				const snapshot = this.currentSnapshot;
				this.snapshotNotes = snapshot.description;
				this.editNotes = false;
				this.$nextTick(() => {
					this.editNotes = true;
				});
			},
			async cropSnapshot() {
				try {
					this.cropper.destroy();
				} catch (error) {
					// brute force
				}
				this.cropper = {};

				const currentImage = this.$refs[`snapshot-${this.currentSnapshotId}`][0].image;
				const image = await Snapshot.prepareForCropping(currentImage);
				const checkCrossOrigin = true;
				const wrapper = this.$refs.cropContainer;
				wrapper.appendChild(image);
				this.cropper = new Cropper(image, {
					scalable: true,
					checkCrossOrigin: checkCrossOrigin,
					checkOrientation: false,
					viewMode: 1,
					ready: function () {
						this.cropper.setCropBoxData(this.cropBoxData).setCanvasData(this.canvasData);
					},
				});
				this.cropping = true;
			},
			cancelCrop() {
				try {
					this.cropping = false;
					const wrapper = this.$refs.cropContainer;
					wrapper.innerHTML = "";
					try {
						this.cropper.destroy();
					} catch (error) {
						// brute force
					}
					this.savingCrop = false;
				} catch (err) {
					console.error(err);
				}
			},
			saveCrop() {
				const snapshot = this.currentSnapshot;
				this.savingCrop = true;
				this.cropper.getCroppedCanvas().toBlob(async (blob) => {
					if (blob == null) {
						await this.$root.$confirm.open(
							"Error",
							`The cropped snapshot is too small.`,
							{},
							false,
							"Close",
							null,
							true,
							true,
							true
						);
						this.savingCrop = false;
						return;
					}
					const cropped = await api.uploadSnapshot(snapshot.conversationId, blob, snapshot.id);
					let indexToPut = this.lightBoxIndex;
					while (indexToPut > 0 && this.snapshots[indexToPut - 1]?.parentId === snapshot.id) {
						indexToPut--;
					}

					const newSnapshot = {
						...cropped,
						id: cropped.id,
						thumbnail: cropped.uploadResult.signedUrlThumbnail,
						conversationId: snapshot.conversationId,
						src: cropped.uploadResult.signedUrl,
						User: snapshot.User,
						description: cropped.notes ?? "",
						title: timezones.formatDate(cropped.createdAt),
						createdAt: cropped.createdAt,
					};

					this.snapshotList.splice(indexToPut, 0, newSnapshot);
					this.currentSnapshot = newSnapshot;
					this.currentSnapshotId = newSnapshot.id;
					this.cancelCrop();
				});
			},
			async deleteSnapshot() {
				let index = this.snapshotList.indexOf(this.currentSnapshot);
				if (await this.$root.$confirm.deleteOpen("Delete Photo", `This will delete the photo.`)) {
					await api.deleteSnapshot(this.currentSnapshot.id);
					this.$emit("delete-snapshot", this.currentSnapshot.id);
					this.snapshotList.splice(index, 1);
					if (this.snapshotList.length > 0) {
						if (index > 0) {
							index -= 1;
						}

						this.currentSnapshot = this.snapshotList[index];
						this.currentSnapshotId = this.currentSnapshot.id;
					} else {
						this.$emit("close-lightbox");
					}
				}
			},
			reset() {
				this.$router.push({ name: "snapshots" });
			},
			save() {
				this.saving = true;
				this.$store.dispatch("saveSnapshots", this.snapshotList).then(() => {
					this.saving = false;
					this.dirty = false;
				});
			},
		},
		watch: {
			snapshots: {
				async handler(newVal) {
					this.snapshotList = newVal;
				},
				deep: true,
			},
			isLightboxThumbsOpen: {
				handler(newVal) {
					this.$emit("update:isLightboxThumbsOpen", newVal);
				},
			},
			lightboxId: {
				handler(newVal) {
					this.openLightBox(newVal);
				},
			},
		},
	};
</script>

<style scoped>
	.mobile-recording-carousel {
		height: 50%;
	}
	:deep(.v-window__container) {
		width: 100%;
	}
	:deep(.mobile-recording-carousel .v-window__container) {
		height: 100%;
		width: 100%;
	}
	:deep(.mobile-recording-carousel .v-window__container .v-carousel__item) {
		height: 100%;
	}
	.mobile-controls {
		width: 100%;
	}
	.snapshot-container {
		background-color: #000;
		border-radius: 12px 0px 0px 0px;
	}

	.lightbox-header {
		height: 72px;
	}

	.cropper-dashed {
		border: 0 solid #eeeeeecc;
	}

	.cropper-view-box {
		outline: 1px solid #fff;
	}

	.cropper-point,
	.cropper-point.point-se {
		background-color: #fff;
		height: 15px;
		width: 15px;
		opacity: 1;
		border-radius: 15px;
	}

	.cropper-point.point-n,
	.cropper-point.point-w,
	.cropper-point.point-s,
	.cropper-point.point-e {
		display: none;
	}

	.cropper-point.point-ne {
		right: -7px;
		top: -7px;
	}

	.cropper-point.point-se {
		right: -7px;
		bottom: -7px;
	}

	.cropper-point.point-nw {
		left: -7px;
		top: -7px;
	}

	.cropper-point.point-sw {
		left: -7px;
		bottom: -7px;
	}

	.cropper-bg {
		background-image: none;
	}

	.cropper-container {
		height: 100% !important;
		margin-left: 30px !important;
	}

	.crop-controls {
		position: fixed;
		width: 50%;
		max-height: calc(100vh - 20%);
		height: 100%;
		display: flex;
		flex-direction: column;
		z-index: 10000;
		left: 25%;
		top: 10%;
		background-color: #fff;
		padding: 32px 32px 15px;
		border-radius: 8px;
	}

	.annotation-controls {
		position: fixed;
		width: calc(100vw - 25%);
		height: calc(100vh - 25%);
		display: flex;
		flex-direction: column;
		z-index: 10000;
		left: 12.5%;
		top: 12.5%;
		padding: 32px;
	}

	.crop-controls.thumbs-open,
	.annotation-controls.thumbs-open {
		width: calc(100vw - 320px - 5%) !important; /* 320px is the width of the snapshot tray */
		left: calc(320px + 2.5%) !important;
		max-width: calc(100vw - 25%) !important;
	}

	.annotation-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		z-index: 9995;
	}

	.annotation-controls .v-overlay {
		z-index: 10005;
		margin: 32px;
		border-radius: 12px;
	}

	.cropper-modal {
		background-color: #fff;
	}

	#crop-container {
		max-height: 65vh;
		height: 100%;
	}

	.crop-controls > div:nth-child(3) {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}

	.crop-controls > div:nth-child(3) > button:first-child {
		color: #e33154;
	}

	.crop-controls > div:nth-child(3) > button:nth-child(2) {
		color: #0070ff;
	}

	.edit-notes {
		width: 470px;
		max-width: calc(100% - 32px);
		border-radius: 12px;
		margin: 0 auto;
	}

	.edit-notes textarea {
		resize: none;
	}

	.edit-notes-controls {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.edit-notes-controls > button:first-child {
		margin-right: 15px;
		color: #1e2128;
	}

	.team-avatar {
		display: flex;
		justify-content: center;
		font-size: 1rem;
		color: #fff;
		text-transform: uppercase;
	}

	.comment-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		pointer-events: none;
	}

	.add-comment-card {
		position: relative;
		bottom: 0;
	}
	.lightbox-controls {
		position: fixed;
		width: 100%;
		display: flex;
		z-index: 10000;
		left: 0%;
	}

	.lightbox-access {
		position: fixed;
		display: flex;
		z-index: 10000;
		right: 15%;
		top: 2%;
		color: #000;
	}

	.theme--light.v-btn.v-btn--icon {
		color: #000;
	}

	.lightbox-controls > button.theme--light.v-btn.v-btn--icon {
		color: black;
		border-radius: 100px;
		height: 40px;
		width: 40px;
		margin: 10px 3px;
		z-index: 1000000;
	}

	.lightbox-controls > button.theme--light.v-btn.v-btn--icon:last-child {
		color: #ec5d43;
	}

	.lightbox-tooltip {
		font-size: 10px;
		line-height: 12px;
		padding: 5px 8px;
	}

	.snapshots.fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10010;
		width: 280px;
		height: 100vh;
		overflow-y: scroll;
	}

	.snapshots.fixed button {
		background-color: #1e2128 !important;
		color: #fff;
		font-size: 12px;
	}

	.snapshots.fixed > .snapshot-controls {
		flex-direction: column;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button {
		background-color: #000 !important;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button:first-child {
		color: #fff !important;
	}

	@media (min-width: 960px) {
		.lightbox-access {
			right: 7% !important;
		}

		.desktop-right {
			overflow-y: auto;
			border-radius: 0px 12px 0px 0px !important;
			filter: unset !important;
			height: 100%;
			border-left: #fff;
			box-shadow: unset !important;
		}

		.lightbox-controls {
			height: 70px;
			width: 90%;
			margin-left: 5%;
			align-items: center;
		}

		.comment-controls {
			width: 350px !important;
			right: 6% !important;
			top: 11vh !important;
		}

		.comment-box {
			max-height: 100% !important;
		}
	}

	.comment-controls {
		/* position: fixed; */
		width: 90%;
		height: 100%;
		/* height: 75vh;
		top: 11vh;
		right: 3%; */
		display: flex;
		flex-direction: column;
		z-index: 10000;
	}

	.comment-controls .v-overlay {
		z-index: 10005;
		margin: 32px;
		border-radius: 12px;
	}

	/* this section is when the nav bar moves to the bottom of the screen */
	@media (max-width: 959px) {
		.snapshot-container {
			border-radius: 0px;
		}
		@media (orientation: landscape) {
			.mobile-recording-carousel {
				height: 100%;
			}
			:deep(.v-window__prev),
			:deep(.v-window__next) {
				top: 75% !important;
			}
		}
		:deep(.v-window__prev),
		:deep(.v-window__next) {
			top: 85%;
		}
		.annotation-controls {
			width: calc(100vw - 10%);
			left: 5%;
			padding: 10px;
		}

		.annotation-controls .v-overlay {
			margin: 10px !important;
		}

		.theme--light.v-btn.v-btn--icon {
			color: #000;
		}

		.lightbox-controls {
			justify-content: space-between;
			top: 32%;
			padding-left: 16px;
			padding-right: 16px;
		}

		.desktop-right {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			border-radius: 0px 0px 0px 0px !important;
		}

		html {
			-ms-overflow-style: none;
			/* IE and Edge */
			scrollbar-width: none;
			max-width: 100vw !important;
			overflow: hidden !important;
			overflow-y: auto !important;
		}
		html ::-webkit-scrollbar {
			display: none;
		}
		.comment-controls {
			width: 100vw;
			right: 0vw;
			height: calc(100% - 86px);
			bottom: 0px;
		}

		.comment-controls .v-overlay {
			margin: 10px !important;
		}

		.snapshots.fixed {
			width: 100%;
			z-index: 10009;
		}
	}
	@media (max-width: 959px) and (orientation: landscape) {
		.annotation-controls {
			height: 100%;
			top: 0;
		}
	}
</style>
