import { ArtifactType } from "@/enums/ArtifactType";
import api from "@/api";

interface Artifact {
	id: string;
	conversationId?: string;
}

interface ArtifactTypeConfig {
	singularName: string;
	pluralName: string;
}

const artifactConfigs: Record<ArtifactType, ArtifactTypeConfig> = {
	[ArtifactType.Snapshots]: {
		singularName: "photo",
		pluralName: "photos",
	},
	[ArtifactType.Files]: {
		singularName: "document",
		pluralName: "documents",
	},
	[ArtifactType.Recordings]: {
		singularName: "video",
		pluralName: "videos",
	},
};

export function getArtifactTypeConfig(artifactType: ArtifactType): ArtifactTypeConfig {
	return artifactConfigs[artifactType];
}

export async function deleteArtifacts(
	artifactType: ArtifactType,
	selectedIds: string[],
	artifacts: Artifact[]
): Promise<Artifact[]> {
	if (selectedIds.length === 0) {
		return artifacts;
	}

	const artifactsToDelete = artifacts
		.filter((x) => selectedIds.includes(x.id))
		.map((x) => ({ id: x.id, conversationId: x.conversationId }));

	await api.deleteArtifacts(artifactType, artifactsToDelete);
	return artifacts.filter((x) => !selectedIds.includes(x.id));
}
