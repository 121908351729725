<template>
	<div>
		<v-autocomplete
			:autocomplete="autocomplete"
			:dense="!compact"
			hide-details
			outlined
			ref="projectSearch"
			:label="compact ? 'Project' : 'Project (Optional)'"
			auto-select-first
			:search-input.sync="search"
			:no-data-text="search ? 'No projects found.' : 'Start typing to find a project.'"
			:placeholder="placeholder"
			counter="255"
			:maxLength="255"
			:disabled="hasFreeTrialEnded"
			v-model="localSelectedProject"
			item-text="name"
			item-value="id"
			return-object
			:items="projectItems"
			@blur="projectSearchBlur"
			@focus="projectFieldFocus"
			clearable
			:menu-props="{
				maxWidth: projectsMenuWidth,
				contentClass: 'projects-menu-content',
			}"
			:loading="projectsLoading"
			attach
			no-filter>
			<template v-slot:selection="{ item }">
				<div class="text-truncate">{{ item.name }}</div>
			</template>
			<template v-slot:item="{ item }">
				<v-list-item-content v-if="!item.edit">
					<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
				</v-list-item-content>
			</template>
			<!-- When there are no items, show a create button -->
			<template v-slot:no-data>
				<div class="d-flex align-center justify-center no-data-slot pb-2" v-if="search">
					<v-btn
						@click="createNewProject"
						id="create-project-button"
						class="elevation-0 text-none create-project-btn"
						color="transparent">
						Create project '<span class="text-truncate mw-50">{{ search }}</span
						>'
					</v-btn>
				</div>
			</template>

			<!-- Append an extra item always at the bottom if conditions are met -->
			<template v-slot:append-item>
				<v-list-item
					v-if="shouldShowCreateButton"
					class="create-project-fixed pa-0"
					@click="createNewProject"
					:key="'create-btn'">
					<v-list-item-content class="text-center pt-0 pb-2">
						<hr class="mb-2" />
						<v-btn
							class="elevation-0 pa-2 text-none create-project-btn"
							color="transparent"
							id="create-project-button">
							Create project '<span class="text-truncate mw-50">{{ search }}</span
							>'
						</v-btn>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-autocomplete>
		<CreateProjectDialog ref="createProjectDialog" />
	</div>
</template>

<script>
	import { hasFreeTrialEnded } from "@/helpers/hasFreeTrialEnded";
	import api from "../api";
	import CreateProjectDialog from "./CreateProjectDialog.vue";
	import { AccessType } from "@/enums/AccessType";

	export default {
		components: {
			CreateProjectDialog,
		},
		props: {
			selectedProject: {
				type: Object,
				required: false,
			},
			compact: {
				type: Boolean,
				default: false,
			},
			placeholder: {
				type: String,
				default: "Start typing to find a project",
			},
		},
		data() {
			return {
				filteredProjectItems: [],
				autocomplete: Math.random(),
				me: null,
				search: "",
				projectsLoading: false,
				hasFreeTrialEnded: false,
				isTouch: false,
				projectsMenuWidth: 0,
				projectsFieldIsFocused: false,
				dropdownVisible: false,
			};
		},
		computed: {
			localSelectedProject: {
				get() {
					return this.selectedProject;
				},
				set(newValue) {
					this.$emit("change", newValue);
					this.$emit("update:selectedProject", newValue);
				},
			},
			projectItems() {
				if (this.filteredProjectItems.length > 0) {
					return this.filteredProjectItems;
				} else {
					return [this.selectedProject];
				}
			},
			shouldShowCreateButton() {
				if (!this.search || this.filteredProjectItems.filter(Boolean).length == 0) return false;
				const exactMatch = this.filteredProjectItems
					.filter(Boolean)
					.some((item) => item.name.trim().toLowerCase() === this.search.toLowerCase());
				return !exactMatch;
			},
		},
		async mounted() {
			this.isTouch = matchMedia("(hover: none), (pointer: coarse)").matches;
			this.me = await api.getMe();
			if (await hasFreeTrialEnded(this.me.organization)) {
				this.hasFreeTrialEnded = true;
			}
			window.addEventListener("resize", this.adjustMenusWidths);
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.adjustMenusWidths);
		},
		watch: {
			async search(val) {
				if (!this.projectsFieldIsFocused) {
					return;
				}
				this.projectsLoading = true;
				val = val?.trim();
				this.search = val;
				clearTimeout(this._searchTimerId);
				this._searchTimerId = setTimeout(async () => {
					await this.performSearch(val);
				}, 500);
			},
		},
		methods: {
			async performSearch(val) {
				if (!this.me) {
					// we still want an initial set on load, but we need the me object to be loaded
					setTimeout(() => {
						this.performSearch(val);
					}, 1000);
					return;
				}
				// get projects matching search
				const projectItems = await api.getProjects({
					where: {
						name: { $ilike: `%${val ?? ""}%` },
					},
					pageSize: 10,
				});
				// get shares
				const projectShares = await api.getProjectShares({
					pageSize: 1000, // do we need this? we limit by project id, not sure if we can have more shares than projects
					where: {
						accessType: AccessType.Editor,
						userIdentifier: { $or: [this.me.account.id, this.me.user.email] },
						projectId: { $in: projectItems.rows.map((project) => project.id) },
					},
				});
				this.filteredProjectItems = projectItems.rows.filter((project) => {
					return projectShares.rows.some((share) => share.projectId === project.id);
				});
				this.filteredProjectItems.push(this.localSelectedProject);
				this.projectsLoading = false;
			},
			createNewProject() {
				const newProjectName = this.search;
				this.$refs.createProjectDialog.open(newProjectName).then((record) => {
					if (record) {
						this.filteredProjectItems.push(record);
						this.localSelectedProject = record;
						this.projectSearchBlur();
					}
				});
			},
			projectSearchBlur() {
				this.$refs?.projectSearch?.blur();
				this.projectsFieldIsFocused = false;
				this.search = "";
				if (this.$refs.projectSearch.internalSearch) {
					this.$refs.projectSearch.internalSearch = "";
				}
			},
			isAdmin() {
				if (this.me?.account.roles[0].name.toLowerCase() === "user") {
					return false;
				}
				return true;
			},
			adjustMenusWidths() {
				this.projectsMenuWidth = this.$refs.projectSearch?.$el.getBoundingClientRect().width + "px";
			},
			async projectFieldFocus() {
				this.projectsFieldIsFocused = true;
				this.adjustMenusWidths();
				await this.performSearch();
			},
		},
	};
</script>

<style scoped>
	.create-project-fixed {
		position: sticky !important;
		bottom: 0;
		background-color: white;
	}

	.create-project-btn:hover::before,
	.create-project-fixed:hover::before {
		opacity: 0 !important;
	}

	.create-project-btn {
		white-space: normal;
		overflow-wrap: break-word;
	}

	.mw-50 {
		max-width: 50% !important;
	}

	.create-project-btn,
	.create-project-btn::v-deep .v-btn__content {
		width: 100% !important;
	}

	.projects-menu-content .v-select-list {
		padding-bottom: 0;
	}
</style>
