<template>
	<v-menu
		v-model="dialog"
		content-class="fab-action-menu fab-action-height"
		class="fab-action-menu"
		:offset-y="true"
		:close-on-content-click="false"
		:close-on-click="false"
		tile
		persistent
		:z-index="50">
		<div class="pa-4" style="background-color: white">
			<div class="d-flex align-center justify-space-between pb-4">
				<div class="text-h5" v-if="remainingFiles.length > 0">
					<h5>Uploading {{ remainingFiles.length }} item{{ remainingFiles.length === 1 ? "" : "s" }}</h5>
				</div>
				<div class="text-h5" v-else>
					<h5>Uploaded {{ fileList.length }} item{{ fileList.length === 1 ? "" : "s" }}</h5>
				</div>
				<v-icon @click="closeDialog" class="text-h5">icon-close-medium</v-icon>
			</div>
			<div class="d-flex flex-column" style="gap: 16px;">
				<template v-for="(file, index) in fileList">
					<div :key="index" class="rounded-xl pa-2" style="border: 1px solid black;">
						<div class="d-flex flex-column align-start">
							<div class="d-flex align-center width-100">
								<v-icon class="mr-2" :size="40">{{ getIcon(file.type) }}</v-icon>
								<div class="d-flex flex-column align-start mb-1 file-info-container">
									<span class="mr-2 max-width-100 text-truncate">{{ file.name }}</span>
									<span class="mr-2 text-subtitle-2">{{ getSizeInMB(file.size) }} MB</span>
								</div>
							</div>
							<v-progress-linear v-if="file.progress < 100" indeterminate color="green"/>
						</div>
						<div class="d-flex align-center justify-start"></div>
					</div>
				</template>
			</div>
		</div>
	</v-menu>
</template>

<script lang="ts">
	import api from "@/api";
	interface FileWithProgress extends File {
		progress: number;
	}
	export default {
		data() {
			return {
				dialog: false as boolean,
				fileList: [] as FileWithProgress[],
				files: [] as File[],
			};
		},
		props: {},
		computed: {
			remainingFiles() {
				return this.fileList.filter((file: FileWithProgress) => file.progress < 100);
			},
		},
		methods: {
			updateProgress(file: FileWithProgress) {
				this.$set(file, "progress", Math.min(file.progress + 1, 95));
			},
			getSizeInMB(size: number) {
				return (size / 1024 / 1024).toFixed(2);
			},
			async openDialog(files: File[], conversationId: string, conversationName: string) {
				this.files = files;
				this.fileList = files.map((file: File) => ({
					...file,
					progress: 0,
					name: file.name,
					size: file.size,
					type: file.type,
					lastModified: file.lastModified,
					mimeType: file.type,
				}));
				let uploadResults: any[] = [];
				if (this.fileList.length > 0) {
					this.dialog = true;
					const uploadPromises = this.fileList.map(async (file: FileWithProgress) => {
						const result = await this.uploadFile(file, conversationId, conversationName);
						this.$set(file, "progress", 100);
						uploadResults.push({ ...result, fileType: file.type });
					});

					const progressInterval = setInterval(() => {
						this.fileList.forEach((file: FileWithProgress) => {
							if (file.progress < 95) {
								this.updateProgress(file);
							}
						});
					}, 50);

					await Promise.all(uploadPromises).finally(() => {
						clearInterval(progressInterval);
					});
				}
				return uploadResults;
			},
			getIcon(type: string) {
				if (type.startsWith("image/")) {
					return "icon-image";
				} else if (type.startsWith("video/")) {
					return "icon-video-solid";
				} else {
					return "icon-file-blank-solid";
				}
			},
			closeDialog() {
				this.dialog = false;
				this.fileList = [];
				this.files = [];
			},
			async uploadFile(file: FileWithProgress, conversationId: string, conversationName: string) {
				const fileToUpload = this.files.find((f: File) => f.name === file.name);
				return await api.uploadFiles(conversationId, fileToUpload, null, {
					conversationId: `${conversationId}`,
					description: `${conversationName}`,
				});
			},
		},
	};
</script>

<style scoped>
	.fab-action-height {
		overflow-y: auto;
	}
	.v-application .v-menu__content.fab-action-menu {
		position: fixed !important;
		bottom: 60px;
		right: 12px;
		z-index: 49 !important;
		top: unset !important;
		left: unset !important;
		width: calc(100% - 20px) !important;
		max-width: 500px !important;
		max-height: 500px;
		border-radius: 16px !important;
		background-color: #f5f5f5;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.width-100 {
		width: 100% !important;
	}
	.max-width-100 {
		max-width: 100% !important;
		width: 100% !important;
	}
	.file-info-container {
		min-width: 0; /* This is crucial for text-truncate to work */
		flex: 1;
	}
</style>