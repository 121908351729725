<template>
  <div class="clipboard-toast">
    <p>Oops! We couldn't copy the video link to your clipboard. You can still copy it manually:</p>
    <v-text-field
      :value="url"
      outlined
      dense
      readonly
      class="clipboard-text-field"
      ref="clipboardTextInputField"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      try {
        // Attempt to select the text inside of the toast. This is a nice to have (if it fails not a big deal).
        const inputElement = this.$refs.clipboardTextInputField.$refs.input;
        inputElement.select();
      } catch (_) {
        // Empty on purpose.
      }
      
    });
  },
};
</script>

<style scoped>
.clipboard-toast {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.clipboard-text-field {
    width: 100%;
}

.clipboard-toast .clipboard-text-field  ::v-deep(.v-input__control > .v-input__slot) {
  background-color: #fff !important;
}

.clipboard-toast .clipboard-text-field  ::v-deep(fieldset) {
  border-color: #888888 !important;
}

.clipboard-text-field ::v-deep(input[type="text"]) {
  color: #888888 !important;
}
</style>