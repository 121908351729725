<template>
	<v-app>
		<v-overlay :value="!subscriptionReady">
			<div class="text-center">
				<p>Please wait while we retrieve your account information.</p>
				<v-progress-circular indeterminate color="primary"> </v-progress-circular>
			</div>
		</v-overlay>
		<main class="main d-flex align-center">
			<v-row>
				<v-col cols="4" offset="4">
					<div class="text-center">
						<img
							src="https://assets.liveswitch.com/shared/liveswitch-logotype.svg"
							alt="LiveSwitch"
							height="72" />
					</div>

					<div v-if="!subscriptionNotReady">
						<h6 class="text-center mt-2 mb-2">Set your password.</h6>

						<v-form ref="form">
							<v-text-field
								type="email"
								outlined
								v-model="email"
								hide-details="auto"
								readonly
								disabled
								class="field-border-radius"
								placeholder="Email Address"></v-text-field>

							<v-text-field
								type="password"
								outlined
								v-model="password"
								hide-details="auto"
								:rules="passwordRules"
								class="field-border-radius"
								autocomplete="new-password"
								placeholder="Password"></v-text-field>

							<v-text-field
								type="password"
								outlined
								v-model="confirmPassword"
								hide-details="auto"
								:rules="confirmPasswordRules"
								class="field-border-radius"
								autocomplete="new-password"
								placeholder="Confirm Password"></v-text-field>

							<div v-if="!accountSetupComplete">
								<v-btn
									class="w-100"
									color="primary"
									:disabled="!valid"
									:loading="loading"
									@click="completeAccountSetup"
									>Submit</v-btn
								>

								<div v-show="false">
									<p class="text-center mt-4 mb-4" v-if="subscriptionInfo?.oAuthProviders">Or</p>

									<v-btn v-for="provider in subscriptionInfo?.oAuthProviders" class="btn-sso">
										<div class="d-flex align-center mr-2">
											<img :src="`/assets/sso/${provider.name}.svg`" :alt="provider.name" />
										</div>
										<div class="name">Sign in with {{ provider.name }}</div>
									</v-btn>
								</div>
							</div>
						</v-form>
					</div>
					<div v-else>
						<h6 class="text-center mt-4">
							Your account is still being set up. You will receive an email once your account has been set
							up.
						</h6>
					</div>
				</v-col>
			</v-row>
			<v-snackbar id="snackbar" v-model="showSnackbar" top :timeout="snackbarTimeout">
				{{ message }}
			</v-snackbar>
		</main>
	</v-app>
</template>

<script lang="ts" setup>
	import { onMounted, ref, computed, getCurrentInstance, watch } from "vue";
	import { setupAccount, getSubscriptionInfo } from "./api/payments";
	import { SubscriptionInfo } from "./types/payments/SubscriptionInfo";
	import { AccountSetupRequest } from "./types/payments/AccountSetupRequest";
	import { AccountSetupResponse } from "./types/payments/AccountSetupResponse";

	const showSnackbar = ref<boolean>(false);
	const snackbarTimeout = ref<number>(5000);
	const instance = ref();
	const message = ref<string>("");
	const email = ref<string>("");
	const password = ref<string>("");
	const confirmPassword = ref<string>("");
	const params = ref<URLSearchParams>();
	const form = ref<any>();
	const subscriptionMaxChecks = 4;
	const subscriptionChecks = ref<number>(0);
	const subscriptionInfo = ref<SubscriptionInfo>();
	const subscriptionReady = ref<boolean>();
	const subscriptionNotReady = ref<boolean>();
	const accountSetupComplete = ref<boolean>();
	const loading = ref<boolean>();

	const valid = computed(() => {
		return email.value && password.value && confirmPassword.value && password.value === confirmPassword.value;
	});

	watch(password, (newPassword, oldPassword) => {
		if (confirmPassword.value) {
			(form.value as any).validate();
		}
	});

	watch(confirmPassword, (newPassword, oldPassword) => {
		(form.value as any).validate();
	});

	const passwordRules = ref<Array<any>>([
		(v) => !!v || "Password is required.",
		(v) =>
			/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d\S]{8,}$/.test(v) ||
			"The password must be at least 8 characters long and include 1 uppercase character, 1 number, and may contain special characters.",
		(v) => v.length < 50 || "Password must be less than 50 characters long.",
	]);

	const confirmPasswordRules = ref<Array<any>>([(v) => v == password.value || "The passwords must match."]);

	onMounted(async () => {
		instance.value = getCurrentInstance();
		params.value = new URLSearchParams(window.location.search);
		await getCheckoutDetails();
	});

	async function getCheckoutDetails(): Promise<void> {
		const provider: string = params.value?.get("provider") ?? "Stripe";
		const sessionId: string = params.value?.get("checkoutSessionId") ?? "";
		const subscription: SubscriptionInfo | null = await getSubscriptionInfo(provider, sessionId);

		if (subscription && !subscription.error) {
			subscriptionInfo.value = subscription;
			email.value = subscription.email;

			// If the user already completed their account setup, redirect them to instead of prompting to set up again.
			// This is the scenario where they set up their account and click on the set up link in the welcome email.

			if (subscription.state === "Complete" && !!subscription.redirectUrl) {
				window.location.href = subscription.redirectUrl;
				return;
			}
		}

		if (subscription && subscription.state !== "Pending") {
			if (subscription.state === "New" && subscriptionChecks.value < subscriptionMaxChecks) {
				setTimeout(async () => {
					subscriptionChecks.value++;
					await getCheckoutDetails();
				}, 2500);

				return;
			} else {
				subscriptionNotReady.value = true;
			}
		}

		subscriptionReady.value = true;
	}

	async function completeAccountSetup(): Promise<void> {
		loading.value = true;
		const request: AccountSetupRequest = {
			subscriptionTransactionId: subscriptionInfo.value?.subscriptionTransactionId,
			password: password.value,
			confirmPassword: password.value,
		};

		const response: AccountSetupResponse = await setupAccount(request);

		if (!response.error) {
			accountSetupComplete.value = true;
			message.value = "Your account has been set up successfully. You will be redirected shortly.";
			showSnackbar.value = true;
			setTimeout(() => {
				if (subscriptionInfo.value?.redirectUrl) {
					window.location.href = response.redirectUrl;
				}
			}, snackbarTimeout.value);
		} else {
			message.value = response.message;
			showSnackbar.value = true;
		}

		loading.value = false;
	}
</script>

<style>
	input::-ms-input-placeholder {
		color: #606060 !important;
	}

	input::-webkit-input-placeholder {
		color: #606060 !important;
	}

	input::placeholder {
		color: #606060 !important;
	}
</style>

<style lang="scss" scoped>
	#app {
		display: block !important;
	}

	nav .v-icon {
		padding-right: 8px;
		font-size: 18px;
	}

	.action {
		cursor: pointer;
	}

	.main {
		height: 100vh;
	}

	.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
		background-color: #0070ff !important;
		opacity: 0.24;
	}
	.theme--light.v-btn.v-btn--disabled {
		color: white !important;
	}
	.theme--light.v-btn.v-btn--disabled .v-icon,
	.theme--light.v-btn.v-btn--disabled .v-btn__loading {
		color: white !important;
	}

	.v-btn {
		width: 100%;
		height: 48px !important;
		border-radius: 12px;
	}

	.btn-sso {
		background-color: #fff !important;
	}
</style>
