<template>
	<div id="draw-div">
		<v-overlay :absolute="true" :opacity="0.5" :value="!canvasReady">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
			Loading...
		</v-overlay>
		<div id="draw-actions">
			<div>
				<v-btn :class="{ selected: selectedTool === 'circle' }" elevation="0" icon @click="circle">
					<div>
						<img
							:src="
								selectedTool === 'circle'
									? require('@/assets/liveswitch-draw-circle-selected.svg')
									: require('@/assets/liveswitch-draw-circle.svg')
							"
							alt="Processing" />
					</div>
				</v-btn>
				<span class="icon-label">Circle</span>
			</div>

			<div>
				<v-btn :class="{ selected: selectedTool === 'square' }" elevation="0" icon @click="square">
					<div>
						<img
							:src="
								selectedTool === 'square'
									? require('@/assets/liveswitch-draw-square-selected.svg')
									: require('@/assets/liveswitch-draw-square.svg')
							"
							alt="Processing" />
					</div>
				</v-btn>
				<span class="icon-label">Square</span>
			</div>

			<div>
				<v-btn :class="{ selected: selectedTool === 'line' }" elevation="0" icon @click="line">
					<div>
						<img
							:src="
								selectedTool === 'line'
									? require('@/assets/liveswitch-draw-line-selected.svg')
									: require('@/assets/liveswitch-draw-line.svg')
							"
							alt="Processing" />
					</div>
				</v-btn>
				<span class="icon-label">Line</span>
			</div>

			<div>
				<v-btn :class="{ selected: selectedTool === 'freehand' }" elevation="0" icon @click="freeHand">
					<div>
						<img
							:src="
								selectedTool === 'freehand'
									? require('@/assets/liveswitch-draw-freehand-solid-selected.svg')
									: require('@/assets/liveswitch-draw-freehand-solid.svg')
							"
							alt="Processing" />
					</div>
				</v-btn>
				<span class="icon-label">Freehand</span>
			</div>

			<div>
				<v-btn :disabled="linesStack.length == 0" elevation="0" icon @click="undoDraw">
					<div>
						<img
							:src="
								linesStack.length == 0
									? require('@/assets/liveswitch-undo-icon-disabled.svg')
									: require('@/assets/liveswitch-undo-icon.svg')
							"
							alt="Processing" />
					</div>
				</v-btn>
				<span :class="{ 'icon-label': true, enabled: linesStack?.length > 0 }">Undo</span>
			</div>

			<div>
				<v-btn :disabled="removedLinesStack.length == 0" elevation="0" icon @click="redoDraw">
					<div>
						<img
							:src="
								removedLinesStack.length == 0
									? require('@/assets/liveswitch-redo-icon-disabled.svg')
									: require('@/assets/liveswitch-redo-icon.svg')
							"
							alt="Processing" />
					</div>
				</v-btn>
				<span :class="{ 'icon-label': true, enabled: removedLinesStack?.length > 0 }">Redo</span>
			</div>
		</div>

		<div id="draw-wrapper">
			<div class="image-container">
				<img id="imageRef" :src="backgroundImage.src" />
			</div>
			<div id="draw-container"></div>
		</div>

		<div id="draw-footer">
			<div id="draw-footer-caption">
				<p v-if="backgroundImageCaption.title">
					{{ backgroundImageCaption.title }}
				</p>
				<p v-if="backgroundImageCaption.description">
					{{ backgroundImageCaption.description }}
				</p>
			</div>
			<div id="draw-footer-buttons">
				<v-btn elevation="0" @click="cancelDraw"> Back </v-btn>
				<v-btn elevation="0" @click="saveDrawing" :disabled="!applyButtonDisabled"> Save </v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import Raphael from "raphael";
	import * as Sentry from "@sentry/vue";

	export default {
		name: "SnapshotAnnotation",
		props: {
			backgroundImage: HTMLImageElement,
			backgroundImageCaption: {
				type: Object,
				required: true,
			},
			required: true,
		},
		data() {
			return {
				board: null,
				path: [],
				linesStack: [],
				removedLinesStack: [],
				selectedTool: "freehand",
				color: "#FF0000",
				size: 4,
				lines: [],
				wrapper: null,
				canvas: null,
				backgroundImageData: "",
				isDrawing: false,
				canvasReady: false,
				imgWidth: 0,
				imgHeight: 0,
				backgroundImageCanvas: null,
				originalBackgroundImageData: null,
			};
		},
		mounted() {
			if (this.backgroundImage) {
				const image = new Image();
				image.setAttribute("crossorigin", "anonymous");
				image.onload = () => {
					this.startCanvas(image);
				};
				const timestamp = new Date().getTime();
				image.src = this.backgroundImage.src + `&t=${timestamp}`;
			}
		},
		methods: {
			getImageData(img) {
				this.backgroundImageCanvas = document.createElement("canvas");
				this.backgroundImageCanvas.width = img.width;
				this.backgroundImageCanvas.height = img.height;
				const ctx = this.backgroundImageCanvas.getContext("2d");
				ctx.drawImage(img, 0, 0, img.width, img.height);
				this.originalBackgroundImageData = ctx.getImageData(0, 0, img.width, img.height);
				return this.backgroundImageCanvas.toDataURL("image/png");
			},
			startCanvas(image) {
				this.wrapper = document.getElementById("draw-container");
				const imageInBackground = document.getElementById("imageRef");
				this.imgWidth = imageInBackground.clientWidth;
				this.imgHeight = imageInBackground.clientHeight;
				this.board = Raphael(this.wrapper, this.imgWidth, this.imgHeight);
				this.backgroundImageData = this.getImageData(image);
				// TODO: Removed for now. Not rendering background image on board
				//this.board.image(this.backgroundImageData, 0, 0, this.imgWidth, this.imgHeight);
				this.canvasReady = true;
				this.board.canvas.addEventListener("mousedown", this.handleMouseDown);
				this.board.canvas.addEventListener("touchstart", this.handleMouseDown, { passive: false });
				this.board.canvas.addEventListener("mouseleave", this.handleMouseLeave);
				this.board.canvas.addEventListener("mouseenter", this.handleMouseEnter);
			},
			position(event) {
				const rect = this.board.canvas.getBoundingClientRect();
				let x, y;
				if (event.touches) {
					x = event.touches[0].clientX;
					y = event.touches[0].clientY;
				} else {
					x = event.clientX;
					y = event.clientY;
				}
				return { x: x - rect.left, y: y - rect.top };
			},
			handleMouseDown(event) {
				event.preventDefault();
				this.path = [];
				let pos = this.position(event);
				this.path = [["M", pos.x, pos.y]];
				this.lines = this.draw();
				this.isDrawing = true;
				this.wrapper.addEventListener("mousemove", this.handleMouseMove);
				this.wrapper.addEventListener("touchmove", this.handleMouseMove, { passive: false });
				document.addEventListener("mouseup", this.handleMouseUp);
				document.addEventListener("touchend", this.handleMouseUp, { passive: false });
			},
			handleMouseMove(event) {
				event.preventDefault();
				if (!this.isDrawing) return;
				var pos = this.position(event);
				var x = this.path[0][1];
				var y = this.path[0][2];
				var dx = pos.x - x;
				var dy = pos.y - y;
				var path = this.path;
				switch (this.selectedTool) {
					case "freehand":
						this.path.push(["L", pos.x, pos.y]);
						break;
					case "square":
						path[1] = ["L", x + dx, y];
						path[2] = ["L", x + dx, y + dy];
						path[3] = ["L", x, y + dy];
						path[4] = ["L", x, y];
						path[5] = ["L", x, y];
						break;
					case "line":
						path[1] = ["L", pos.x, pos.y];
						break;
					case "circle":
						path[1] = ["A", dx / 2, dy / 2, 0, 1, 0, pos.x, pos.y];
						path[2] = ["A", dx / 2, dy / 2, 0, 0, 0, x, y];
						break;
					default:
						return "";
				}
				this.lines.attr({ path: this.path });
			},
			handleMouseUp() {
				if (this.isDrawing) {
					if (this.path.length > 1) {
						this.linesStack.push(this.lines);
					} else {
						this.lines.remove();
					}
					this.path = [];
					this.lines = null;
					this.isDrawing = false;
					this.wrapper.removeEventListener("mousemove", this.handleMouseMove);
					this.wrapper.removeEventListener("touchmove", this.handleMouseMove);
					document.removeEventListener("mouseup", this.handleMouseUp);
					document.removeEventListener("touchend", this.handleMouseUp);
				}
			},
			handleMouseLeave() {
				if (this.isDrawing) {
					this.wrapper.style.cursor = "not-allowed";
				}
			},

			handleMouseEnter() {
				this.board.canvas.style.cursor = "default";
			},
			draw() {
				if (!this.board) {
					return;
				}
				const result = this.board.path(this.path);
				result.attr({ stroke: this.color, "stroke-width": this.size, "stroke-linecap": "round" });
				return result;
			},
			erase() {
				this.selectedTool = "eraser";
			},
			circle() {
				this.selectedTool = "circle";
			},
			square() {
				this.selectedTool = "square";
			},
			line() {
				this.selectedTool = "line";
			},
			freeHand() {
				this.selectedTool = "freehand";
			},
			undoDraw() {
				if (!this.linesStack || this.linesStack.length == 0) return;
				const lastLine = this.linesStack.pop();
				const lastLineData = lastLine.attrs?.path;
				this.removedLinesStack.push(lastLineData);
				lastLine.remove();
			},
			redoDraw() {
				if (!this.removedLinesStack || this.removedLinesStack.length === 0) return;
				const reDrawLinePath = this.removedLinesStack.pop();
				this.path = reDrawLinePath;
				this.lines = this.draw();
				this.linesStack.push(this.lines);
			},
			saveDrawing() {
				if (this.board.canvas) {
					try {
						let svgData = new XMLSerializer().serializeToString(this.board.canvas);
						const img = new Image();

						img.onload = () => {
							const finalDrawing = document.createElement("canvas");
							finalDrawing.width = this.originalBackgroundImageData.width;
							finalDrawing.height = this.originalBackgroundImageData.height;
							const ctx = finalDrawing.getContext("2d");
							ctx.putImageData(this.originalBackgroundImageData, 0, 0);
							ctx.drawImage(
								img,
								0,
								0,
								this.originalBackgroundImageData.width,
								this.originalBackgroundImageData.height
							);
							finalDrawing.toBlob((blob) => {
								createImageBitmap(blob).then((bitmap) => {
									this.$emit("save-annotation", blob);
								});
							}, "image/png");
						};
						img.src = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(svgData);
					} catch (e) {
						Sentry.captureException(e, {
							tags: {
								method: "saveDrawing",
								file: "SnapshotAnnotation",
							},
						});
					}
				}
			},
			async cancelDraw() {
				if (
					this.linesStack.length == 0 ||
					(await this.$root.$confirm.open(
						"Back",
						`Are you sure you want to navigate away from this page${
							this.linesStack.length > 0 ? " and lose your changes" : ""
						}?`,
						{},
						false,
						"Yes",
						"Cancel",
						true,
						true
					))
				) {
					this.board.clear();
					this.$emit("cancel-annotation");
				}
			},
		},
		computed: {
			applyButtonDisabled() {
				return this.canvasReady && this.linesStack.length > 0;
			},
		},
	};
</script>

<style>
	#draw-div {
		height: 100%;
		width: 100%;
		background-color: #03011d;
		border-radius: 12px;
	}

	#draw-wrapper {
		height: 60%;
		max-height: calc(100% - 100px);
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.image-container {
		height: 100%;
		width: 100%;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.image-container img {
		max-height: 100%;
		max-width: 100%;
		object-fit: contain;
		position: absolute;
	}

	#draw-container {
		z-index: 2;
		max-height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
	}

	#draw-actions {
		height: 20%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 11px;
		padding: 12px 0px;
	}

	#draw-actions div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	#draw-actions button {
		background-color: #35344a;
		border-radius: 100px;
		height: 40px;
		width: 40px;
		display: flex;
		flex-direction: column;
	}

	#draw-actions .selected {
		color: #1d1b34;
		background-color: #dfe0e2;
	}

	.icon-label {
		color: #5e5c6e;
		margin-top: 11px;
		text-align: center;
		font-size: 12px !important;
	}

	.selected + .icon-label,
	.icon-label.enabled {
		color: #fff !important;
	}

	#draw-footer {
		height: 20%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	#draw-footer-caption {
		text-align: center;
		word-wrap: break-word;
		color: #9b9ea5;
		padding: 8px 10px;
	}

	#draw-footer-caption p {
		font-size: 10px;
		margin-bottom: 0px;
	}

	#draw-footer-buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 16px;
		padding: 0px 40px 20px 40px;
	}

	#draw-footer-buttons > button:first-child {
		background-color: #f3f5f9;
		color: #03011d;
		font-size: 14px;
		border-radius: 100px;
		height: 36px;
		width: 65px;
	}

	#draw-footer-buttons > button:nth-child(2) {
		background: #0070ff;
		color: #fff;
		font-weight: 600;
		font-size: 14px;
		border-radius: 100px;
		height: 36px;
		width: 65px;
	}

	#draw-footer-buttons > :disabled {
		background-color: #35344a !important;
	}

	#draw-div .v-overlay {
		z-index: 10005;
		margin: 32px;
	}

	@media (max-width: 959px) {
		#draw-actions {
			align-items: flex-end;
		}

		#draw-footer {
			margin-top: 0px;
		}

		#draw-footer-caption {
			padding: 4px 8px;
		}

		#draw-footer-caption p {
			font-size: 8px;
		}

		#draw-div .v-overlay {
			margin: 10px !important;
		}
	}

	@media (max-width: 959px) and (orientation: landscape) {
		#draw-actions {
			padding: 4px 0;
		}
		.icon-label {
			margin-top: 0px;
			font-size: 10px !important;
		}
		#draw-wrapper {
			height: 55%;
		}
	}
</style>
