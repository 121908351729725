<template>
	<v-system-bar class="subscription" app>
		<span class="inner d-flex">
			<v-icon class="d-flex" color="white">icon-info-circle-stroke</v-icon>
			<div class="d-flex header" v-if="subscription">
				<span v-if="!canUpdateOrg"
					>Welcome! Contact an administrator to select your plan to start your free trial.
				</span>
				<span v-else-if="trialExpired && !subscription.hasPaymentMethod"
					>Your free trial has ended. <a @click="$emit('upgrade')">Add a payment method</a>
					to continue using premium features.
				</span>
				<span v-else-if="trialExpired"
					>Your free trial has ended. Your account needs attention. Please access your subscription info in
					the <a @click="$emit('upgrade')">customer billing portal.</a>
				</span>
				<span v-else-if="trialDaysRemaining > 0 && !subscription.hasPaymentMethod"
					>{{ trialDays }} remaining in your free trial. <a @click="$emit('upgrade')">Add a payment method</a>
					to continue using premium features once your trial has ended.
				</span>
				<span v-else-if="trialDaysRemaining > 0 && subscription.hasPaymentMethod"
					>{{ trialDays }} remaining in your free trial. at the end of your trial, we will automatically
					charge the payment method on file.
				</span>
			</div>
			<div class="d-flex header" v-else>
				<span v-if="isEndedTrial && canUpdateOrg"
					><a @click="$emit('upgrade')">Upgrade</a> your subscription to access all features.
				</span>
				<span v-else-if="isEndedTrial && !canUpdateOrg"
					>Contact an administrator to upgrade your subscription to access all features.
				</span>
				<span v-else-if="isTrialCCNow && canUpdateOrg">
					Welcome! <a @click="$emit('upgrade')">Select your plan</a> and follow the prompts to start your free
					trial.
				</span>
				<span v-else-if="isTrialCCNow && !canUpdateOrg"
					>Welcome! Contact an administrator to select your plan to start your free trial.
				</span>
				<span v-else-if="trialDaysRemaining > -1 && canUpdateOrg">
					{{ trialDaysRemaining > 1 ? `${trialDaysRemaining} Days` : "1 Day" }} remaining on your free trial.
					Ready for the full experience?
					<a @click="$emit('upgrade')">Upgrade now</a>
				</span>
				<span v-else-if="trialDaysRemaining > -1 && !canUpdateOrg">
					{{ trialDaysRemaining > 1 ? `${trialDaysRemaining} Days` : "1 Day" }} remaining on your free trial.
					Ready for the full experience? Contact an administrator to upgrade your subscription.
				</span>
			</div>
		</span>
	</v-system-bar>
</template>
<script>
	import Account from "../api/account";
	import api from "@/api";
	import StorageWrapper from "@/helpers/storage";
	import constants from "@/api/constants";

	export default {
		name: "TrialTopBar",
		props: {
			isEndedTrial: Boolean,
			isTrialCCNow: Boolean,
			trialDaysRemaining: Number,
		},
		data() {
			return {
				canUpdateOrg: false,
				subscription: null,
			};
		},
		async mounted() {
			let cachedAccount;
			try {
				cachedAccount = JSON.parse(StorageWrapper.getItem("account"));
			} catch (e) {}
			if (!cachedAccount) {
				const me = await api.getMe();
				cachedAccount = me.account;
			}
			const account = new Account(cachedAccount);
			this.canUpdateOrg = account.hasPermissionTo("UPDATE_ORGANIZATION");
		},
		methods: {
			setSubscriptionInfo(subscription) {
				this.subscription = subscription;
			},
		},
		computed: {
			trialDays() {
				if (this.trialDaysRemaining != 1) {
					let days = this.trialDaysRemaining;

					if (days < 0) {
						days = 0;
					}

					return `${days} days`;
				} else {
					return "1 day";
				}
			},
			trialExpired() {
				return this.subscription?.accountStatus === constants.SUBSCRIPTION_STATUS.TRIAL_EXPIRED;
			},
		},
	};
</script>
<style scoped>
	.v-system-bar.subscription span {
		margin: auto;
	}
	.v-system-bar.subscription span,
	.v-system-bar.subscription span a {
		color: white;
	}

	.v-system-bar.subscription span a {
		text-decoration: underline;
	}

	.v-system-bar.subscription .header {
		font-weight: bold;
	}
</style>
